import styled from 'styled-components';
import DatePicker from '@mui/lab/DatePicker';

const CustomDatePicker = styled(DatePicker)(props =>({
    '& label': {
        fontFamily: 'MetropolisBold',
        marginTop: '10px'
    },

    '& label.Mui-focused': {
        color: props.theme.primaryColor,
        fontSize: '17px',
        borderRadius: '10px',
        marginTop: '0'
    },

    '& label.MuiFormLabel-filled': {
        marginTop: '0',
        fontSize: '17px',
    },

    '& .MuiInputLabel-root': {
        color: 'rgba(158, 170, 181, 1)',
    },

    '& .MuiOutlinedInput-root': {
        fontSize: '17px',
        fontFamily: 'MetropolisBold',
        paddingTop: '7px',
        paddingBottom: '7px',
        borderRadius: '7px',
        color: 'rgba(42, 50, 57, 0.9)',

        'input': {
            borderRadius: '7px'
        },

        '& fieldset': {
            border: 'rgba(158, 170, 181, 0.5) solid 2px',
            borderRadius: '7px'
        },

        '&:hover fieldset': {
            border: 'rgba(158, 170, 181, 0.5) solid 2px'
        },

        '&.Mui-focused fieldset': {
            border: 'rgba(101, 192, 196, 0.5) solid 2px'

        },
    },

    '.css-1z7n62': {
        width: 'auto',
        fontSize: '12px',
    }
}));

export default CustomDatePicker;