import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form } from 'antd';
import MenuItem from '@mui/material/MenuItem';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import CustomButton from '../Custom/CustomButton';
import CustomTextInput from '../Custom/CustomTextInput';
import { useTranslation } from 'react-i18next';

import { updateCompanyConfiguration } from '../../store/authentication';

const { useForm } = Form;

const SystemConfigurationForm = props => {
    const [form] = useForm();
    const dispatch = useDispatch();
    const { user, config, loading } = useSelector(state => state.entities.authentication);
    const { t } = useTranslation(['settings', 'common_labels', 'currency']);

    const { volume_unit, currency } = config;
    const { company } = user;

    const currencyOptions = [
        { value: 'usd', key: 'usd', label: 'USD'},
        { value: 'mxn', key: 'mxn', label: 'MXN'}
    ];

    const unitOptions = [
        { value: 'gal', key: 'gal', label: t('gallon', {ns: "common_labels"})},
        { value: 'l', key: 'l', label: t('liter', {ns: "common_labels"})}
    ];

    const handleSave = (values) => {
        const { company } = values;

        dispatch(updateCompanyConfiguration(company, values));
    }

    const onValueChange = (changedValues, allValues) => {
        form.setFieldsValue(changedValues);
    }

    return (
        <CustomForm
            form={form}
            onFinish={handleSave}
            initialValues={{ company, volume_unit, currency }}
            onValuesChange={onValueChange}
        >

            <CustomFormItem label="company" name="company" hidden={true}><CustomTextInput /></CustomFormItem>

            <CustomFormItem
                name="volume_unit"
            >
                <CustomTextInput
                    id="unit"
                    label="Measurement unit"
                    size="small"
                    select
                    fullWidth
                    placeholder="Please select your measurement unit"
                >
                    {
                        unitOptions.map(option => {
                            const {key, value, label} = option;

                            return (
                                <MenuItem
                                    key={key}
                                    value={value}
                                >
                                    {label}
                                </MenuItem>
                            )
                        })
                    }
                </CustomTextInput>
            </CustomFormItem>

            <CustomFormItem
                name="currency"
            >
                <CustomTextInput
                    id="currency"
                    label="Currency"
                    size="small"
                    select
                    fullWidth
                    placeholder="Please select your currency"
                >
                    {
                        currencyOptions.map(option => {
                            let {key, value, label} = option;

                            return (
                                <MenuItem
                                key={key}
                                value={value}
                                >
                                {label}
                                </MenuItem>
                            )
                        })
                    }
                </CustomTextInput>
            </CustomFormItem>

            <Row>
                <Col xs={24} md={24} className='text-right'>
                    <CustomButton key="submit" type="primary" loading={loading} htmlType="submit">
                        {t('save', {ns: 'common_labels'})}
                    </CustomButton>
                </Col>
            </Row>
        </CustomForm>
    )
}

export default SystemConfigurationForm;
