import React, { useState } from 'react';
import { Collapse, Row, Col, Typography, Divider } from 'antd';

import CustomTable from '../Custom/CustomTable';
import CustomCollapsePanel from '../Custom/CustomCollapsePanel';
import GroupDetailCard from '../Custom/GroupDetailCard';
import Labels from "../Custom/VolumeUnitLabel";

const { Text } = Typography;

const VehiclesGroupCard = (props) => {

    let { translationFunction, group, totals_filter, columns} = props
    
    const [tableCollapsed, setTableCollapsed] = useState(true);
    const [tableOrder, setTableOrder] = useState();

    const { getVolumeLabel, getCurrencyLabel } = Labels();

    const volumeLabel = getVolumeLabel();
    const currencyLabel = getCurrencyLabel();

    const sortTable = (order) => {
        setTableOrder(order)
    }
    
    const showTable = (order, isSelected) => {

        if (!tableCollapsed && isSelected) {
            setTableCollapsed(true)
        } else {
            setTableCollapsed(false)
            sortTable(order)
        }
    }

    /**
     *  @desc Función encargada de generar la estructura de las columnas si
     *  se usa el filtro de Volumen en la vista de Servicios.
     */
    const setPerformanceColum = () => {

        columns.forEach((column) => {
            if (column.key === 'miles_per_gallon') {
                    
                column.sortOrder = tableOrder ? 'descend' : 'ascend';

                column.render = (miles_per_gallon) =>
                    <Text 
                        style={{ 
                            color: tableOrder ? '#44E3B2' : '#FA2D2D' 
                        }} 
                    > 
                        {
                            miles_per_gallon ? 
                            miles_per_gallon?.toFixed(2) : 
                            "0.00"
                        } { volumeLabel.distance }
                    </Text>
            }
        })
    }

    /**
     *  @desc Función encargada de generar la estructura de las columnas si
     *  se usa el filtro de Monto en la vista de Servicios.
     */
    const setAmountColumn = () => {

        columns.forEach((column) => {
            if (column.key === 'services_amount') {
                
                column.sortOrder = tableOrder ? 'ascend' : 'descend';

                column.render = (services_amount) => 
                    <Text 
                        style={{ 
                            color: tableOrder ? '#44E3B2' : '#FA2D2D' 
                        }} 
                        strong
                    > 
                        $ {services_amount?.toFixed(2)} { currencyLabel.abbreviated }
                    </Text>
            }
        })
    }

    /**
     *  @desc Se definiara la estructura de la columna una vez renderizado este
     *  componente, que se dibujara en la tabla del grupo.
     */
    totals_filter ? setPerformanceColum() : setAmountColumn();

    return (
        <Row xs={24} md={24} gutter={16} className='GroupCard mb-3'>
            <Col xs={24} className="gutter-row">
                <Row xs={24}  md={24} 
                    style={{ 
                        backgroundColor: '#FFF', 
                        boxShadow: "0px 3px 6px #C2CFE0A5", 
                        borderRadius: '10px',
                        height: '100%'
                    }}
                >
                    <Col xs={24} className='pt-3 pb-0 pr-3 pl-4' style={{ style:'flex' }}>
                        <Text 
                            style={{ 
                                fontSize: '18px',
                            }}
                        >
                            {props.group.name}
                        </Text>
                        <Text 
                            style={{ 
                                font: "normal normal normal 33px",
                                color: '#8D8D8D',
                                paddingLeft: '.5em'
                            }}
                        >
                            {translationFunction('fleet_cards.title', { number: props.group.vehicles.length})}
                        </Text>

                        <Divider style={{'margin-top': ".5em", 'margin-bottom': "0px"}} />

                        <Row>
                            <GroupDetailCard 
                                title={translationFunction('fleet_cards.average_consumption')} 
                                buttonMode={false}
                                group={props.group.name} 
                                content={
                                    props.totals_filter 
                                    ? `${group.average_performance} ${ volumeLabel.distance }`
                                    : '$ ' + group.average_amount}
                                textColor={'#F38C41'}
                            />
                            <GroupDetailCard 
                                title={translationFunction('fleet_cards.max_consumption')} 
                                buttonMode={true} 
                                value={false} 
                                content={
                                    props.totals_filter 
                                    ? group.max_consumption["performance"] 
                                    : group.max_consumption["amount"]
                                } 
                                border={tableOrder} 
                                onClickMethod={showTable}
                                textColor={'#FA2D2D'}
                            />
                            <GroupDetailCard 
                                title={translationFunction('fleet_cards.min_consumption')} 
                                buttonMode={true} 
                                value={true} 
                                content={
                                    props.totals_filter 
                                    ? group.min_consumption["performance"] 
                                    : group.min_consumption["amount"]
                                } 
                                border={tableOrder}
                                onClickMethod={showTable}
                                textColor={'#44E3B2'}
                            />
                            <GroupDetailCard 
                                title={
                                    props.totals_filter ? 
                                        `${translationFunction('fleet_cards.total_gallons')} ${volumeLabel.plural}` :
                                        translationFunction('fleet_cards.total_amount')
                                } 
                                buttonMode={false}
                                group={props.group.name} 
                                content={
                                    props.totals_filter ? 
                                    (
                                        group.total_gallons ? 
                                        group.total_gallons?.toFixed(2) : 
                                        0
                                    ) + ` ${volumeLabel.abbreviated}` : 
                                    '$ ' + (
                                        group.total_amount ? 
                                        group.total_amount?.toFixed(2) : 
                                        0
                                    )
                                }
                                textColor={'#3D80EA'}
                            />
                        </Row>

                        <Collapse activeKey={!tableCollapsed && 'group_table'} ghost>
                            <CustomCollapsePanel key={'group_table'}>
                                <Row>
                                    <Col xs={24}>
                                        <CustomTable
                                            locale={{emptyText: translationFunction('no_data', {ns: 'common_labels'})}}
                                            className='services-table'
                                            columns={[...columns]}
                                            dataSource={props.dataSource}
                                            rowKey='id'
                                            loading={props.loading}
                                        >
                                        </CustomTable>
                                    </Col>
                                </Row>
                            </CustomCollapsePanel>
                        </Collapse>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default VehiclesGroupCard;
