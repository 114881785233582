import * as React from "react";

function SvgChevronDown({ title, titleId, ...props }) {
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="10.09" 
        height="6.045" 
        viewBox="0 0 10.09 6.045"
        {...props}
    >
    <path id="chevron-down" d="M6,9l3.631,3.631L13.262,9" transform="translate(-4.586 -7.586)" fill="none" stroke="#2a3239" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
  );
}

export default SvgChevronDown;