import * as React from "react";

function SvgUsers({ title, titleId, ...props }) {
  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="#c2cfe0">
        <path
          data-name="Trazado 281"
          d="M6.813 8.708a3.167 3.167 0 10-3.167-3.166 3.167 3.167 0 003.167 3.166zm0-4.75a1.583 1.583 0 11-1.584 1.584 1.583 1.583 0 011.584-1.584z"
        />
        <path
          data-name="Trazado 282"
          d="M12.681 9.903a2.375 2.375 0 10-2.375-2.375 2.375 2.375 0 002.375 2.375zm0-3.167a.792.792 0 11-.792.792.792.792 0 01.792-.792z"
        />
        <path
          data-name="Trazado 283"
          d="M12.708 10.084a3.561 3.561 0 00-2.269.831 4.954 4.954 0 00-5.656-1.193 5.557 5.557 0 00-3.2 5.112.768.768 0 00.742.792.768.768 0 00.742-.792 3.839 3.839 0 013.708-3.958 3.839 3.839 0 013.708 3.958.743.743 0 101.483 0 5.755 5.755 0 00-.638-2.652 2.111 2.111 0 012.348-.275 2.393 2.393 0 011.256 2.135.743.743 0 101.483 0 3.839 3.839 0 00-3.707-3.958z"
        />
      </g>
    </svg>
  );
}

export default SvgUsers;
