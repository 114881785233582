import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, InputNumber, Button, Row, Card, Form, Carousel, Tooltip, Space } from 'antd';
import QRCode from 'qrcode.react';

import vehicleIcon from './../../assets/icons/vehicle.png';
import { createExternalId } from '../../store/vehicles';

const { Item } = Form;

const QrTagAdmin = () => {

    const dispatch = useDispatch();
    const { loadingAction, externalIdList } = useSelector(state => state.entities.vehicles);
    const [ loadingData, setLoadingData ] = useState(false);
    const [ printReady, setPrintReady ] = useState(false);
    const carousel = useRef(null);
    const carouselStyle = { width: "200px" }

    let qr_codes = {};

    useEffect(() => {
        if (loadingAction) {
            setPrintReady(true);
        }
    }, [loadingAction]);


    const createQrCode = (value) => {

        const qr_value = JSON.stringify({ external_id: value });

        const code = ( 
            <QRCode
                level={'H'}
                renderAs="svg"
                size={176}
                value={ qr_value }
                imageSettings={{
                    src: vehicleIcon,
                    x: null,
                    y: null,
                    height: 40,
                    width: 40,
                    excavate: true,
                }}
            />
        )

        return code
    };
    
    const loadData = (formValues) => {
        dispatch(createExternalId(formValues));
    };

    const printTags = () => {
        const pagelink = "about:blank";
        const pwa = window.open(pagelink, "_new");

        pwa.document.open();
        
        for (const property in qr_codes) {
            pwa.document.write(qr_codes[property]);
        }

        pwa.document.close();

        pwa.document.body.style.margin = "0px";
        pwa.addEventListener('afterprint', (event) => pwa.close());
        pwa.print();

        setPrintReady(false);
        qr_codes = {};
    }

    const onRefChange = (element) => {
        if (element && !(element.id in qr_codes)) {

            const qrCodeHtmlElement = element.cloneNode(true);
            let qrCodeSvg = qrCodeHtmlElement.firstChild;

            qrCodeSvg.style.marginTop = '9px'

            qr_codes[element.id] = qrCodeSvg.outerHTML;
        }
    };

    return (
        <>
            <Row justify="center">
                <Col className="" span={12}>
                    <Card title="Panel de administración de etiquetas QR">
                        <Form initialValues={{ quantity: 1 }} layout="vertical" onFinish={loadData}>
                            <Item name="quantity" label="Cantidad de etiquetas">
                                <InputNumber min={1} />
                            </Item>
                            <Item>
                                <Space>
                                    <Button 
                                        htmlType="submit" 
                                        loading={loadingAction}
                                    >
                                        Generar
                                    </Button>
                                    { printReady && 
                                        <Button 
                                            type="primary" 
                                            onClick={printTags}
                                        >
                                            Imprimir etiquetas
                                        </Button>
                                    }
                                </Space>
                            </Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
            { printReady && 
                <Row className="mt-3" justify="center">
                    <Col flex="auto">
                            <Carousel autoplay={true}>
                                { externalIdList && externalIdList.map(externalId => (
                                        <div id={externalId} ref={onRefChange} className="text-center">
                                            <Tooltip title={externalId} placement="right">
                                                { createQrCode(externalId) }
                                            </Tooltip>
                                        </div>
                                    )
                                )}
                            </Carousel>
                    </Col>
                </Row>
            }
        </>
    );
};

export default QrTagAdmin
