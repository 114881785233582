import React, { Component } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import SvgActionIcon from '../icons/ActionsIcon';
import TagsDispenser from './TagsDispenser';


class ActionsDispenser extends Component {

  render() {
    const { dispenser, hasViewPermissions, openModal, t } = this.props;
      
    const menu = (
        <Menu>
            <Menu.Item  style={{display: hasViewPermissions ? '' : 'none'}} >
                <Button type="text" 
                    onClick={() => openModal(dispenser, true)} >
                    {t('dispenser_detail')}
                </Button>
            </Menu.Item>
            <Menu.Item style={{ display: hasViewPermissions ? '' : 'none' }} >
                <TagsDispenser t={t} dispenser={dispenser}  />
            </Menu.Item>
      </Menu>
    );

    return ( 

        <div>
            <Dropdown overlay={menu} >
                <div className="mdc-touch-target-wrapper">
                    <Button type="text" className="mdc-icon-button mdc-icon-button__detail">
                        <div className="mdc-icon-button__ripple"></div>
                        <SvgActionIcon />
                    </Button>
                </div>
            </Dropdown>
            
      </div>
    );
  }
}

const ActionsDispenserComponent = withTranslation('actions')(ActionsDispenser);

export default ActionsDispenserComponent;
