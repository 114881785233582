import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/customers/';
const slice = createSlice({
    name: 'customers',
    initialState: {
        list: [],
        ownersList: [],
        loading: false,
        loadingAction: false,
        lastFetch: null,
        errors: [],
        stations:[]
    },
    reducers: {
        customersRequested: (customers, action) => {
            customers.loading = true;
        },
        customersActionRequested: (customers, action) => {
            customers.loadingAction = true;
        },
        customersRequestFailed: (customers, action) => {
            customers.loading = false;
            customers.loadingAction = false;
            let responseData = action.payload.data;
            customers.errors = [];
            for (const field in responseData) {
                customers.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
        },
        customersReceived: (customers, action) => {
            customers.list = action.payload.data;
            customers.loading = false;
        },
        customerAdded: (customers, action) => {
            customers.loadingAction = false;
            customers.errors = [];
            customers.list.unshift(action.payload.data);
        },
        customerDeleted: (customers, action) => {
            customers.list = customers.list.filter(customer => customer.id !== action.payload.requestData);
            customers.loading = false;
        },
        customerUpdated: (customers, action) => {
            const index = customers.list.findIndex(customer => customer.id === action.payload.data.id);
            customers.list[index] = action.payload.data;
            customers.loadingAction = false;
            customers.errors = [];
        },
        fleetOwnersReceived: (customers, action) => {
            customers.ownersList = action.payload.data;
            customers.loading = false;
        },
        customersStationsReceived:(customers, action) => {
            customers.stations = action.payload.data;
        },
    }
});

export default slice.reducer;
export const loadCustomers = () => apiCallBegan({
    url,
    onStart: slice.actions.customersRequested.type,
    onSuccess: slice.actions.customersReceived.type,
    onError: slice.actions.customersRequestFailed.type
});

export const addCustomer = customer => apiCallBegan({
    url,
    method: 'post',
    data: customer,
    onStart: slice.actions.customersActionRequested.type,
    onSuccess: slice.actions.customerAdded.type,
    onError: slice.actions.customersRequestFailed.type
});

export const deleteCustomer = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onStart: slice.actions.customersActionRequested.type,
    onSuccess: slice.actions.customerDeleted.type,
    onError: slice.actions.customersRequestFailed.type
});

export const changeStatus = (id, isActive) => apiCallBegan({
    url: `${url}${id}/`,
    method: 'patch',
    data: { is_active: isActive },
    onStart: slice.actions.customersRequested.type,
    onSuccess: slice.actions.customerUpdated.type,
    onError: slice.actions.customersRequestFailed.type
});

export const updateCustomer = (customer) => apiCallBegan({
    url: `${url}${customer.id}/`,
    method: 'put',
    data: customer,
    onStart: slice.actions.customersActionRequested.type,
    onSuccess: slice.actions.customerUpdated.type,
    onError: slice.actions.customersRequestFailed.type
});

export const loadOwners = () => apiCallBegan({
    url: `${url}owners/`,
    onStart: slice.actions.customersRequested.type,
    onSuccess: slice.actions.fleetOwnersReceived.type,
    onError: slice.actions.customersRequestFailed.type
});

export const loadCustomersStations = () => apiCallBegan({
    url: `${url}stations/`,
    onSuccess: slice.actions.customersStationsReceived.type,
});