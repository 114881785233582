import styled from 'styled-components';
import { Input } from 'antd';
import SearchIcon from '../icons/SearchIcon'
import { useTranslation } from 'react-i18next';

const StyledInput = styled(Input)`
        & {
            height: 48px;
            border-radius: 10px;
        }
`;

const CustomSearhInput = (props) => {

    const { t } = useTranslation('common_labels');
    const defaultPlaceholder = t('search_bar');

    return(
        <StyledInput
            placeholder={defaultPlaceholder}
            onChange={props.onChange}
            suffix={<SearchIcon></SearchIcon>}
        />
    )
}

export default CustomSearhInput;
