import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/dispensers/';
const urlTag = '/tags/';
const slice = createSlice({
    name: 'dispensers',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        tags: [],
        loadingTags: false,
        errors: [],
        loadingAction: false,
    },
    reducers: {
        dispensersRequested: (dispensers, action) => {
            dispensers.loading = true;
        },
        dispensersRequestFailed: (dispensers, action) => {
            dispensers.loading = false;
        },
        dispensersReceived: (dispensers, action) => {
            dispensers.list = action.payload.data;
            //dispensers.list.unshift({id: "all", name: "Select all", is_active: true});
            // console.log('lista dada por dispensersReceived: ', dispensers.list);
            dispensers.loading = false;
        },
        dispensersActionRequested: (dispensers, action) => {
            dispensers.loadingAction = true;
        },
        dispenserAdded: (dispensers, action) => {
            dispensers.list.unshift(action.payload.data);
            dispensers.loadingAction = false;
            dispensers.errors = [];
        },
        dispenserAddRequestFailed: (dispensers, action) => {
            let responseData = action.payload.data;
            dispensers.errors = [];
            for (const field in responseData) {
                dispensers.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            dispensers.loadingAction = false;
        },
        dispenserDeleted: (dispensers, action) => {
            dispensers.list = dispensers.list.filter(dispenser => dispenser.id !== action.payload.requestData);
            dispensers.loading = false;
        },
        dispenserUpdated: (dispensers, action) => {
            const index = dispensers.list.findIndex(dispenser => dispenser.id === action.payload.data.id);
            dispensers.list[index] = action.payload.data;
            dispensers.loadingAction = false;
            dispensers.errors = [];
        },
        tagsDispenserRequested: (dispensers, action) => {
            // console.log('estado antes del delete: ', current(dispensers));
            dispensers.loadingTags = true;
        },
        tagsDispenserReceived: (dispensers, action) => {
            // console.log('tags:', action.payload.data);
            dispensers.tags = action.payload.data;            
            dispensers.loadingTags = false;
        },
        tagsDispenserFailed: (dispensers, action) => {
            dispensers.tags = [];
            dispensers.loadingTags = false;
        },

        changetagsDispenserRequested: (dispensers, action) => {
            dispensers.loadingTags = true;
        },
        changetagsDispenserReceived: (dispensers, action) => {
            // console.log('estado despues del delete: ', dispensers.tags.users.filter(user => user.tag_name != action.payload.data.name));
            // console.log('action', action.payload.data.name);
            dispensers.tags.users = dispensers.tags.users.filter(user => user.tag_name != action.payload.data.name);
            dispensers.loadingTags = false;
            // console.log('estado nuevo: ', current(dispensers));

        },
        changetagsDispenserFailed: (dispensers, action) => {            
            dispensers.loadingTags = false;
        },
    }
});

export default slice.reducer;
export const loadDispensers = () => apiCallBegan({
    url,
    onStart: slice.actions.dispensersRequested.type,
    onSuccess: slice.actions.dispensersReceived.type,
    onError: slice.actions.dispensersRequestFailed.type
});

export const addDispenser = dispenser => apiCallBegan({
    url,
    method: 'post',
    data: dispenser,
    onStart: slice.actions.dispensersActionRequested.type,
    onSuccess: slice.actions.dispenserAdded.type,
    onError: slice.actions.dispenserAddRequestFailed.type
});

export const deleteDispenser = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onSuccess: slice.actions.dispenserDeleted.type
});

export const updateDispenser = (dispenser) => apiCallBegan({
    url: `${url}${dispenser.id}/`,
    method: 'put',
    data: dispenser,
    onStart: slice.actions.dispensersActionRequested.type,
    onSuccess: slice.actions.dispenserUpdated.type,
    onError: slice.actions.dispenserAddRequestFailed.type
});


export const tagsDispenser = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'get',
    onStart: slice.actions.tagsDispenserRequested.type,
    onSuccess: slice.actions.tagsDispenserReceived.type,
    onError: slice.actions.tagsDispenserFailed.type
});


export const updateTag = (id, data) => apiCallBegan({
    url: `${urlTag}${id}/`,
    method: 'patch',
    data: data,
    onStart: slice.actions.changetagsDispenserRequested.type,
    onSuccess: slice.actions.changetagsDispenserReceived.type,
    onError: slice.actions.changetagsDispenserFailed.type
});