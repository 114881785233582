import React, { useState, useEffect, Fragment } from 'react';
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Form, Typography, Button } from 'antd';
import  QrReader   from 'react-qr-reader';
import { loadDispensers, addDispenser, updateDispenser, /*deleteDispenser*/ } from '../../store/dispensers';
import { loadStations } from '../../store/stations';
import { loadProducts, loadProductsByStation } from '../../store/products';
import { loadDispenserTypes } from '../../store/dispenserTypes';
import CreateModal from '../Custom/CreateModal';
import CreateDispenserForm from './CreateDispenserForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import IconButton from '../Custom/IconButton';
import SvgEditIcon from "../icons/EditIcon"
// import SvgDeleteIcon from '../icons/DeleteIcon';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomSearhInput from '../Custom/CustomSearchInput';
// import ConfirmModal from '../Custom/ConfirmationModal';
import ActionsDispenser from './ActionsDispenser';
import CustomTag from '../Custom/CustomTag';
import { STATUS } from '../constants';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Dispensers = () => {
    const MODULE_NAME = 'dispenser';
    const { t } = useTranslation([MODULE_NAME, 'tags', 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    // const DELETE_PERMISSION_CODENAME = `delete_${MODULE_NAME}`;
    const VIEW_PERMISSION_CODENAME = `read_${MODULE_NAME}`;
    const CHANGE_PERMISSION_CODENAME = `update_${MODULE_NAME}`;
    const ADD_PERMISSION_CODENAME = `create_${MODULE_NAME}`;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [camaraActive, setCamaraActive] = useState(false);
    const [isModalQr, setIsModalQr] = useState(false);
    const [delay, setDelay] = useState(false);
    const [titleModal, setTitleModal] = useState("Create Dispenser");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { hasResourcePermission } = PermissionValidator();
    const { list, loading, errors, loadingAction } = useSelector(state => state.entities.dispensers);
    const { config } = useSelector(state => state.entities.authentication);

    let dispensersLength = list.length;
    const indexedList = list.map(e => ({...e, 'index': dispensersLength--}));

    const dispenserTypes = useSelector(state => state.entities.dispenserTypes.list);
    const stations = useSelector(state => state.entities.stations.list);
    const { byStation, loading:loadingProducts } = useSelector(state => state.entities.products);

    useEffect(() => {
        dispatch(loadDispensers());
        dispatch(loadStations());
        dispatch(loadProducts());
        dispatch(loadDispenserTypes());
        getVideo();
    }, [dispatch]);

    useEffect(() => {
        form.setFields(errors);
        if (errors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, errors]);

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            onCell: (record, rowIndex) => {
                // console.log(record);
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}> { record.index } </Text>
        },
        {
            title: t('dispensers.table.station'),
            dataIndex: 'station',
            key: 'station',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => <Text delete={record.station.deleted_at}>{record.station.name}</Text>
        },
        {
            title: t('dispensers.table.terminal_number'),
            dataIndex: 'terminal_number',
            id: 'terminal_number',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (terminal_number) => <Text strong> #{terminal_number} </Text>
        },
        {
            title: t('dispensers.table.product'),
            dataIndex: 'product',
            key: 'product',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => <Text delete={record.product.deleted_at}>{record.product.name}</Text>
        },
        {
            title: t('dispensers.table.price'),
            dataIndex: 'price',
            key: 'price',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => <Text strong style={{ color: config.primaryColor }}>$ {parseFloat(record.product.price).toFixed(2)}</Text>
        },
        {
            title: t('dispensers.table.status'),
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => {
                let status = record.is_active ? STATUS.active : STATUS.inactive;
                return <CustomTag colors={status.colors}>{status.label}</CustomTag>
            }
        },
        {
            title: t('dispensers.table.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasViewPermissions = hasResourcePermission(VIEW_PERMISSION_CODENAME);
                let hasEditPermissions = hasResourcePermission(CHANGE_PERMISSION_CODENAME);
                // let hasDeletePermissions = hasResourcePermission(DELETE_PERMISSION_CODENAME);
                return (
                    <Fragment>
                        <Space size="middle">
                            <IconButton 
                            isVisible={hasEditPermissions}
                            hover_color='mdc-icon-button__edit' 
                            onClick={() => showModal(record)} 
                            icon={<SvgEditIcon/>}
                            />
                        </Space>
                        {/* <Space size="middle">
                            <IconButton 
                            isVisible={hasDeletePermissions}
                            hover_color='mdc-icon-button__delete'
                            onClick={() => ConfirmModal({
                                "onConfirmedDeletion": clickConfirmed,
                                "moduleName": MODULE_NAME,
                                "recordId": record.id,
                                "name": record.name
                            })
                            }
                            icon={<SvgDeleteIcon/>}
                            />
                        </Space> */}
                        <Space
                            size="middle" >
                            <ActionsDispenser
                                hasViewPermissions={hasViewPermissions}
                                dispenser={record}
                                openModal={showModal}
                            />
                        </Space>
                    </Fragment>
                );
            }
        }
    ];

    const resetForm = () => {
        form.resetFields();
    };

    const showModal = (dispenser = [], detailsOnly = false) => {
        setIsDetailsOnly(detailsOnly);
        if (dispenser.id) {
            let dispenserData = { ...dispenser };
            let { station, product  } = dispenser;
            dispenserData.dispenser_type = dispenserData.dispenser_type.id;
            dispenserData.product = null;
            dispenserData.station = null;
            if (station && station.deleted_at == null) {
                dispenserData.station = station.id;
                dispenserData.product = product && product.deleted_at == null ? product.id : null;
                dispatch(loadProductsByStation(dispenserData.station))
            }
            form.setFieldsValue(dispenserData);
            if (detailsOnly === true) {
                setTitleModal("Dispenser Detail")
            } else {
                 setTitleModal("Edit Dispenser")
            }
        } else {
            setTitleModal("Create Dispenser")
        }
        setIsModalVisible(true);
    };

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    
    const handleSearch = (event) => {
        let value = (event.target.value).trim().toLowerCase();

        let filtered = indexedList.filter(element => {
            const { terminal_number, station, product } = element;
            return (
                terminal_number.toString().toLowerCase().includes(value) ||
                station.name.toLowerCase().includes(value) ||
                product.name.toLowerCase().includes(value)
            );
        });

        setSearch(value);
        setUserSerch(filtered);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        resetForm();
    }

    const handleFinish = (values) => {
        if (!isDetailsOnly) {
            if(values.id)
                dispatch(updateDispenser(values));
            else
                dispatch(addDispenser(values));
        } else {
            setIsDetailsOnly(false);
            setTitleModal("Edit Dispenser");
        }
    }

    // const clickConfirmed = (recordId) => {
    //     dispatch(deleteDispenser(recordId));
    // }


    const handleScan = data => {
        if (data) {
            let qrString = data.split(',')
            if (qrString[0].length === 24) {
                setDelay(false);
                form.setFieldsValue({ "name": qrString[0], "terminal_number": qrString[2] });
                setIsModalQr(false);   
            }
        }
    }
    const handleError = err => {
        console.error(err)
    }

    const getVideo = () => {
        if (navigator.mediaDevices){
            navigator.mediaDevices
                .getUserMedia({ video: { width: 300 } })
                .then(stream => {
                    if (stream.active) {
                        setCamaraActive(true)
                    }
                })
                .catch(err => {
                    console.error("error get video:", err);
                    setCamaraActive(false)
                });
        }
    };

    const showModalQr = () => {
        setIsModalQr(true);
        setDelay(500);
    };

    const handleCancelQr = () => {
        setDelay(false);
        setTimeout(() => setIsModalQr(false), 300);
    };

    return (
        <>
            <CreateModal 
            title={titleModal}
            okText={isDetailsOnly ? 'Edit' : 'Save'} 
            width={1000} 
            visible={isModalVisible}
            onCancel={handleCancel} 
            okButtonProps={{ onClick: form.submit, loading: loadingAction }}
            >
                <CreateDispenserForm 
                    form={form}
                    onFinish={handleFinish}
                    dispenserTypes={dispenserTypes}
                    stations={stations}
                    products={byStation}
                    loadingProducts={loadingProducts}    
                    isDetailsOnly={isDetailsOnly}
                    camaraActive={camaraActive}
                    showModalQr={showModalQr}
                    t={t}
                />
            </CreateModal>
            <CreateModal title="Dispenser QR code reader"
                visible={isModalQr}
                onCancel={handleCancelQr}
                footer={[
                    <Button onClick={handleCancelQr}>
                        Cancel
                    </Button>
                ]}
                >
                <QrReader
                    delay={delay}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                />
            </CreateModal>

            <Row>
                <Col xs={12}>
                    <h1 style={{ fontSize: '2em' }}>
                        {t('header')}
                    </h1>
                </Col>
                <Col xs={12}>
                    <CustomButton 
                    className="create-button"
                    onClick={showModal} 
                    type="primary"
                    style={{ 
                        float: 'right',
                        display: hasResourcePermission(ADD_PERMISSION_CODENAME) ? '' : 'none'
                    }}
                    >
                        {t('button')}
                    </CustomButton>
                </Col>
                <Col xs={0}>
                    <BreadcrumbCustom items={["Home", "Dispensers List"]} />
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <CustomSearhInput 
                        placeholder={t('common_labels.search_bar')}
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <CustomTable 
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                columns={columns} 
                dataSource={searchValue ? usersSerch : indexedList} 
                rowKey='id' 
                loading={loading} 
            />
        </>
    );
}

export default Dispensers;
