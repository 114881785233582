import React, { useState, useEffect, Fragment } from 'react';
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { loadStations } from '../../store/stations';
import { loadProducts, addProduct, updateProduct, /*deleteProduct*/ } from '../../store/products';
import { Row, Col, Space, Form, Typography } from 'antd';
import CreateModal from '../Custom/CreateModal';
import CreateProductForm from './CreateProductForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import IconButton from '../Custom/IconButton';
import SvgEditIcon from "../icons/EditIcon"
// import SvgDeleteIcon from '../icons/DeleteIcon';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomTag from '../Custom/CustomTag';
import CustomSearhInput from '../Custom/CustomSearchInput';
// import ConfirmModal from '../Custom/ConfirmationModal';
import { STATUS } from '../constants';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Products = () => {
    const MODULE_NAME = 'product';
    const { t } = useTranslation([MODULE_NAME, 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    // const DELETE_PERMISSION_CODENAME = `delete_${MODULE_NAME}`;
    const CHANGE_PERMISSION_CODENAME = `update_${MODULE_NAME}`;
    const ADD_PERMISSION_CODENAME = `create_${MODULE_NAME}`;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [titleModal, setTitleModal] = useState("Create Product");
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { hasResourcePermission } = PermissionValidator();
    const { list, loading, errors, loadingAction } = useSelector(state => state.entities.products);
    const { config } = useSelector(state => state.entities.authentication);
    const stations = useSelector(state => state.entities.stations.list);
    let productsLength = list.length;
    const indexedList = list.map(e => ({...e, 'index': productsLength--}));

    useEffect(() => {
        dispatch(loadProducts());
        dispatch(loadStations());
    }, [dispatch]);

    useEffect(() => {
        form.setFields(errors);
        if (errors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, errors]);

    const columns = [
        {
            dataIndex: 'index',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}> { (record.index) } </Text>
        },
        {
            dataIndex: 'name',
            id: 'name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (name) => <Text> {name} </Text>
        },
        {
            dataIndex: 'station',
            key: 'station',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => <Text delete={record.station.deleted_at}>{record.station.name}</Text>
        },
        {
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (price) => <Text>$ {parseFloat(price).toFixed(2)} </Text>
        },
        {
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => {
                let status = record.is_active ? STATUS.active : STATUS.inactive;
                return <CustomTag colors={status.colors}>{status.label}</CustomTag>
            }
        },
        {
            dataIndex: 'created_at',
            key: 'created_at',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (created_at) => <Text>{new Date(created_at).toLocaleString("en-US")} </Text>
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasEditPermissions = hasResourcePermission(CHANGE_PERMISSION_CODENAME);
                // let hasDeletePermissions = hasResourcePermission(DELETE_PERMISSION_CODENAME);
                return (
                    <Fragment>
                        <Space size="middle">
                            <IconButton
                                isVisible={hasEditPermissions}
                                hover_color='mdc-icon-button__edit'
                                onClick={() => showModal(record)}
                                icon={<SvgEditIcon />}
                            />
                        </Space>
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasDeletePermissions}
                                hover_color='mdc-icon-button__delete'
                                onClick={() => ConfirmModal({
                                    "onConfirmedDeletion": clickConfirmed,
                                    "moduleName": MODULE_NAME,
                                    "recordId": record.id,
                                    "name": record.name
                                })}
                                icon={<SvgDeleteIcon />}
                            />
                        </Space> */}
                    </Fragment>
                );
            }
        }
    ];

    const resetForm = () => {
        form.resetFields();
    };

    const showModal = (product = [], detailsOnly = false) => {
        form.isUpdate = false;
        if (product.id) {
            let productData = { ...product };
            let { station } = product;
            productData.station = station && station.deleted_at == null ? station.id : null;
            form.isUpdate = true;
            form.setFieldsValue(productData);
            setTitleModal(detailsOnly ? "Product Details" : "Edit Product")

        } else {
            setTitleModal("Create Product")
        }
        setIsModalVisible(true);
        setIsDetailsOnly(detailsOnly);
    };

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    
    const handleSearch = (event) => {
        let value = (event.target.value).trim().toLowerCase();

        let filtered = indexedList.filter(element => {
            const { name, station } = element;
            return (
                name.toLowerCase().includes(value) ||
                station.name.toLowerCase().includes(value)
            );
        });

        setSearch(value);
        setUserSerch(filtered);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        resetForm();
    }

    const handleSave = (values) => {
        if (!isDetailsOnly) {
            if (form.isUpdate)
                dispatch(updateProduct(values));
            else
                dispatch(addProduct(values));
        } else {
            setIsDetailsOnly(false);
            setTitleModal("Edit Product")
        }
    }

    // const clickConfirmed = (recordId) => {
    //     dispatch(deleteProduct(recordId))
    // }

    return (
        <Fragment>
            <CreateModal
                title={titleModal}
                okText={isDetailsOnly ? 'Edit' : 'Save'}
                width={800}
                visible={isModalVisible}
                onCancel={handleCancel}
                okButtonProps={{ onClick: form.submit, loading: loadingAction }}
            >
                <CreateProductForm
                    form={form}
                    onFinish={handleSave}
                    stations={stations}
                    isDetailsOnly={isDetailsOnly}
                />
            </CreateModal>
            <Row>
                <Col xs={12}>
                    <h1 style={{ fontSize: '2em' }}>
                        {t('header')}
                    </h1>
                </Col>
                <Col xs={12}>
                    <CustomButton
                    className="create-button"
                        onClick={showModal}
                        style={{
                            float: 'right',
                            display: hasResourcePermission(ADD_PERMISSION_CODENAME) ? '' : 'none'
                        }}
                        type="primary"
                    >
                        {t('button')}
                    </CustomButton>
                </Col>

                <Col xs={0}>
                    <BreadcrumbCustom
                        items={["Home", "Product List"]}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <CustomSearhInput 
                        placeholder={t('common_labels.search_bar')}
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <CustomTable
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                columns={columns}
                dataSource={searchValue ? usersSerch : indexedList}
                rowKey='id'
                loading={loading}
            />
        </Fragment>
    );
}

export default Products;
