import React, { Component } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import SvgActionIcon from '../icons/ActionsIcon';


class ActionsUsers extends Component {

  render() {
      const { user, hasEditPermissions, hasViewPermissions,
              clickChangeStatus, actions, openModal, t } = this.props;
    const menu = (
      <Menu>
            {/* <Menu.Item  style={{display: hasEditPermissions && user.is_verified ? '' : 'none'}} >
                <Button type="text" disabled={!hasEditPermissions}
                    onClick={() => clickChangeStatus(user.id, !user.is_active)}
                    >
                    {user.is_active ? actions.inactivate.label : actions.activate.label}
                </Button>
            </Menu.Item> */}
            <Menu.Item d style={{display: hasViewPermissions ? '' : 'none'}} >
                <Button type="text" 
                    onClick={() => openModal(user, true)} >
                    {t('user_detail')}
                </Button>
            </Menu.Item>
      </Menu>
    );

    return ( 

        <div>
            <Dropdown overlay={menu} >
                <div className="mdc-touch-target-wrapper">
                    <Button type="text" className="mdc-icon-button mdc-icon-button__detail">
                        <div className="mdc-icon-button__ripple"></div>
                        <SvgActionIcon />
                    </Button>
                </div>
            </Dropdown>
            
      </div>
    );
  }
}

const ActionUsersComponent = withTranslation('actions')(ActionsUsers);

export default ActionUsersComponent;
