import React, { useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomDatePicker from '../Custom/CustomDatePicker';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import CustomCheckbox from '../Custom/CustomCheckbox';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';


const CreateAutorizationForm = (props) => {

    const { t } = useTranslation('common_labels');
    const inputLabelStyle = {margin: '0'};
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const disabledDate = (current) => {
        if (endDate === null) {
            return current && current < moment().startOf('day');
        }
        return (current && current < moment().startOf('day')) || current > endDate;
    }

    const disabledDateEnd = (current) => {
        return current && current < startDate;
    }

    return (
        <CustomForm
            form={props.form}
            onFinish={props.onFinish}
            initialValues={{ is_active: true }}
        >
            <CustomFormItem
                name="users"
                rules={[{
                    required: true,
                    message: 'Please select an user'
                }]}
            >
                <CustomMultipleSelect
                    className='password_id_user_select'
                    label='Users'
                    fieldName='users'
                    form={props.form}
                    options={props.users}
                    onChangeUsers={props.onChangeUsers}
                />
            </CustomFormItem>
            <CustomFormItem>
                <CustomTextInput
                    label="E-mails"
                    multiline={true}
                    rows={5}
                    placeholder="E-mails"
                    value={props.selectedUsers.join('\r\n')}
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: inputLabelStyle
                    }}
                />
            </CustomFormItem>
            <Row gutter={16} justify="space-between">
                <Col>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CustomFormItem name="start_date" >
                        <CustomDatePicker
                            label="Start Date"
                            value={startDate}
                            shouldDisableDate={disabledDate}
                            renderInput={(params) => <CustomTextInput {...params} />}
                            onChange={(newDate) => setStartDate(newDate)}
                        />
                        </CustomFormItem>
                    </LocalizationProvider>
                </Col>
                <Col>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CustomFormItem name="end_date" >
                        <CustomDatePicker
                            label="Expiration Date"
                            value={endDate}
                            shouldDisableDate={disabledDateEnd}
                            renderInput={(params) => <CustomTextInput {...params} />}
                            onChange={(newDate) => setEndDate(newDate)}
                        />
                       </CustomFormItem>
                    </LocalizationProvider>
                </Col>
            </Row>
            <CustomFormItem name="renewal" valuePropName="checked" >
                <CustomCheckbox defaultChecked={false} >
                    {t('automatic_renewal')} 
                </CustomCheckbox>
            </CustomFormItem>
        </CustomForm>

    );
};

export default CreateAutorizationForm;
