import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/states/';
const slice = createSlice({
    name: 'states',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        statesRequested: (states, action) => {
            states.loading = true;
        },
        statesRequestFailed: (states, action) => {
            states.loading = false;
        },
        statesReceived: (states, action) => {
            states.list = action.payload.data;
            states.loading = false;
        }
    }
});

export default slice.reducer;
export const loadStates = () => apiCallBegan({
    url,
    onStart: slice.actions.statesRequested.type,
    onSuccess: slice.actions.statesReceived.type,
    onError: slice.actions.statesRequestFailed.type
});