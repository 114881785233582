import styled from 'styled-components';
import { Col } from 'antd';


const CustomLoginComponent = styled(Col)( props => ({

    '&.header' : {
        'text-align': 'center'
    },

    '&.card' : {
        'background-color': '#FFF',
        'box-shadow': '0px 3px 10px #65C0C458',
    }
}));

export default CustomLoginComponent;
