import { Radio, Layout, Row, Col, Button, Select, Form, Typography, Empty, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
    loadGsDevices,
    addGsDevice
} from '../store/vehicles';


const GSTrackerForm = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { 
        gsDevicesLoading, 
        errors, 
        gsTrackingDevices 
    } = useSelector(state => state.entities.vehicles);

    const onFinish = (formValues) => {
        form.resetFields();
        dispatch(addGsDevice(formValues));
    }

    return (
        <Layout className='mt-5'>
            <Row>
                <Col 
                    span={10} 
                    offset={7} 
                    style={{marginTop: '10%',}}  
                    className='mb-5'
                >
                    <Form
                        form={form}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        autoComplete="off"
                        initialValues={{}}
                        disabled={gsDevicesLoading}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="External ID"
                            name="external_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input an external id for the device"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="GS tracker mac address"
                            name="mac_address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input a mac address for the device"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input a name for the device"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            wrapperCol={{ offset: 17, span: 3 }} 
                            style={{ textAlign: 'right' }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={gsDevicesLoading}
                            >
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Layout>
    )
};

export default GSTrackerForm;
