import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/dispenser_types/';
const slice = createSlice({
    name: 'dispenserTypes',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        dispenserTypesRequested: (dispenserTypes, action) => {
            dispenserTypes.loading = true;
        },
        dispenserTypesRequestFailed: (dispenserTypes, action) => {
            dispenserTypes.loading = false;
        },
        dispenserTypesReceived: (dispenserTypes, action) => {
            dispenserTypes.list = action.payload.data;
            dispenserTypes.loading = false;
        }
    }
});

export default slice.reducer;
export const loadDispenserTypes = () => apiCallBegan({
    url,
    onStart: slice.actions.dispenserTypesRequested.type,
    onSuccess: slice.actions.dispenserTypesReceived.type,
    onError: slice.actions.dispenserTypesRequestFailed.type
});