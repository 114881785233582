import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Typography, Spin, Result, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../Custom/CustomButton';
import CustomForm from '../Custom/CustomForm';
import CustomLink from '../Custom/CustomLink';
import styled from 'styled-components';
import SvgPadlock from '../icons/Padlock';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomLoginComponent from '../Custom/CustomLoginComponent';
import PasswordFormItem from '../Custom/PasswordFormItem';
import './Login.css'
import { useDispatch , useSelector} from 'react-redux';
import { loadLogin } from '../../store/authentication';
import { verifyEmail, resetPassword, verifyResetPasswordToken } from '../../store/authentication';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const {Paragraph} = Typography;

const Img = styled.img`
    width: 90%;
`;

const PasswordResetForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['forgot_password', 'form_error_messages']);
    const { loading, passwordReseted, resetPasswordResponse } = useSelector(state => state.entities.authentication);
    const { data, type } = resetPasswordResponse;
    const { token } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const [passwordSaved, setPasswordSaved] = useState()

    const handleSave = () => {
        let form_data = form.getFieldsValue();
        dispatch(resetPassword(form_data.new_password, data.user.user.email));
        setPasswordSaved(true)
    }

    const containUpperCase = (str) => {
        return /[A-Z]/.test(str);
    }

    const containLowerCase = (str) => {
        return /(?=.*[a-z])/.test(str);
    }

    const containNumber = (str) => {
        return /(?=.*\d)/.test(str);
    }

    const containSpecialCharacter = (str) => {
        return /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/.test(str);
    }


    const { REACT_APP_API_BUCKER_LOGOS } = process.env;

    let subdomain = window.location.host.split('.')[0];
    if (subdomain.includes("localhost") || subdomain.includes("dashboard") ) {
        subdomain = 'app'
    }
    
    let url = `${REACT_APP_API_BUCKER_LOGOS}${subdomain}`

    useEffect(() => {
        passwordSaved && navigate('/')
    }, [resetPasswordResponse])

    useEffect(() => {
        dispatch(verifyResetPasswordToken(token));
    }, [token]);

    const CustomSpin = styled(Spin)`
        margin-top: 125px;
    `;

    return (
        <Layout className='mt-5'>
        { loading
                ?   <CustomSpin size="large" tip="Loading..." />
                :   <Row>
                        <Col xs={2}  sm={4}  md={7} />
                            <CustomLoginComponent className="card" xs={20} sm={16} md={10} >
                                <Col span={16} offset={4} className='mb-5'>
                                        { type === "success" ? <>
                                            <Col offset={6} span={12} className='mt-5'>
                                                {
                                                    location.pathname.includes("fleet") ? <Img src={`${url}/logoLoginFleet`} alt="logo" />
                                                        :  <Img src={`${url}/logoLogin`} alt="logo" />
                                                }
                                            </Col>
                                            <Row className="margin-login flex items-center justify-center">
                                                <Col span={6}>
                                                    <hr className="divider"/>
                                                </Col>
                                                <CustomLoginComponent className="header" span={12} >
                                                    <span className="font-semibold">{t('reset_title')}</span>
                                                </CustomLoginComponent>
                                                <Col span={6}>
                                                <hr className="divider"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <CustomLoginComponent className="header" span={24} >
                                                    <span className="font-subtitle">{t('reset_password_message')}</span>
                                                </CustomLoginComponent>
                                            </Row>
                                            <Row className='mt-5'>
                                                <CustomForm 
                                                    className='w-100'
                                                    form={form}
                                                    onFinish={handleSave}
                                                >
                                                    <PasswordFormItem  />
                                                    <CustomForm.Item 
                                                        name="confirm_password" 
                                                        rules={[
                                                                { required: true, message: t('Please confirm password', {ns: 'form_error_messages'}) },
                                                                {
                                                                    validator: async (item, value) => {
                                                                        if (form.getFieldValue('new_password') !== value) {
                                                                            return Promise.reject(new Error(t('same_password_message')));
                                                                        }
                                                                    }
                                                                },
                                                            ]}
                                                    >
                                                            <CustomTextInput
                                                                id="confirm_password"
                                                                label="Confirm password"
                                                                type="password"
                                                                variant="outlined"
                                                                size="small"
                                                                required
                                                                fullWidth
                                                                maxLength={60}
                                                            />
                                                    </CustomForm.Item>
                                                    <CustomForm.Item>
                                                        <CustomButton 
                                                            className="login-button"
                                                            type="primary"
                                                            htmlType="submit"
                                                            loginColor={location.pathname.includes("fleet") ? 'fleet' : 'station' }
                                                            block 
                                                        >
                                                            <span className="textButton">
                                                                {t('reset_password_button')}
                                                            </span>
                                                        </CustomButton>
                                                    </CustomForm.Item>
                                                </CustomForm>
                                            </Row>
                                        </>
            
                                            :
            
                                            <Result
                                                key="result-email"
                                                status={type}
                                                title='The link has expired'
                                                subTitle="Please resend the email, and try again."
                                                extra={[
                                                    <Link to="/">
                                                        <CustomButton key="console">
                                                            Go Home
                                                        </CustomButton>
                                                    </Link>
                                                ]}
                                            />
                                        }
                                </Col>
                            </CustomLoginComponent>
                        <Col xs={2}  sm={4}  md={7} />
                    </Row>}
        </Layout>
    );
}

export default PasswordResetForm;
