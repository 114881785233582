import { Spin } from 'antd';
import styled from 'styled-components';

const CustomSpinner = styled(Spin)`
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

export default CustomSpinner;
