import * as React from "react";

function SvgStations({ title, titleId, ...props }) {
  return (
    <svg
      data-name="Grupo 13084"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path data-name="Trazado 11469" d="M0 0h18v18H0z" fill="none" />
      <path
        data-name="Trazado 11470"
        d="M10.5 8.25h.75a1.5 1.5 0 011.5 1.5V12A1.125 1.125 0 0015 12V6.75L12.75 4.5"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="Trazado 11471"
        d="M3 15V4.5A1.5 1.5 0 014.5 3H9a1.5 1.5 0 011.5 1.5V15"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 111"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2 15h9"
      />
      <path
        data-name="Trazado 11472"
        d="M13.5 5.25V6a.75.75 0 00.75.75H15"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 112"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 8h8"
      />
    </svg>
  );
}

export default SvgStations;
