import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/profiles/';
const slice = createSlice({
    name: 'profiles',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        errors: []
    },
    reducers: {
        profilesRequestStarted: (profiles, action) => {
            profiles.loading = true;
        },
        profilesRequestFailed: (profiles, action) => {
            profiles.loading = false;
        },
        profilesReceived: (profiles, action) => {
            profiles.list = action.payload.data;
            profiles.loading = false;
        },
        profileAdded: (profiles, action) => {
            profiles.errors = [];
            profiles.list.unshift(action.payload.data);
            profiles.loading = false;
        },
        profileAddRequestFailed: (profiles, action) => {
            let responseData = action.payload.data;
            profiles.errors = [];
            for (const field in responseData) {
                profiles.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            profiles.loading = false;
        },
        profileDeleted: (profiles, action) => {
            profiles.list = profiles.list.filter(profile => profile.id !== action.payload.requestData);
            profiles.loading = false;
        },
        profileUpdated: (profiles, action) => {
            const index = profiles.list.findIndex(profile => profile.id === action.payload.data.id);
            profiles.errors = [];
            profiles.list[index] = action.payload.data;
            profiles.loading = false;
        }
    }
});

export default slice.reducer;
export const loadProfiles = () => apiCallBegan({
    url,
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profilesReceived.type,
    onError: slice.actions.profilesRequestFailed.type
});

export const addProfile = profile => apiCallBegan({
    url,
    method: 'post',
    data: profile,
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profileAdded.type,
    onError: slice.actions.profileAddRequestFailed.type
});

export const deleteProfile = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profileDeleted.type
});

export const changeStatus = (id, isActive) => apiCallBegan({
    url: `${url}${id}/`,
    method: 'patch',
    data: { is_active: isActive },
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profileUpdated.type
});

export const updateProfile = (profile) => apiCallBegan({
    url: `${url}${profile.id}/`,
    method: 'put',
    data: profile,
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profileUpdated.type,
    onError: slice.actions.profileAddRequestFailed.type
});


export const setPassword = (value) => apiCallBegan({
    url: `${url}set_password`,
    method: 'post',
    data: value,
    onStart: slice.actions.profilesRequestStarted.type,
    onSuccess: slice.actions.profilesRequestFailed.type,
    onError: slice.actions.profilesRequestFailed.type
});