import React, { Fragment, useState } from 'react';
import { Col } from 'antd';

import CustomButton from '../Custom/CustomButton';
import GreenArrowDown from '../icons/GreenArrowDown'
import RedArrowUp from '../icons/RedArrowUp'

const GroupDetailCard = (props) => {

    const {
            textColor,
            border, 
            value, 
            onClickMethod, 
            title, 
            content, 
            buttonMode
    } = props;

    const [selectedBorders, setSelectedBorders] = useState(false);

    border != value && selectedBorders && setSelectedBorders(false);

    return (
        <Fragment>
            {buttonMode ?
                <Col 
                    // xs ={24} 
                    xl={6} xs={12} 
                    className='pr-3 pt-2 pb-2'
                    style={{ width: '100%' }}
                >
                    <CustomButton 
                        block
                        className={
                            value 
                            ? "mincon_button" 
                            : "maxcon_button"} 
                        style={{
                            padding: "0px", 
                            height: '100%', 
                            whiteSpace: 'normal'}} 
                        type="link" 
                        onClick={() => {
                            setSelectedBorders(!selectedBorders);
                            onClickMethod(value, selectedBorders);
                        }} 
                    >
                        <div 
                            style={{ 
                                display: 'flex', 
                                backgroundColor: '#F8F9FB', 
                                borderRadius: '10px', 
                                border: 
                                selectedBorders ? 
                                    (border == value ? 
                                        (value ? 
                                            '1px solid #44E3B2' : '1px solid #FA2D2D') 
                                    : 'none') 
                                : 'none', 
                                padding: '6px',
                                height: '100%'
                            }}
                        >
                            <div 
                                style={{
                                    height: '100%',
                                    width: '80%', 
                                    wordWrap: 'break-word',
                                    paddingLeft: '10px'
                                }}
                            >
                                <p style={{ 
                                    fontSize: '14px', 
                                    color: '#6B7083', 
                                    margin: '0px',
                                }}
                                >
                                    {title}
                                </p>
                                <p 
                                    style={{ 
                                        color: textColor, 
                                        fontWeight: 'bold',
                                        fontSize: '20px' ,
                                        margin: '0px',
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {content} %
                                </p>
                            </div> 
                            <div 
                                style={{ 
                                    width: '20%', 
                                    display: "flex", 
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: 'auto'
                                }}
                            >
                                {value ? <GreenArrowDown/> : <RedArrowUp />}
                            </div>
                        </div>
                    </CustomButton>
                </Col>
                :
                <Col 
                    // xs ={24} 
                    xl={6} xs={12}                    
                    className='pr-3 pt-2 pb-2'
                    style={{ width: '100%' }}
                >
                    <div 
                        style={{ 
                            height: '100%',
                            // width: '100%',
                            display: 'flex', 
                            backgroundColor: '#F8F9FB', 
                            borderRadius: '10px', 
                            border: border, 
                            padding: '6px' 
                        }}
                    >
                        <div 
                            style={{ 
                                width: '80%', 
                                wordWrap: 'break-word',
                                paddingLeft: '10px'
                            }}
                        >
                            <p 
                                style={{
                                    fontSize: '14px', 
                                    color: '#6B7083', 
                                    margin: '0px'}}
                            >
                                {title}
                            </p>
                            <p 
                                style={{ 
                                    color: textColor, 
                                    fontWeight: 'bold', 
                                    fontSize: '20px' ,
                                    margin: '0px'
                                }}
                            >
                                {content}
                            </p>
                        </div>
                    </div>
                </Col>
            }
        </Fragment>
    );
}

export default GroupDetailCard;