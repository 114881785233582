import * as React from "react";

function SalesIcon({ title, titleId, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Grupo_15248" data-name="Grupo 15248" transform="translate(-826 428)">
        <circle id="Elipse_202" data-name="Elipse 202" cx="10" cy="10" r="10" transform="translate(826 -428)" fill="#ebf2fd"/>
        <g id="vuesax_linear_tag-2" data-name="vuesax/linear/tag-2" transform="translate(829.666 -424.334)">
          <g id="tag-2">
            <path id="Vector" d="M.742,6.612,3.133,9A2.523,2.523,0,0,0,6.7,9L9.013,6.686a2.523,2.523,0,0,0,0-3.563L6.617.737A2.508,2.508,0,0,0,4.716,0L2.077.13A2.04,2.04,0,0,0,.129,2.067L0,4.707A2.536,2.536,0,0,0,.742,6.612Z" transform="translate(1.584 1.584)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Vector-2" data-name="Vector" d="M2.639,1.32A1.32,1.32,0,1,1,1.32,0,1.32,1.32,0,0,1,2.639,1.32Z" transform="translate(3.819 3.815)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-width="1"/>
            <path id="Vector-3" data-name="Vector" d="M0,2.111,2.111,0" transform="translate(6.986 6.982)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            <path id="Vector-4" data-name="Vector" d="M0,0H12.669V12.669H0Z" fill="none" opacity="0"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SalesIcon;