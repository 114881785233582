import { combineReducers } from "redux";
import usersReducer from './users';
import profilesReducer from './profiles';
import stationsReducer from './stations';
import rolesReducer from './roles';
import productsReducer from './products';
import vehicleBrandsReducer from './vehicleBrands';
import vehicleModelsReducer from './vehicleModels';
import vehiclesReducer from './vehicles';
import statesReducer from './states';
import citiesReducer from './cities';
import dispenserTypesReducer from './dispenserTypes';
import dispensersReducer from './dispensers';
import customersReducer from './customers';
import servicesReducer from './services';
import permissionsReducer from './permissions';
import contentTypesReducer from './contentTypes';
import authorizationsReducer from './authorizations';
import authenticationReducer from './authentication';
import fleetsReducer from './fleets';

export default combineReducers({
    users: usersReducer,
    profiles: profilesReducer,
    stations: stationsReducer,
    roles: rolesReducer,
    products: productsReducer,
    vehicles: vehiclesReducer,
    states: statesReducer,
    cities: citiesReducer,
    dispenserTypes: dispenserTypesReducer,
    dispensers: dispensersReducer,
    vehicleBrands: vehicleBrandsReducer,
    vehicleModels: vehicleModelsReducer,
    services: servicesReducer,
    authorizations: authorizationsReducer,
    permissions: permissionsReducer,
    contentTypes: contentTypesReducer,
    customers: customersReducer,
    authentication: authenticationReducer,
    fleets: fleetsReducer
});