import { Row, Col } from 'antd';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import MenuItem from '@mui/material/MenuItem';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';
import CustomFormItem from '../Custom/CustomFormItem';
import { useTranslation } from 'react-i18next';

const CreateStationForm = (props) => {

    const { t } = useTranslation();

    const statusOptions = [
        {
            name: 'Active',
            value: true
        },
        {
            name: 'Inactive',
            value: false
        }
    ];

    return (
        <CustomForm
            form={props.form}
            onFinish={props.onFinish}
            initialValues={{ is_active: true, user: '' }}
        >
            <CustomFormItem label="id" name="id" hidden={true}>
                <CustomTextInput />
            </CustomFormItem>
            <CustomFormItem
                name="name"
                rules={[{ required: true, message: 'Please input the name' }]}
            >
                <CustomTextInput
                    id="name"
                    label={t('stations.modal.name')}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    disabled={props.isDetailsOnly} maxLength={140}
                />
            </CustomFormItem>
            <CustomFormItem
                name="street"
                rules={[{ required: true, message: 'Please input the address street' }]}
            >
                <CustomTextInput
                    id="street"
                    label={t('stations.modal.address_street')}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    disabled={props.isDetailsOnly}
                    maxLength={240}
                />
            </CustomFormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomFormItem
                        name="city_name"
                        rules={[{ required: true, message: 'Please input the city' }]}
                    >
                        <CustomTextInput
                            id="city_name"
                            label={t('stations.modal.city')}
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                </Col>
                <Col span={12}>
                    <CustomFormItem
                        name="state_name"
                        rules={[{ required: true, message: 'Please input the state' }]}
                    >
                        <CustomTextInput
                            id="state_name"
                            label={t('stations.modal.state')}
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <CustomFormItem
                        name="zip_code"
                        rules={[{ required: true, message: 'Please input the zip code' }]}
                    >
                        <CustomTextInput
                            id="zip_code"
                            label={t('stations.modal.zip_code')}
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            disabled={props.isDetailsOnly} maxLength={5}
                        />
                    </CustomFormItem>
                </Col>
{/*                <Col span={12}>
                    <CustomFormItem name="is_active">
                        <CustomTextInput
                            label="Status"
                            size="small"
                            select
                            fullWidth
                            placeholder="Please select a status"
                            disabled={props.isDetailsOnly}
                        >
                            {statusOptions.map(status =>
                                <MenuItem key={status.name} value={status.value}>
                                    {status.name}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>*/}
            </Row>
            <CustomFormItem
                name="phone"
                rules={[{
                    required: true,
                    message: 'Please input a valid phone',
                    min: 10,
                    pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                }]}
            >
                <CustomTextInput
                    id="phone"
                    label={t('stations.modal.phone')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    type='tel'
                    disabled={props.isDetailsOnly}
                    inputProps={{
                        maxLength: 14,
                    }}
                />
            </CustomFormItem>
            <CustomFormItem
                name="manager"
                rules={[{ required: true, message: 'Please select an Stations Manager' }]}
            >
                <CustomMultipleSelect 
                    label={t('stations.modal.station_manager')}
                    fieldName="manager"
                    form={props.form}
                    options={props.stationManagers}
                    disabled={props.isDetailsOnly}
                />
            </CustomFormItem>
            <CustomFormItem
                name="dispatchers"
            >
                <CustomMultipleSelect
                    label={t('stations.modal.station_worker')}
                    fieldName='dispatchers'
                    form={props.form}
                    options={props.dispatchers}
                    disabled={props.isDetailsOnly}
                />
            </CustomFormItem>
        </CustomForm>
    );
}

export default CreateStationForm;
