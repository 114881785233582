import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { verifyEmail } from '../../store/authentication';
import { Result, Spin } from 'antd';
import CustomButton from '../Custom/CustomButton';
import { useTranslation } from 'react-i18next';

const EmailVerification = () => {
    const { t } = useTranslation('common_labels');
    const dispatch = useDispatch();
    const { loading, emailVerificationResponse } = useSelector(state => state.entities.authentication);
    const { data, type } = emailVerificationResponse;
    const { token } = useParams();

    const EMAIL_VERIFIED_MSG = t('email_verified_msg');
    const EMAIL_VERIFIED_ERROR_MSG = t('email_verified_error_msg');

    useEffect(() => {
        dispatch(verifyEmail(token));
    }, [dispatch, token]);

    return (
        loading ? <Spin size="large" tip="Loading..." style={{ marginTop: '125px' }} />
            : <Result
                key="result-email"
                status={type}
                title={type === "success" ? EMAIL_VERIFIED_MSG : EMAIL_VERIFIED_ERROR_MSG}
                subTitle={data ? t(data.detail) : ""}
                extra={[
                    <Link to="/">
                        <CustomButton key="console">
                            {t('email_verified_button')}
                        </CustomButton>
                    </Link>
                ]}
            />
    )
}

export default EmailVerification;
