import styled from 'styled-components';
import { Table } from "ant-table-extensions";
import { useTranslation } from 'react-i18next';

const StyledTable = styled(Table)`

    .ant-table-container {
        padding: 20px;
    }

    &.services-table {
        margin-top: 1em;
        width: 100%;
        .ant-table-container {
            padding: 0;
        }
    }

    .ant-table-content {
        overflow-x: auto!important;
    }

    tr {
        border-radius: 80px !important;
        background-color: none !important; 
    }
    
    .ant-table-thead th {
        background-color: #F8F9FB 0% 0% no-repeat padding-box !important; 
    }

    .ant-table-thead th:first-child {

        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .ant-table-thead th:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .ant-pagination {
        margin-top: 67px;
    }

    .ant-pagination-item {
        background-color: #FFFFFF !important;
        border-color: #C4C4C4 !important;
        border-radius: 4px !important;
        a { 
            color: #C4C4C4 !important;
        }
    }

    .ant-pagination-item-active {
        background-color: ${props => props.theme.primaryColor} !important;
        border-color: ${props => props.theme.primaryColor} !important;
        border-radius: 4px !important;
        a {
            color: #FFFFFF !important;
        }
    }

    .ant-pagination-item-link {
        :hover {
            border-color: ${props => props.theme.primaryColor} !important;
            color: ${props => props.theme.primaryColor} !important;
        }
    }

    th.ant-table-cell {
        background-color: #F8F9FB;
    }

    .ant-table-expanded-row > .ant-table-cell {
        background: #F8F9FB;
        padding: 0px;
        .authRow {
            padding-left: 49px;
            padding-top: .5em;
            padding-bottom: .4em;
        }
        .authRow:not(:last-child) {
            border-bottom: 1px solid #c2cfe066;
        }
        .column {
            width: 20%;
            display: inline-block;
            padding-right: 16px;
            padding-left: 16px;
        }
        .tag{
            display: inline-block;
            width: 24%;
            padding-right: 16px;
            padding-left: 16px;
        }
    }
`;

const CustomTable = (props) => {

    const { columns } = props;
    const { t } = useTranslation('table_labels');

    columns.forEach(column => {
        if (column.title === "services_user" || column.title === "amount_gallon") {
            column.title = t(column.title);
        } else {
            column.title = t(column.dataIndex)
        }

        if (column.suffix) {
            let title = column.title;

            column.title = value => <><span>{title}</span> {column.suffix}</>;
        }
    });

    return (
        <StyledTable {...props}/>
    )
}

export default CustomTable;
