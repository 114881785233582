import * as React from "react";

function SvgVehicles({ title, titleId, ...props }) {
  return (
    <svg
      data-name="Grupo 13096"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19.571 19.571"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path data-name="Trazado 11467" d="M0 0h19.571v19.571H0z" fill="none" />
      <circle
        data-name="Elipse 133"
        cx={2}
        cy={2}
        r={2}
        transform="translate(4.051 12.027)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <circle
        data-name="Elipse 134"
        cx={2}
        cy={2}
        r={2}
        transform="translate(12.027 12.027)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="Trazado 11468"
        d="M4.271 14.357H2.696V8.964L4.271 4.47h7.089l3.151 4.494h.785a1.7 1.7 0 011.575 1.8v3.6h-.845m-3.878 0H7.883m-5.187-5.4h11.815m-4.726 0V4.47"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default SvgVehicles;
