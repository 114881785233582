import React, { Fragment } from 'react';
import { useSelector } from "react-redux";
import CustomButton from '../Custom/CustomButton';

import ReportIcon from '../icons/ReportIcon';


const DownloadReports = (props) => {
    const {  translationFunction, data, disabled, reportType, icon } = props;
    const { config } = useSelector(state => state.entities.authentication);

    const { volume_unit } = config;

    const downloadReport = () =>{
        if (reportType) {
            let row;
            const rows = ["number", "user", "station", "product", "price", "quantity", "invoice_amount", "created_at", "unit number", "plate number", "odometer"];
            const translatedRows = rows.map(row => translationFunction('report.' + row));
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += translatedRows.join(',') + "\r\n";
            let totalGallons = 0
            let totalAmount = 0
            data.forEach(function (s) {
                let { user, station, product, price, quantity, invoice_amount, created_at, odometer, unit_code, plate_number} = s;
                row = `${s?.number},"${user?.first_name} ${user?.last_name}","${station?.name}","${product?.name}","$ ${price}","${quantity}","$ ${invoice_amount}","${created_at}","${unit_code}","${plate_number}","${odometer}"`;
                totalGallons += parseFloat(quantity);
                totalAmount += parseFloat(invoice_amount);
                csvContent += row + "\r\n";
            });
            csvContent += "\r\n".repeat(4)
            csvContent += `,,,${translationFunction('report.totals')},,$${totalAmount.toFixed(2)} ${translationFunction(volume_unit, { ns: "volume_units", count: 2 })},${totalGallons.toFixed(2)}`
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "services_data.csv");
            document.body.appendChild(link);
            link.click();
            link.remove();

        } else {
            const rows = ["number", "user", "station", "product", "price", "quantity", "invoice_amount", "created_at", "customer"];
            const translatedRows = rows.map(row => translationFunction('report.' + row));
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += translatedRows.join(',') + "\r\n";
            let totalGallons = 0
            let totalAmount = 0
            data.forEach(function (s) {
                let { user, station, product, price, quantity, invoice_amount, created_at, customer } = s;
                let row = `${s?.number},"${user?.first_name} ${user?.last_name}","${station?.name}","${product?.name}","$ ${price}","${quantity}","$ ${invoice_amount}","${created_at}","${customer}"`;
                totalGallons += parseFloat(quantity);
                totalAmount += parseFloat(invoice_amount);
                csvContent += row + "\r\n";
            });
            csvContent += "\r\n".repeat(4)
            csvContent += `,,,${translationFunction('report.totals')},,$${totalAmount.toFixed(2)} ${translationFunction(volume_unit, { ns: "volume_units", count: 2 })},${totalGallons.toFixed(2)}`
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "services_data.csv");
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }


    return (
        <Fragment>
            <CustomButton style={{ padding: '4px 1em 4px 1em', display: 'flex', alignItems: 'center', marginLeft: 'auto'}} type="primary"  onClick={downloadReport} >
                <ReportIcon /> 
                <span className='ml-3'>
                    {translationFunction('export_button')}
                </span>
            </CustomButton>
        </Fragment>
    );
}

export default DownloadReports;
