import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Layout, Row, Col, Form, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../Custom/CustomButton';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import CustomLink from '../Custom/CustomLink';
import styled from 'styled-components';
import BackArrowIcon from '../icons/BackArrowIcon';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomLoginComponent from '../Custom/CustomLoginComponent';
import './Login.css'
import { useDispatch , useSelector} from 'react-redux';
import { loadLogin, sendResetPasswordEmail, refreshErrors } from '../../store/authentication';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Img = styled.img`
    width: 90%;
`;

const PasswordReset = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation('forgot_password');
    const [email, setEmail] = useState(null);
    const [emailVerificationMessage, setEmailVerificationMessage] = useState('');
    let { loading, resetPasswordResponse, resetPasswordErrors} = useSelector(state => state.entities.authentication);
    const { data, type } = resetPasswordResponse;

    const handleSave = () => {
        let data = form.getFieldsValue();
        setEmail(data.email)
        
        dispatch(sendResetPasswordEmail(data.email));
    }

    const { REACT_APP_API_BUCKER_LOGOS } = process.env;

    let subdomain = window.location.host.split('.')[0];
    if (subdomain.includes("localhost") || subdomain.includes("dashboard") ) {
        subdomain = 'app'
    }
    
    let url = `${REACT_APP_API_BUCKER_LOGOS}${subdomain}`

    useLayoutEffect(() => {
        type === "success" && navigate('/')
    }, [resetPasswordResponse])

    useLayoutEffect(() => {
        if (resetPasswordErrors.length) {
            setEmailVerificationMessage(t('user_not_found_message'));
        } 
        else if (type === "success") {
            setEmailVerificationMessage('');
        }
    }, [loading])

    const LoginCard = styled(Col)`
        background-color: #FFF;
        box-shadow: 0px 3px 10px #65C0C458;

    `;

    const Header = styled(Col)`
        text-align: center;

    `;

    const handleRefreshErrors = () => {
        dispatch(refreshErrors())
    }

    return (
        <Layout className='mt-5'>
            <Row>
                <Col xs={2}  sm={4}  md={7} />
                    <CustomLoginComponent className="card" xs={20} sm={16} md={10} >

                        <Col span={16} offset={4} className='mb-5 mt-5'>
                            <Col offset={6} span={12}>
                                {
                                    location.pathname.includes("fleet") ? <Img src={`${url}/logoLoginFleet`} alt="logo" />
                                        :  <Img src={`${url}/logoLogin`} alt="logo" />
                                }
                            </Col>
                            <Row className="margin-login flex items-center justify-center">
                                <Col span={3}>
                                    <hr className="divider"/>
                                </Col>
                                <CustomLoginComponent className="header" span={18}>
                                    <span className="font-semibold" > 
                                        {t('title')}
                                    </span>
                                </CustomLoginComponent>
                                <Col span={3}>
                                    <hr className="divider"/>
                                </Col>
                            </Row>
                            <Row>
                                <CustomLoginComponent className="header" span={24}>
                                    <span className="font-subtitle" > 
                                        {t('welcome_message')}
                                    </span>
                                </CustomLoginComponent>
                            </Row>
                            <Row className="mt-5">
                                <CustomForm 
                                    className="w-100"
                                    form={form}
                                    onFinish={handleSave}
                                >
                                    <CustomFormItem 
                                        name="email"
                                        rules={[
                                            { 
                                                type: 'email', 
                                                required: true, 
                                                message: 'Please input an email' 
                                            }
                                        ]}
                                    >
                                            <CustomTextInput
                                                    id="email"
                                                    label="Email address"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    maxLength={60}
                                                    helperText={emailVerificationMessage}
                                                    error={resetPasswordErrors.length}
                                                />
                                    </CustomFormItem>
                                    <CustomFormItem>
                                        <CustomButton 
                                            className="login-button"
                                            type="primary"
                                            htmlType="submit"
                                            loginColor={
                                                location.pathname.includes("fleet") 
                                                ? 'fleet' 
                                                : 'station' 
                                            }
                                            block loading={loading}
                                        >
                                            <span className="textButton">
                                                {t('button')}
                                            </span>
                                        </CustomButton>
                                    </CustomFormItem>
                                    <Row justify='center' align='middle'>
                                        <BackArrowIcon />
                                        <CustomLink className="back-login-link" to="/" onClick={handleRefreshErrors}>
                                            {t('back_to_login')}
                                        </CustomLink>
                                    </Row>
                                </CustomForm>
                            </Row>
                        </Col>
                    </CustomLoginComponent>
                <Col xs={2}  sm={4}  md={7} />
            </Row>
        </Layout>
    );
}

export default PasswordReset;
