import { Breadcrumb } from 'antd';
import styled from 'styled-components'

const BreadcrumbCustomStyle = styled(Breadcrumb)`
color: ${props => props.theme.primaryColor} !important;
`;

const BreadcrumbSeparator = styled(Breadcrumb.Separator)`
color: ${props => props.theme.primaryColor} !important;
`;

const BreadcrumbCustom = props => (
    <BreadcrumbCustomStyle separator="">
        {props.items.map(item => <Breadcrumb.Item key={item}>{item}<BreadcrumbSeparator>></BreadcrumbSeparator></Breadcrumb.Item>)}
    </BreadcrumbCustomStyle>
)

export default BreadcrumbCustom