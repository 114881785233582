import React, { useState, useEffect, Fragment } from 'react';
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Form, Typography } from 'antd';
import {
    loadStations,
    addStation,
    updateStation,
    changeStatus,
    loadStationManagers,
    loadStationWorkers
    // deleteStation,
} from '../../store/stations';
import CreateModal from '../Custom/CreateModal';
import CreateStationForm from './CreateStationForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import IconButton from '../Custom/IconButton';
import ModuleHeader from '../Custom/ModuleHeader';
import SvgEditIcon from "../icons/EditIcon"
// import SvgDeleteIcon from '../icons/DeleteIcon';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomTag from '../Custom/CustomTag';
import CustomSearhInput from '../Custom/CustomSearchInput';
// import ConfirmModal from '../Custom/ConfirmationModal';
import ActionsStations from './ActionsStations';
import { STATUS } from '../constants';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Stations = () => {
    const MODULE_NAME = 'station';
    const { t } = useTranslation([MODULE_NAME, 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [titleModal, setTitleModal] = useState("Create Station");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { hasResourcePermission } = PermissionValidator();
    const { list, loading, errors, loadingAction } = useSelector(state => state.entities.stations);
    let stationsLength = list.length;
    const indexedList = list.map(e => ({...e, 'index': stationsLength--}));
    const stationManagers = useSelector(state => state.entities.stations.managersList);
    const dispatchers = useSelector(state => state.entities.stations.dispatchersList);
    const { config } = useSelector(state => state.entities.authentication);
    let managersList = [].concat(stationManagers);
    let managersNamesList = managersList.map(manager => { return {name: `${manager.first_name} ${manager.last_name}`, id: manager.id}})
    let dispatchersList = dispatchers.map(dispatcher => (
        {
            label: `${dispatcher.first_name} ${dispatcher.last_name}`,
            value: dispatcher.id,
            disabled: !dispatcher.is_active
        }
    ));

    let stationManagersList = stationManagers.map(manager => (
        {
            label: `${manager.first_name} ${manager.last_name}`,
            value: manager.id,
            disabled: !manager.is_active
        }
    ));

    dispatchersList && dispatchersList.sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        dispatch(loadStations());
        dispatch(loadStationManagers());
        dispatch(loadStationWorkers());
    }, [dispatch]);

    useEffect(() => {
        form.setFields(errors);
        if (errors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, errors]);

    const columns = [
        {
            dataIndex: 'index',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}> { record.index } </Text>
        },
        {
            dataIndex: 'name',
            id: 'name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (number) => <Text> {number} </Text>
        },
        {
            dataIndex: 'managers',
            key: 'managers',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (managerIds) => {
                const LAST_INDEX = managerIds.length;

                return managerIds.map((id, index) => {

                    index += 1;

                    let manager = managersList.find(
                        manager => manager.id === id
                    );

                    manager = `${manager?.first_name}  
                        ${manager?.last_name}
                        ${index < LAST_INDEX ? ", " : ""}
                    `
                    return (
                        <Text>
                            {manager}
                        </Text> 
                    )
                }
                )
            }
        },
        {
            dataIndex: 'address',
            key: 'address',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => {
                return <Text>{`${record.street} ${record.city_name} ${record.state_name}`}</Text>
            }
        },
        {
            dataIndex: 'zip_code',
            key: 'zip_code',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (zip_code) => <Text > {zip_code} </Text>
        },
        {
            dataIndex: 'is_active',
            key: 'is_active',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: is_active => {
                let status = (is_active) ? STATUS.active : STATUS.inactive;
                return (
                    <CustomTag colors={status.colors}>
                        {status.label}
                    </CustomTag>
                )
            }
        },
        {
            dataIndex: 'phone',
            key: 'phone',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (phone) => <Text> {phone} </Text>
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasViewPermissions = hasResourcePermission(`read_${MODULE_NAME}`);
                let hasEditPermissions = hasResourcePermission(`update_${MODULE_NAME}`);
                // let hasDeletePermissions = hasResourcePermission(`delete_${MODULE_NAME}`);
                return (
                    <Fragment>
                        <Space size="middle">
                            <IconButton
                                isVisible={hasEditPermissions}
                                hover_color='mdc-icon-button__edit'
                                onClick={() => showModal(record)}
                                icon={<SvgEditIcon />}
                            />
                        </Space>
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasDeletePermissions}
                                hover_color='mdc-icon-button__delete'
                                onClick={() => ConfirmModal({
                                    "onConfirmedDeletion": onConfirmDeletion,
                                    "moduleName": MODULE_NAME,
                                    "recordId": record.id,
                                    "name": record.name
                                })}
                                icon={<SvgDeleteIcon />}
                            />
                        </Space> */}
                        <Space size="middle">
                            <ActionsStations
                                hasEditPermissions={hasEditPermissions}
                                hasViewPermissions={hasViewPermissions}
                                clickChangeStatus={clickChangeStatus}
                                station={record}
                                openModal={showModal}
                            />
                        </Space>
                    </Fragment>
                );
            }
        }
    ];

    const resetForm = () => {
        form.resetFields();
    };

    const showModal = (station = [], detailsOnly = false) => {
        let newModalTitle = "Create Station";

        if (station.id) {
            let stationData = { ...station };
            let { managers, workers } = station;
            let assignedDispatchers = [];


            workers.forEach(workerId => {
                let dispatcher = dispatchers.find(dispatcher => dispatcher.id === workerId);
                if (dispatcher) {
                    assignedDispatchers.push(dispatcher.id);
                }
            });

            stationData.dispatchers = assignedDispatchers;
            stationData.manager = managers ? managers : null;
            form.setFieldsValue(stationData);
            newModalTitle = detailsOnly ? "Station Detail" : "Edit Station";
        }

        setTitleModal(newModalTitle)
        setIsDetailsOnly(detailsOnly);
        setIsModalVisible(true);
    };

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    
    const handleSearch = (event) => {
        let 
            value = (event.target.value).trim().toLowerCase(),
            filteredManagers = managersNamesList.filter(manager =>
                manager.name.toLowerCase().includes(value)
                );
        
        let filtered = indexedList.filter(element => {
            const { name, managers } = element;
            
            return (
                name.toLowerCase().includes(value) ||
                managers.some(managerId => 
                    filteredManagers.some(manager => 
                        manager.id === managerId
                        )
                    )
            );
        });

        setSearch(value);
        setUserSerch(filtered);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        resetForm();
    }

    const handleSave = (values) => {
        values.owners = [];
        values.managers = values.manager;
        values.workers = values.dispatchers;
        delete values.manager;
        if (!isDetailsOnly) {
            if (values.id)
                dispatch(updateStation(values));
            else
                dispatch(addStation(values));
        } else {
            setIsDetailsOnly(false);
            setTitleModal("Edit Station");
        }
    }

    // const onConfirmDeletion = (recordId) => {
    //     dispatch(deleteStation(recordId));
    // }

    const clickChangeStatus = (recordId, is_active) => {
        dispatch(changeStatus(recordId, is_active))
    }
    let hasEditPermissions = hasResourcePermission(`update_${MODULE_NAME}`);

    return (
        <Fragment>
            <CreateModal
                title={titleModal}
                okText={isDetailsOnly ? 'Edit' : 'Save' }
                width={800}
                visible={isModalVisible}
                getContainer={false}
                onCancel={handleCancel}
                cancelText={hasEditPermissions ? 'Cancenl' : 'Close'}
                okButtonProps={{ onClick: form.submit, 
                    loading: loadingAction,
                    style: { display: hasEditPermissions ? '' : 'none'}
                }}
            >
                <CreateStationForm
                    form={form}
                    onFinish={handleSave}
                    stationManagers={stationManagersList}
                    dispatchers={dispatchersList}
                    isDetailsOnly={isDetailsOnly}
                />
            </CreateModal>
            <Row>
                <Col xs={12}>
                    <ModuleHeader>{t('header')}</ModuleHeader>
                </Col>
                <Col xs={12}>
                    <CustomButton
                        className="create-button"
                        onClick={showModal}
                        type="primary"
                        style={{
                            float: 'right',
                            display: hasResourcePermission(`create_${MODULE_NAME}`) ? '' : 'none'
                        }}
                    >
                        {t('button')} 
                    </CustomButton>
                </Col>
                <Col xs={0}>
                    <BreadcrumbCustom items={["Home", "Station List"]} />
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <CustomSearhInput 
                        placeholder={t('common_labels.search_bar')}
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <CustomTable 
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                columns={columns}
                dataSource={searchValue ? usersSerch : indexedList}
                rowKey='id'
                loading={loading} />
        </Fragment>
    );
}

export default Stations;
