import * as React from "react";

function SvgAuthorizations({ title, titleId, ...props }) {
  return (
    <svg
      data-name="Grupo 13087"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19.329 19.329"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path data-name="Trazado 11474" d="M0 0h19.329v19.329H0z" fill="none" />
      <rect
        data-name="Rect\xE1ngulo 17282"
        width={5}
        height={5}
        rx={1}
        transform="translate(3 3.259)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 117"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6 13.259h0"
      />
      <rect
        data-name="Rect\xE1ngulo 17283"
        width={5}
        height={5}
        rx={1}
        transform="translate(11 3.259)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 118"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6 5.259h0"
      />
      <rect
        data-name="Rect\xE1ngulo 17284"
        width={5}
        height={5}
        rx={1}
        transform="translate(3 11.259)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 119"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14 5.259h0"
      />
      <path
        data-name="L\xEDnea 120"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11 11.259h3"
      />
      <path
        data-name="L\xEDnea 121"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 11.259h0"
      />
      <path
        data-name="L\xEDnea 122"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11 11.259v2"
      />
      <path
        data-name="L\xEDnea 123"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11 16.259h3"
      />
      <path
        data-name="L\xEDnea 124"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14 13.259h2"
      />
      <path
        data-name="L\xEDnea 125"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 13.259v3"
      />
    </svg>
  );
}

export default SvgAuthorizations;
