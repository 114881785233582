import * as React from "react";

function RedArrowUp({ title, titleId, ...props }) {
  return (
    <svg id="arrow-up" xmlns="http://www.w3.org/2000/svg" width="38.525" height="38.525" viewBox="0 0 38.525 38.525">
      <path id="Vector" d="M32.1,16.052A16.052,16.052,0,1,1,16.052,0,16.052,16.052,0,0,1,32.1,16.052Z" transform="translate(3.21 3.21)" fill="#fff"/>
      <g id="Grupo_15028" data-name="Grupo 15028" transform="translate(14.447 13.644)">
        <path id="Vector-2" data-name="Vector" d="M0,9.631V0" transform="translate(4.816 1.605)" fill="none" stroke="#fa2d2d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Vector-3" data-name="Vector" d="M0,4.816,4.816,0,9.631,4.816" fill="none" stroke="#fa2d2d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
      <path id="Vector-4" data-name="Vector" d="M0,0H38.525V38.525H0Z" transform="translate(38.525 38.525) rotate(180)" fill="none" opacity="0"/>
    </svg>
  );
}

export default RedArrowUp;
