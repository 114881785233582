import * as React from "react";

function SavedCO2({ title, titleId, ...props }) {
  return (
    <svg id="Grupo_15243" data-name="Grupo 15243" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19.516 19.516">
      <path id="Trazado_1686" data-name="Trazado 1686" d="M0,0H19.516V19.516H0Z" fill="none"/>
      <g id="Grupo_15210" data-name="Grupo 15210" transform="translate(3.242 3.253)">
        <path id="Trazado_1688" data-name="Trazado 1688" d="M8.063,15.384c5.056,0,8.538-2.674,8.945-9.758V4H13.744C6.425,4,4,7.253,3.986,11.318a5.256,5.256,0,0,0,1.626,4.066H8.052Z" transform="translate(-3.986 -4)" fill="#74d583" stroke="#74d583" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Trazado_1687" data-name="Trazado 1687" d="M5,19.132c.407-3.659,2.033-6.505,5.692-8.132" transform="translate(-4.176 -5.308)" fill="none" stroke="#74d583" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      </g>
    </svg>
  );
}

export default SavedCO2;

