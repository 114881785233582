import React from 'react';
import styled from 'styled-components';
import { Typography, Tooltip } from 'antd';

import SavedCO2 from '../icons/SavedCO2';
import TaxCredit from '../icons/TaxCredit';

const { Text } = Typography;

const CustomDiv = styled.div`
        padding: 4px;
        background-color: white;
        .container {
            position: relative;
            ::before {
                position: absolute;
                top: 8px;
                left: 0;
                background-color: #C2CFE0;
                height: 60%;
                width: 1px;
                content: '';
            }
        }
`;

const TaxCo2Card = (props) => {

    const { values } = props;

    const multipleValues = values.length > 1;

    return (
        <CustomDiv style={{ display: 'flex', borderRadius: '10px 10px 10px 10px', border:'1px solid #C2CFE0', padding: '3px'}}>
            {values.map((value, index) =>  
                <Tooltip title={value['label']}>
                    <div 
                        style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className={ multipleValues && index == (values.length - 1) && 'container'}
                    >
                        {/*<p style={{ margin: '0px', color: '#F3A141', fontSize: '10px'}}>Pounds Of Co2 Saved</p>*/}
                        <Text style={{ marginRight: '.3em', fontSize: '17px'}}>
                            {value['value']
                                ? value['value'].toFixed(2) 
                                : '0'
                            } 
                        </Text>
                        {value['label'] == 'Tax Credit' ? <TaxCredit /> : <SavedCO2 />}
                    </div>
                </Tooltip>
            )
        }
        </CustomDiv>
    );
}

export default TaxCo2Card;