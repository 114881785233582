import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CustomLink = styled(Link)`
    text-decoration: underline;
    color: ${props => props.theme.primaryColor};
    :hover {
        color: ${props => props.theme.primaryColor};
    };

    &.back-login-link {
        color: #35393C;
        text-decoration: None;
        margin-left: .5em;
    }
`;

export default CustomLink;