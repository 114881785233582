import * as React from "react";

function GallonIcon({ title, titleId, ...props }) {
  return (
    <svg id="Grupo_15235" data-name="Grupo 15235" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <rect id="Rectángulo_17953" data-name="Rectángulo 17953" width="50" height="50" rx="3" fill="#f4f0ff"/>
      <g id="Grupo_15234" data-name="Grupo 15234" transform="translate(4 4)">
        <rect id="Rectángulo_17920" data-name="Rectángulo 17920" width="42" height="42" fill="none"/>
        <g id="Grupo_15117" data-name="Grupo 15117" transform="translate(9.255 4.348)">
          <path id="Trazado_13710" data-name="Trazado 13710" d="M20.305,548.447H2.9a.887.887,0,0,0-.207-.062,2.7,2.7,0,0,1-2.151-1.232,2.658,2.658,0,0,1-.488-1.57,2.9,2.9,0,0,1,.408-1.532c.091-.154.2-.3.31-.455A3.367,3.367,0,0,1,0,541.226q0-8.118,0-16.237a1.674,1.674,0,0,1,.516-1.264c.267-.263.511-.548.763-.826q.28-.309.554-.625c.238-.275.47-.555.711-.827.256-.288.526-.564.782-.853.234-.264.456-.539.686-.807a1.055,1.055,0,0,1,.543-.385,2.858,2.858,0,0,1,.634-.085q4.51-.008,9.02,0a1.4,1.4,0,0,1,1.119.464c.455.513.922,1.014,1.388,1.516.364.393.735.78,1.1,1.17l1.013,1.077c.338.359.673.72,1.013,1.077.518.543,1.04,1.083,1.558,1.625.437.457.874.914,1.307,1.375a4.454,4.454,0,0,1,.324.4.479.479,0,0,1,.093.2,4.547,4.547,0,0,1,.075.668c0,3.275.011,6.551,0,9.826a30.845,30.845,0,0,1-.053,3.216,2.536,2.536,0,0,1-.242.925c-.138.263-.3.516-.453.784l.092.121a2.869,2.869,0,0,1,.515,2.555,2.624,2.624,0,0,1-.814,1.325,2.672,2.672,0,0,1-1.7.746,1.194,1.194,0,0,0-.239.062m-.965-18.561a1.634,1.634,0,0,0-.263-1.093,4.651,4.651,0,0,0-.468-.57c-.219-.236-.459-.453-.678-.689-.445-.478-.877-.969-1.325-1.444-.567-.6-1.144-1.191-1.716-1.786-.044-.046-.082-.1-.122-.149a2.276,2.276,0,0,0-1.948-.99,1.976,1.976,0,0,0-1.729,1.358,2.044,2.044,0,0,0,.5,2.026c.493.523.993,1.038,1.49,1.557l1.377,1.443.044.047c.494.535.969,1.09,1.487,1.6a1.9,1.9,0,0,0,1.822.552,1.836,1.836,0,0,0,1.089-.639,1.65,1.65,0,0,0,.44-1.224m-7.75,12.75a2.733,2.733,0,0,0,.513,0,2.9,2.9,0,0,0,1.823-1.1,2.84,2.84,0,0,0,.494-2.492,4.677,4.677,0,0,0-.338-.9,20.112,20.112,0,0,0-1.566-2.693,1,1,0,0,0-1.045-.533.54.54,0,0,0-.1,0,1.062,1.062,0,0,0-.318.135,2.038,2.038,0,0,0-.367.4,19.245,19.245,0,0,0-1.556,2.661,3.769,3.769,0,0,0-.439,1.823,2.789,2.789,0,0,0,.931,1.981,2.592,2.592,0,0,0,1.965.721" transform="translate(0 -515.444)" fill="#905efd" opacity="0.497"/>
          <path id="Trazado_13711" data-name="Trazado 13711" d="M52.621,476.256c-.264.057-7.243.073-7.687.019a3.556,3.556,0,0,1,0-.908c0-.021.016-.041.017-.062.057-.639.475-.921,1.139-.926,1.793-.014,3.586-.008,5.379,0a1.818,1.818,0,0,1,.533.1.829.829,0,0,1,.57.644,3.568,3.568,0,0,1,.052,1.134" transform="translate(-41.036 -474.371)" fill="#905efd"/>
          <path id="Trazado_13712" data-name="Trazado 13712" d="M125.1,734.074a1.075,1.075,0,0,1,.123.144,8.348,8.348,0,0,1,.743,1.493.867.867,0,0,1-.431,1.118.933.933,0,0,1-1.029-.113.873.873,0,0,1-.268-.937,1.385,1.385,0,0,1,.112-.3q.261-.535.533-1.065c.057-.111.134-.212.216-.339" transform="translate(-113.508 -711.712)" fill="#905efd"/>
        </g>
      </g>
    </svg>
  );
}

export default GallonIcon;
