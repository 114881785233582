import * as React from "react";

function SvgPadlock({ title, titleId, ...props }) {
  return (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="20" 
        height="20" 
        viewBox="0 0 20 20"
        {...props}
        >
        <g id="unlock">
          <rect id="Rectángulo_252" data-name="Rectángulo 252" width="20" height="20" fill="#fff" opacity="0"/>
          <path id="Trazado_410" data-name="Trazado 410" d="M14.553,6.871H8.871V5.247a1.624,1.624,0,1,1,3.247,0,.812.812,0,0,0,1.624,0,3.247,3.247,0,1,0-6.494,0V6.871H6.435A2.435,2.435,0,0,0,4,9.306V15.8a2.435,2.435,0,0,0,2.435,2.435h8.118A2.435,2.435,0,0,0,16.988,15.8V9.306A2.435,2.435,0,0,0,14.553,6.871Zm.812,8.929a.812.812,0,0,1-.812.812H6.435a.812.812,0,0,1-.812-.812V9.306a.812.812,0,0,1,.812-.812h8.118a.812.812,0,0,1,.812.812Z" transform="translate(-0.753 -0.376)" fill="#fff"/>
          <path id="Trazado_411" data-name="Trazado 411" d="M11.435,12a2.435,2.435,0,1,0,2.435,2.435A2.435,2.435,0,0,0,11.435,12Zm0,3.247a.812.812,0,1,1,.812-.812A.812.812,0,0,1,11.435,15.247Z" transform="translate(-1.694 -2.259)" fill="#fff"/>
        </g>
      </svg>
  );
}

export default SvgPadlock;
