export const STATUS = {
    'pending': {
        'label': 'Pending',
        'colors': {
            'font': '#7C858E',
            'background': 'rgba(237, 237, 237, 1)'
        }
    },
    'active': {
        'label': 'Active',
        'colors': {
            'font': '#25CA99',
            'background': 'rgba(238, 250, 247, 1)'
        }
    },
    'inactive': {
        'label': 'Inactive',
        'colors': {
            'font': '#FA8E25',
            'background': 'rgba(252, 223, 193, 1)'
        }
    },
    'expired': {
        'label': 'Expired',
        'colors': {
            'font': '#EA3D72',
            'background': '#FF4A564D'
        }
    },
}

export const ACTIONS = {
    'activate': {
        'label': 'Activate'
    },
    'inactivate': {
        'label': 'Inactivate'
    },
}