import * as React from "react";

function SvgDeleteIcon({ title, titleId, ...props }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="16" 
      height="16" 
      viewBox="-1 0 14.621 25"
      {...props}
    >
      <g id="Grupo_13135" data-name="Grupo 13135" transform="translate(-2.829 -1.798)">
        <line id="Línea_163" data-name="Línea 163" x2="13.121" transform="translate(3.579 6.066)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <line id="Línea_164" data-name="Línea 164" y2="4.771" transform="translate(8.35 9.646)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <line id="Línea_165" data-name="Línea 165" y2="4.771" transform="translate(11.928 9.646)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Trazado_11520" data-name="Trazado 11520" d="M5,7l.849,10.19a1.7,1.7,0,0,0,1.7,1.7h6.794a1.7,1.7,0,0,0,1.7-1.7L16.889,7" transform="translate(-0.754 -1.056)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Trazado_11521" data-name="Trazado 11521" d="M9,6.4V3.849A.849.849,0,0,1,9.849,3h3.4a.849.849,0,0,1,.849.849V6.4" transform="translate(-1.357 -0.452)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      </g>
    </svg>
  );
}

export default SvgDeleteIcon;
