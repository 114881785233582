import axios from 'axios';
import * as actions from '../api';
import { logout } from '../authentication';
import { showNotification } from '../../components/Custom/CustomNotification';
import store from '../configureStore';

const api = ({ dispatch }) => next => action => {

    let canDispatchActions = true;

    if (action.type !== actions.apiCallBegan.type) return next(action);

    const  reduxAuthData = store.getState().entities.authentication.authData

    const { url, method, data, onStart, onSuccess, onError, url_codes, params } = action.payload;
    const authData = JSON.parse(localStorage.getItem('auth_data'))
    const token = authData ? authData.access : '';
    const headers = authData ? {'Authorization': `Bearer ${token}`} : '';

    const localStorageUser = authData?.user;
    const reduxStateUser = reduxAuthData?.user;

    /** 
     * Checking if the auth data from the app state
     * is the same as the data from the local storage
    */

    if (reduxStateUser) {
        if (localStorageUser?.id !== reduxStateUser?.id) {
            canDispatchActions = false
            window.location = window.location.origin
        }
    }

    /** Checking if is valid to dispatch actions */

    if (canDispatchActions) {

        if (onStart) dispatch({ type: onStart })

        const { REACT_APP_API_URL, REACT_APP_API_CODES_URL } = process.env;

        next(action);
        let baseURL = REACT_APP_API_URL;

        if (url_codes) {
            baseURL = REACT_APP_API_CODES_URL;
        }

        axios.request({
            url,
            baseURL,
            method,
            data,
            headers
        }).then(response => {

            const status = response.status;
            const responseData = response.data;
            const isOkResponse = status === 200 && (method === 'post' || method === 'put' || method === 'patch');
            const isCreateResponse = status === 201;
            const isAcceptedResponse = status === 202;
            const isNoContentResponse = status === 204;
            if (isOkResponse || isCreateResponse || isAcceptedResponse || isNoContentResponse) {
                showNotification(
                    status,
                    method,
                    responseData.message !== undefined ? responseData.message : null
                );
            }

            if (status === 207 && method === 'post') {
                showNotification(
                    status,
                    method,
                    response.data
                );
            }

            dispatch(actions.apiCallSuccess(responseData));

            if (onSuccess) {
                dispatch({
                    type: onSuccess,
                    payload: {
                        data: response.data,
                        requestData: data,
                        params
                    }
                });
            }


        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { data } = error.response;
                if (data) {
                    const { code } = data;
                    if (code === 'token_not_valid') {
                        dispatch(logout());
                    } else {
                        showNotification(status, method, data);
                    }
                }

            } else {
                showNotification(status, method, error.response.data);
            }

            dispatch(actions.apiCallFailed(error.message));
            if (onError) dispatch({
                type: onError,
                payload: {
                    data: error.response.data,
                    requestData: data
                }
            });
        });
    }
}

export default api;