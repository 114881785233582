import { useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadProfiles } from '../../store/profiles';

const PermissionValidator = () => {

    const authenticatedUser = useSelector(state => state.entities.authentication.user);
    const profiles = useSelector(state => state.entities.profiles.list);
    const roles = useSelector(state => state.entities.roles.list);
    const profilePermissions = authenticatedUser.profile_permissions;
    const resourcePermissions = authenticatedUser.resource_permissions;
    const userResourcePermissions = authenticatedUser.user_resource_permissions;
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(loadProfiles());
    }, [dispatch]);

    const hasProfilePermission = (permissionCodename) => {
        let permission = profilePermissions.find(permission => permission.codename === permissionCodename);
        return typeof permission !== 'undefined';
    }

    const hasResourcePermission = (permissionCodename) => {
        let permission = resourcePermissions.find(
            permission => permission.codename === permissionCodename
        );
        return typeof permission !== 'undefined';
    }

    const roleHasResourcePermission = (roleId, resourcePermissionCodename) => {
        let profile = roles.find(role => role.id === roleId).profile
        return profileHasResourcePermission(profile.id, resourcePermissionCodename)
    }

    const profileHasResourcePermission = (profileId, resourcePermissionCodename) => {
        let profile = profiles.find(profile => profile.id === profileId);
        let permission = profile.resource_permissions.find(
            permission => permission.codename === resourcePermissionCodename
        );
        return typeof permission !== 'undefined';
    }

    const profileHasProfilePermission = (profileId, profilePermissionCodename) => {
        let profile = profiles.find(profile => profile.id === profileId);
        let permission = profile.profiles_permissions.find(
            permission => permission.codename === profilePermissionCodename
        );
        return typeof permission !== 'undefined';
    }

    const userHasResourcePermission = (permissionCodename) => {
        
        let permission = userResourcePermissions?.find(
            permission => permission.codename === permissionCodename
        );

        return typeof permission !== 'undefined';
    }

    return {
        hasProfilePermission,
        hasResourcePermission,
        roleHasResourcePermission,
        profileHasProfilePermission,
        profileHasResourcePermission,
        userHasResourcePermission
    }
}

export { PermissionValidator };