import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/cities/';
const slice = createSlice({
    name: 'cities',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        citiesRequested: (cities, action) => {
            cities.loading = true;
        },
        citiesRequestFailed: (cities, action) => {
            cities.loading = false;
        },
        citiesReceived: (cities, action) => {
            cities.list = action.payload.data;
            cities.loading = false;
        }
    }
});

export default slice.reducer;
export const loadCities = () => apiCallBegan({
    url,
    onStart: slice.actions.citiesRequested.type,
    onSuccess: slice.actions.citiesReceived.type,
    onError: slice.actions.citiesRequestFailed.type
});