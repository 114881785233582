import styled from 'styled-components';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const StyledTag = styled(Tag)`
    border-radius: 20px !important;
    color: ${props => props.colors.font };
    background-color: ${props => props.colors.background };
    padding: 3px 20px;
    border: none;
    font-weight: bold;
`;

const CustomTag = (props) => {
    const { t } = useTranslation('tags');
    const { children } = props;
    const customProps = {...props, children: t(children && children.toLowerCase())};

    return (<StyledTag {...customProps}/>)
};

export default CustomTag;
