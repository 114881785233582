import styled from 'styled-components';
import { Collapse } from "antd";

const CustomCollapsePanel = styled(Collapse.Panel)`

    .ant-collapse-header {
        transition: all 0.2s ease-in-out !important;
        height: 0px;
        padding: 0px !important;
        svg {
            height: 0px;
        }
    }

    .ant-collapse-content-box {
        padding-left: 0px;
        padding-top: 0px !important;
    }

`;

export default CustomCollapsePanel;