import styled from 'styled-components';
import { Menu} from 'antd';

const CustomMenu = styled(Menu)`
    height: 64px;

    .ant-menu-submenu-title {
        :hover {
            color: black !important; 
        }
        :active {
            background-color: white !important;
        }
        :focus-visible {
            border: none !important;
        }
        border: none !important;
    }

    .ant-menu-submenu-active {
        color: black !important; 
        border: none !important;
    }

    .ant-menu-submenu-open {
        border: none !important;
        color: black !important; 
    }

    .ant-menu-submenu-active::after {
        border: none !important;
    }

    .ant-menu-submenu-open::after {
        border: none !important;
    }

    .ant-menu-submenu::after {
        border: none !important;
        transition: none !important;
    }

    .ant-menu-item {
        background-color: red !important;
        :hover {
            background-color: red;
        }   
    } 

    .ant-menu-item-only-child {
        background-color: red;
    }

    .ant-menu-item-active {
        color: black !important; 
        :hover {
            color: black !important; 
        }
    }

    .ant-menu-light {
        color: black !important; 
    }

    .ant-menu-item:hover {
        color: black !important; 
    }
    
    .ant-menu-light .ant-menu-item:hover {
        color: black !important; 
    }


`;

export default CustomMenu;