import * as React from "react";

function AmountIcon({ title, titleId, ...props }) {
  return (
    <svg id="Grupo_15235" data-name="Grupo 15235" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <rect id="Rectángulo_17953" data-name="Rectángulo 17953" width="50" height="50" rx="3" fill="#27c695" opacity="0.101"/>
      <g id="vuesax_bulk_money-send" data-name="vuesax/bulk/money-send" transform="translate(5.977 6.508)">
        <g id="money-send">
          <path id="Vector" d="M0,0H38.046V36.984H0Z" fill="none" opacity="0"/>
          <g id="Group" transform="translate(2.656 2.656)">
            <path id="Vector-2" data-name="Vector" d="M24.55,9.106a1.235,1.235,0,0,1-.867-.348,1.167,1.167,0,0,1,0-1.679l3.355-3.246A16.724,16.724,0,0,0,16.367,0,16.114,16.114,0,0,0,0,15.836,16.114,16.114,0,0,0,16.367,31.672,16.114,16.114,0,0,0,32.733,15.836,15.568,15.568,0,0,0,28.772,5.511L25.417,8.757A1.235,1.235,0,0,1,24.55,9.106Z" fill="#27c695" opacity="0.325"/>
          </g>
          <path id="Vector-3" data-name="Vector" d="M6.641,6.734,5.313,6.269V3.32h.106A1.279,1.279,0,0,1,6.641,4.649a1,1,0,0,0,1.992,0,3.266,3.266,0,0,0-3.214-3.32H5.313V1A1,1,0,0,0,3.32,1v.332h-.4A2.98,2.98,0,0,0,0,4.356,2.722,2.722,0,0,0,1.992,7.225L3.32,7.69v2.949H3.214A1.279,1.279,0,0,1,1.992,9.311a1,1,0,0,0-1-1,1,1,0,0,0-1,1,3.266,3.266,0,0,0,3.214,3.32H3.32v.332a1,1,0,0,0,1.992,0v-.332h.4A2.98,2.98,0,0,0,8.633,9.6,2.7,2.7,0,0,0,6.641,6.734Zm-4-1.408c-.452-.159-.651-.252-.651-.983a.994.994,0,0,1,.93-1.036h.4V5.552Zm3.068,5.3h-.4V8.381l.677.239c.452.159.651.252.651.983A.99.99,0,0,1,5.711,10.625Z" transform="translate(14.706 11.521)" fill="#27c695"/>
          <path id="Vector-4" data-name="Vector" d="M7.239.624A1.039,1.039,0,0,0,6.694.08.953.953,0,0,0,6.309,0H1A1,1,0,0,0,0,1a1,1,0,0,0,1,1H3.9L1.687,4.21A14.087,14.087,0,0,1,3.095,5.618L5.313,3.4V6.322a1,1,0,0,0,1.992,0V1.009A.79.79,0,0,0,7.239.624Z" transform="translate(29.067 1.647)" fill="#27c695"/>
        </g>
      </g>
    </svg>
  );
}

export default AmountIcon;
