import * as React from "react";

function SvgUserPlus({ title, titleId, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.475" height="12.582" viewBox="0 0 17.475 12.582">
      <g id="user-plus" transform="translate(0.75 0.75)">
        <path id="Trazado_11504" data-name="Trazado 11504" d="M11.586,19.235V17.823A2.823,2.823,0,0,0,8.763,15H3.823A2.823,2.823,0,0,0,1,17.823v1.412" transform="translate(-1 -8.153)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <circle id="Elipse_145" data-name="Elipse 145" cx="2.823" cy="2.823" r="2.823" transform="translate(2.47)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <line id="Línea_155" data-name="Línea 155" y2="4.388" transform="translate(13.781 1.258)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <line id="Línea_156" data-name="Línea 156" x1="4.388" transform="translate(11.587 3.452)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      </g>
    </svg>
  );
}

export default SvgUserPlus;
