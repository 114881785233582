import * as React from "react";

function SvgActionIcon({ title, titleId, ...props }) {
  return (
      
    <svg xmlns="http://www.w3.org/2000/svg"
      width="16" 
      height="16" 
      viewBox="-6 0 20.634 20"
      {...props}
     >
        <path id="Trazado_11536" data-name="Trazado 11536" d="M491.556,54.111A1.556,1.556,0,1,0,490,52.556,1.556,1.556,0,0,0,491.556,54.111Zm0,2.333A1.556,1.556,0,1,0,493.111,58,1.556,1.556,0,0,0,491.556,56.444Zm-1.556,7A1.556,1.556,0,1,1,491.556,65,1.556,1.556,0,0,1,490,63.444Z" transform="translate(-490 -51)" fill="#9eaab5"/>
    </svg>


  );
}

export default SvgActionIcon;
