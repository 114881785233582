import { Layout } from 'antd';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/Login/Login'
import EmailVerification from '../components/Authentication/EmailVerification';
import PasswordReset from '../components/Login/PasswordReset';
import PasswordResetForm from '../components/Login/PasswordResetForm';
import GSTrackerForm from '../pages/GSTrackerForm';
import QrTagAdmin from '../components/Login/QrTagAdmin';

const LoginPage = () => {
    return (
        <Layout className="mt-5">
            <Routes>
                <Route
                    path="/"
                    element={<Login />}
                />
                <Route
                    path="/fleet"
                    element={<Login />}
                />
                <Route
                    path="/email_verification/:token"
                    element={<EmailVerification />}
                />
                <Route
                    path="/password_reset"
                    element={<PasswordReset />}
                />
                <Route
                    path="/password_reset/:token"
                    element={<PasswordResetForm />}
                />
                <Route
                    path="/qr_admin"
                    element={<QrTagAdmin />}
                />
                <Route
                    path="/gstracker"
                    element={<GSTrackerForm />}
                />
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        </Layout>
    );
};

export default LoginPage;
