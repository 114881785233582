import React, { useState, useEffect, Fragment } from 'react'
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { loadRoles } from '../../store/roles';
import { loadStations } from '../../store/stations';
import { loadDispensers } from '../../store/dispensers';
import { Radio, Row, Col, Space, Select, Form, Typography, Empty } from 'antd';
import { loadUsers, addUser, /*deleteUser,*/ changeStatus, updateUser, loadManagers } from '../../store/users';
import CustomSearchInput from '../Custom/CustomSearchInput';
import CreateModal from '../Custom/CreateModal';
import CreateUserForm from '../Users/CreateUserForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import ModuleHeader from '../Custom/ModuleHeader'
import IconButton from '../Custom/IconButton';
import SvgEditIcon from "../icons/EditIcon"
import SvgUser from '../icons/User'
import SvgUserPlus from '../icons/UserPlus'
// import SvgDeleteIcon from '../icons/DeleteIcon';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomTag from '../Custom/CustomTag';
// import ConfirmModal from '../Custom/ConfirmationModal';
import ActionsUsers from './ActionsUsers';
import { STATUS, ACTIONS } from '../constants';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const filterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Pending', value: 'pending' },
    { label: 'Inactive', value: 'inactive' },
];

const onChangeFilter = e => {
};

const onChangeRoleFilter = value => {
}

const Users = () => {
    const MODULE_NAME = 'user';
    const { t } = useTranslation([MODULE_NAME, 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [allowedRoles, setAllowedRoles] = useState([]);
    const [modalTitle, setModalTitle] = useState("Create User");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { hasProfilePermission, hasResourcePermission } = PermissionValidator();
    const { list, loading, loadingAction } = useSelector(state => state.entities.users);
    let usersLength = list.length;
	let sortedList = [...list]
	sortedList = sortedList?.sort((a, b) => {
		return new Date(b.created_at) - new Date(a.created_at)
	})
    const indexedList = sortedList.map(e => ({...e, 'column_index': usersLength--}))
    const userErrors = useSelector(state => state.entities.users.errors);
    let roles = useSelector(state => state.entities.roles.list);
    roles = roles.map(role => ({...role, name: t(role?.name, { ns: 'common_labels' }) }));
    const rolesLoading = useSelector(state => state.entities.roles.loading);
    const stations = useSelector(state => state.entities.stations.list);
    const dispensers = useSelector(state => state.entities.dispensers.list);
    const { config } = useSelector(state => state.entities.authentication);
    const permittedRoles = {
        create: roles.filter(role => hasProfilePermission(`create_${role?.profile.value}`)),
        update: roles.filter(role => hasProfilePermission(`update_${role?.profile.value}`)),
    };

    const hasAdminStation = hasResourcePermission("admin_station");

    let stationsList = stations.map(station => (
        {
            label: station?.name,
            value: station.id,
            disabled: !station.is_active
        }
    ));

    let dispensersList = dispensers.map(dispenser => (
        {
            label: dispenser?.name,
            value: dispenser.id,
            disabled: !dispenser.is_active
        }
    ));

    useEffect(() => {
        dispatch(loadRoles());
        dispatch(loadUsers());
        dispatch(loadStations());
        dispatch(loadDispensers());
        dispatch(loadManagers());
    }, [dispatch]);

    useEffect(() => {
        form.setFields(userErrors);
        if (userErrors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, userErrors]);

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);

    const handleSearch = (e) => {
        let value = (e.target.value).trim().toLowerCase();

        let filtered = indexedList.filter(element => {
            const { first_name, last_name, email, role_name } = element;

            let name = first_name + ' ' + last_name;
            let role = t(role_name, {ns: 'common_labels'});

            return (name.toLowerCase().includes(value) ||
                email.toLowerCase().includes(value) ||
                role.toLowerCase().includes(value)
            );
        });


        setSearch(value);
        setUserSerch(filtered);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'column_index',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}>{ record.column_index } </Text>
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (roleId) => {
                if (roles.length > 0) {
                    let role = roles.find(role => role.id === roleId);
                    return <Text> {role?.name} </Text>
                } else {
                    return <Text> </Text>
                }

            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            key: 'first_name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record) => {
                // console.log('fffff')
                // console.log(record);

                return <Text> {`${record.first_name} ${record.last_name}`} </Text>
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => {
                let status = (record.is_verified) ? record.is_active ? STATUS.active : STATUS.inactive : STATUS.pending;
                return (
                    <CustomTag colors={status.colors}>
                        {status.label}
                    </CustomTag>
                )
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasViewPermissions = false;
                let hasUpdatePermissions = false;
                // let hasDeletePermissions = false;
                if (roles.length > 0) {
                    let role = roles.find(role => role.id === record.role);
                    hasViewPermissions = hasProfilePermission(`read_${role?.profile.value}`);
                    hasUpdatePermissions = hasProfilePermission(`update_${role?.profile.value}`);
                    // hasDeletePermissions = hasProfilePermission(`delete_${role.profile.value}`);
                }
                return (
                    <Fragment>
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasUpdatePermissions}
                                hover_color='mdc-icon-button__edit'
                                onClick={() => showModal(record)}
                                icon={<SvgEditIcon />}
                            />
                        </Space> */}
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasDeletePermissions}
                                hover_color='mdc-icon-button__delete'
                                onClick={() => ConfirmModal({
                                    "onConfirmedDeletion": onConfirmDeletion,
                                    "moduleName": MODULE_NAME,
                                    "recordId": record.id,
                                    "name": record.first_name
                                })}
                                icon={<SvgDeleteIcon />}
                            />
                        </Space> */}
                        <Space
                            size="middle" >
                            <ActionsUsers
                                hasEditPermissions={hasUpdatePermissions}
                                hasViewPermissions={hasViewPermissions}
                                clickChangeStatus={clickChangeStatus}
                                user={record}
                                actions={ACTIONS}
                                openModal={showModal}
                            />
                        </Space>
                    </Fragment>
                );
            }
        }
    ];

    const showModal = (user = [], detailsOnly = false) => {
        
        const userType = hasResourcePermission('access_vehicle') 
            ? "Driver"
            : "user"

        let newModalTitle = `Create ${userType}`;
        let newAllowedRoles = permittedRoles.create;

        if (user.id) {
            let assignedStations = [];
            let permittedStations = [];
            let userData = { ...user };

            if (!hasResourcePermission('access_station')) {                
                user.permitted_stations.forEach(stationId => {
                    let permittedStation = stations.find(station => station.id === stationId);
                    if (permittedStation) {
                        permittedStations.push(permittedStation.id);
                    }
                });

                userData.permitted_stations = permittedStations;
                form.setFieldsValue(user);
            } else {
                user.assigned_stations.forEach(stationId => {
                    let assignedStation = stations.find(station => station.id === stationId);
                    if (assignedStation) {
                        assignedStations.push(assignedStation.id);
                    }
                });

                userData.assigned_stations = assignedStations;
                form.setFieldsValue(user);
            }

            newAllowedRoles = permittedRoles.update;
            newModalTitle = detailsOnly ? `${userType} Detail` : `Edit ${userType}`;
        }

        setIsDetailsOnly(detailsOnly);
        setAllowedRoles(newAllowedRoles);
        setModalTitle(newModalTitle);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleSave = (values) => {

        values['assigned_dispensers'] = values['tag_required'] ? values['assigned_dispensers'] : [];

        if (!isDetailsOnly) {
            if(!values['permitted_stations']){
                values['permitted_stations']  = []
            }

            values['extra_fields'] = {}
            if (values['customer_name'] && values['customer_stations']){
                values.extra_fields = {
                    customer_name: values.customer_name,
                    customer_stations: values.customer_stations
                }
                delete values.customer_name;
                delete values.customer_stations;
            }

            if (values.id) {
                dispatch(updateUser(values));
            } else {
                dispatch(addUser(values));
            }

        } else {
            setIsDetailsOnly(false);
            setModalTitle("Edit User");
        }
    }

    // const onConfirmDeletion = (recordId) => {
    //     dispatch(deleteUser(recordId))
    // }

    const clickChangeStatus = (recordId, is_active) => {
        dispatch(changeStatus(recordId, is_active))
    }

    return (
        <Fragment>
            <CreateModal
                getContainer={false}
                title={modalTitle}
                visible={isModalVisible}
                onCancel={handleCancel}
                width={800} okText={isDetailsOnly ? 'Edit' : 'Save'}
                okButtonProps={{ style: { display: isDetailsOnly ? 'none' : '' }, onClick: form.submit, loading:loadingAction }}
            >
                <CreateUserForm
                    onFinish={handleSave}
                    form={form}
                    roles={allowedRoles}
                    dispensers={dispensersList}
                    stations={stationsList}
                    isDetailsOnly={isDetailsOnly}
                />
            </CreateModal>
            <Row align="middle">
                <Col xs={12}>
                    <ModuleHeader>
                        {
                            hasResourcePermission('access_vehicle') 
                                ? t('header2') 
                                : t('header1') 
                        }
                    </ModuleHeader>
                </Col>
                <Col xs={12}>
                    <CustomButton
                        className="create-button"
                        // icon={<SvgUserPlus />}
                        disabled={hasAdminStation && stationsList.length === 0}
                        style={{
                            float: 'right',
                            display: hasResourcePermission(`create_${MODULE_NAME}`) ? '' : 'none'
                        }}
                        onClick={showModal}
                    >
                        {
                            hasResourcePermission('access_vehicle') 
                                ? t('button2') 
                                : t('button1') 
                        }
                    </CustomButton>
                </Col>
                <Col xs={0}>
                    <BreadcrumbCustom className='d-none' items={["Home", "User List"]} />
                </Col>
            </Row>
            <Row style={{ marginBottom: '10px', marginTop: '10px' }} >
                <Col span={0}>
                    <Radio.Group
                        options={filterOptions}
                        onChange={onChangeFilter}
                        value='all'
                        optionType="button"
                    />
                </Col>
                <Col xs={8}>
                    {hasAdminStation && stationsList.length === 0 ?
                        <></>
                    :
                        <CustomSearchInput 
                            onChange={handleSearch} 
                        />
                    }
                </Col>
                <Col span={0}>
                    <Space style={{ float: 'right' }}>
                        Sort by
                        <Select
                            placeholder="Select an option"
                            style={{ width: 180 }}
                            onChange={onChangeRoleFilter}
                        >
                            {/* {roles.map((role) => (
                                <Select.Option
                                    key={role.id}
                                    value={role.id}
                                >
                                    {role.name}
                                </Select.Option>
                            ))} */}
                        </Select>
                    </Space>
                </Col>
            </Row>
            {hasAdminStation && stationsList.length === 0 && !loading ? 
                <Empty 
                    className='pt-4 mt-4 mb-0'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                    <span className='text-center'>
                        There's no station assigned to you, 
                        ask your administrator to assign you one
                    </span> 
                    }
                />
            :
                <CustomTable
                    locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                    dataSource={searchValue ? usersSerch : indexedList}
                    columns={columns}
                    rowKey='index'
                    loading={loading || rolesLoading}
                />
            }
        </Fragment>
    );
}

export default Users;
