import styled from 'styled-components';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const StyledModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 14px !important;
        border-top-right-radius: 14px !important;
    } 
    .ant-modal-footer {
        background-color: #F8FAFC !important;
        border-bottom-left-radius: 14px !important;
        border-bottom-right-radius: 14px !important;
    }
    .ant-modal-content {
        border-radius: 14px !important;
    }
    .ant-modal-close-x svg {
        fill: #9EAAB5 !important;
    }
    
    .ant-modal-footer .ant-btn {
        border-radius: 8px !important;
        border-color: ${props => props.theme.primaryColor} !important;
        background-color: #FFFFFF;
        color: #575F72;
    }
    
    .ant-btn-primary {
        background-color: ${props => props.theme.primaryColor} !important;
        color: #FFFFFF !important;
    }
`;

const CreateModal = (props) => {

    const { t } = useTranslation('common_labels');
    const { title, okText } = props;
    const cancelText = "cancel";

    const customProps = {
        ...props, 
        title: t('modal_title', {title: title}), 
        okText: okText && t(okText.toLowerCase()),
        cancelText: t(cancelText)
    };
    return (<StyledModal {...customProps} />)
}

export default CreateModal;
