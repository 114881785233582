import * as React from "react";

function SvgServices({ title, titleId, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14.404 14.405"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        data-name="Trazado 11486"
        d="M11.812 10.035l-4.61 2.646-4.61-2.646 1.356-.577L6.8 11.1a.8.8 0 00.8 0l2.855-1.64zM6.8 7.9a.8.8 0 00.8 0l2.855-1.64 1.356.578-4.61 2.646-4.61-2.646 1.356-.578zm.4-6.226l4.61 1.963L7.2 6.283 2.59 3.637zm7.2 5.01a.8.8 0 00-.486-.689l-1.722-.733L14 4.221a.8.8 0 00-.086-1.43L7.514.064a.8.8 0 00-.627 0l-6.4 2.727a.8.8 0 00-.085 1.43l1.807 1.038-1.722.733a.8.8 0 00-.085 1.43L2.209 8.46l-1.722.733a.8.8 0 00-.085 1.43l6.4 3.676a.8.8 0 00.8 0l6.4-3.676a.8.8 0 00-.085-1.43l-1.722-.734L14 7.422a.8.8 0 00.4-.741z"
        fill="#c2cfe0"
      />
    </svg>
  );
}

export default SvgServices;
