import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/products/';
const slice = createSlice({
    name: 'products',
    initialState: {
        list: [],
        byStation: [],
        stationList: [],
        loading: false,
        loadingAction: false,
        lastFetch: null,
        errors: []
    },
    reducers: {
        productsRequested: (products, action) => {
            products.loading = true;
        },
        productsRequestedAction: (products, action) => {
            products.loadingAction = true;
        },
        productsRequestFailed: (products, action) => {
            products.loading = false;
            let responseData = action.payload.data;
            products.errors = [];
            for (const field in responseData) {
                products.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            products.loadingAction = false;
        },
        productsReceived: (products, action) => {
            products.list = action.payload.data;
            products.loading = false;
        },
        productsByStationReceived: (products, action) => {
            products.byStation = action.payload.data;
            products.loading = false;
        },
        productAdded: (products, action) => {
            products.list.unshift(action.payload.data);
            products.loading = false;
            products.loadingAction = false;
            products.errors = []
        },
        productDeleted: (products, action) => {
            products.list = products.list.filter(product => product.id !== action.payload.requestData);
            products.loading = false;
        },
        productUpdated: (products, action) => {
            const index = products.list.findIndex(product => product.id === action.payload.data.id);
            products.list[index] = action.payload.data;
            products.loading = false;
            products.loadingAction = false;
            products.errors = []
        }
    }
});

export default slice.reducer;
export const loadProducts = () => apiCallBegan({
    url,
    onStart: slice.actions.productsRequested.type,
    onSuccess: slice.actions.productsReceived.type,
    onError: slice.actions.productsRequestFailed.type
});

export const addProduct = product => apiCallBegan({
    url,
    method: 'post',
    data: product,
    onStart: slice.actions.productsRequestedAction.type,
    onSuccess: slice.actions.productAdded.type,
    onError: slice.actions.productsRequestFailed.type
});

export const deleteProduct = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onStart: slice.actions.productsRequestedAction.type,
    onSuccess: slice.actions.productDeleted.type,
    onError: slice.actions.productsRequestFailed.type
});

export const updateProduct = (product) => apiCallBegan({
    url: `${url}${product.id}/`,
    method: 'put',
    data: product,
    onStart: slice.actions.productsRequestedAction.type,
    onSuccess: slice.actions.productUpdated.type,
    onError: slice.actions.productsRequestFailed.type
});

export const loadProductsByStation = id => apiCallBegan({
    url: `stations/${id}/products/`,
    onStart: slice.actions.productsRequested.type,
    onSuccess: slice.actions.productsByStationReceived.type,
    onError: slice.actions.productsRequestFailed.type
});