import { Row, Col } from 'antd';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const CreateProductForm = (props) => {

    const { t } = useTranslation('tags');

    const statusOptions = [
        {
            name: 'Active',
            value: true
        },
        {
            name: 'Inactive',
            value: false
        }
    ];

    return (
        <CustomForm
            form={props.form}
            onFinish={props.onFinish}
            initialValues={{ is_active: true, station: '' }}
        >
            <CustomFormItem
                label="id"
                name="id"
                hidden={true}
            >
                <CustomTextInput />
            </CustomFormItem>
            <CustomFormItem
                name="name"
                rules={[{
                    required: true,
                    message: 'Please input the name'
                }]}
            >
                <CustomTextInput
                    id="name"
                    label={t('products.modal.name')}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    maxLength={140}
                    disabled={props.isDetailsOnly}
                />
            </CustomFormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomFormItem
                        name="price"
                        rules={[{
                            required: true,
                            message: 'Please input the price'
                        }]}
                    >
                        <CustomTextInput
                            id="price"
                            label={t('products.modal.price')}
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            min={0}
                            maxLength={7}
                            disabled={props.isDetailsOnly}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </CustomFormItem>
                </Col>
                <Col span={12}>
                    <CustomFormItem
                        name="is_active"
                    >
                        <CustomTextInput
                            label={t('products.modal.status')}
                            size="small"
                            select
                            fullWidth
                            disabled={props.isDetailsOnly}
                            placeholder="Please select a status"
                        >
                            {statusOptions.map(option =>
                                <MenuItem
                                    key={option.name}
                                    value={option.value}
                                >
                                    {t(option.name.toLowerCase())}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
            </Row>
            <CustomFormItem
                name="station"
                rules={[{
                    required: true,
                    message: 'Please select a station'
                }]}
            >
                <CustomTextInput
                    id="station"
                    label={t('products.modal.station')}
                    size="small"
                    select
                    fullWidth
                    required
                    disabled={props.isDetailsOnly}
                    placeholder="Please select an station"
                >
                    {props.stations.map(station =>
                        <MenuItem
                            disabled={!station.is_active}
                            key={station.id}
                            value={station.id}>
                            {station.name}
                        </MenuItem>
                    )}
                </CustomTextInput>
            </CustomFormItem>
        </CustomForm>
    );
}

export default CreateProductForm;
