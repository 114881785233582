
import { Route, Routes } from 'react-router-dom';
import GSTrackerForm from '../pages/GSTrackerForm';

const OutsidePage = () => {
    return (
        <Routes>
            <Route
                path="/gstracker"
                element={<GSTrackerForm />}
            />
        </Routes>
    );
};

export default OutsidePage;
