import * as React from "react";

function SvgDetailIcon({ title, titleId, ...props }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="16" 
      height="16" 
      viewBox="-3 3 21.278 22"
      {...props}
    >
      <path id="Trazado_11615" data-name="Trazado 11615" d="M0,0H21.278V21.278H0Z" fill="none"/>
      <path id="Trazado_11616" data-name="Trazado 11616" d="M9.649,7H5.614A1.614,1.614,0,0,0,4,8.614v7.263a1.614,1.614,0,0,0,1.614,1.614h7.263a1.614,1.614,0,0,0,1.614-1.614V11.842" transform="translate(-0.454 0.241)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <line id="Línea_214" data-name="Línea 214" y1="5.594" x2="6.559" transform="translate(9.042 6.643)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      <path id="Trazado_11617" data-name="Trazado 11617" d="M15,4h4.624V8.074" transform="translate(-3.275 1.479)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>

  );
}

export default SvgDetailIcon;
