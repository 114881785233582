import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/stations/';
const slice = createSlice({
    name: 'stations',
    initialState: {
        list: [],
        managersList: [],
        dispatchersList: [],
        loading: false,
        loadingAction: false,
        lastFetch: null,
        errors: []
    },
    reducers: {
        stationsRequested: (stations, action) => {
            stations.loading = true;
        },
        stationsRequestedAction: (stations, action) => {
            stations.loadingAction = true;
        },
        stationsRequestFailed: (stations, action) => {
            stations.loading = false;
            stations.loadingAction = false;
        },
        stationsReceived: (stations, action) => {
            stations.list = action.payload.data;
            stations.loading = false;
        },
        stationAdded: (stations, action) => {
            stations.list.unshift(action.payload.data);
            stations.loadingAction = false;
            stations.loading = false;
            stations.errors = [];
        },
        statioAddRequestFailed: (stations, action) => {
            let responseData = action.payload.data;
            stations.errors = [];
            for (const field in responseData) {
                stations.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            stations.loading = false;
            stations.loadingAction = false;
        },
        stationDeleted: (stations, action) => {
            stations.list = stations.list.filter(station => station.id !== action.payload.requestData);
            stations.loading = false;
        },
        stationUpdated: (stations, action) => {
            const index = stations.list.findIndex(station => station.id === action.payload.data.id);
            stations.list[index] = action.payload.data;
            stations.loading = false;
            stations.loadingAction = false;
            stations.errors = [];
        },
        stationManagersReceived: (stations, action) => {
            stations.managersList = action.payload.data;
            stations.loading = false;
        },
        stationDispatchersReceived: (stations, action) => {
            stations.dispatchersList = action.payload.data;
            stations.loading = false;
        },
    }
});

export default slice.reducer;
export const loadStations = () => apiCallBegan({
    url,
    onStart: slice.actions.stationsRequested.type,
    onSuccess: slice.actions.stationsReceived.type,
    onError: slice.actions.stationsRequestFailed.type
});

export const addStation = station => apiCallBegan({
    url,
    method: 'post',
    data: station,  
    onStart: slice.actions.stationsRequestedAction.type,
    onSuccess: slice.actions.stationAdded.type,
    onError: slice.actions.statioAddRequestFailed.type,
});

export const deleteStation = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onSuccess: slice.actions.stationDeleted.type
});

export const changeStatus = (id, isActive) => apiCallBegan({
    url: `${url}${id}/`,
    method: 'patch',
    data: { is_active: isActive },
    onSuccess: slice.actions.stationUpdated.type
});

export const updateStation = (station) => apiCallBegan({
    url: `${url}${station.id}/`,
    method: 'put',
    data: station,
    onStart: slice.actions.stationsRequestedAction.type,
    onSuccess: slice.actions.stationUpdated.type,
    onError: slice.actions.statioAddRequestFailed.type
});

export const loadStationManagers = () => apiCallBegan({
    url: `${url}station_managers/`,
    onStart: slice.actions.stationsRequested.type,
    onSuccess: slice.actions.stationManagersReceived.type,
    onError: slice.actions.stationsRequestFailed.type
});

export const loadStationWorkers = () => apiCallBegan({
    url: `${url}station_workers/`,
    onStart: slice.actions.stationsRequested.type,
    onSuccess: slice.actions.stationDispatchersReceived.type,
    onError: slice.actions.stationsRequestFailed.type
});