import * as React from "react";

function SvgSentEmail({ title, titleId, ...props }) {
    return (
        <svg id="Grupo_13273" 
            data-name="Grupo 13273" 
            xmlns="http://www.w3.org/2000/svg" 
            width="24.446" 
            height="23.84" 
            viewBox="0 0 24.446 23.84"
        >
            <path id="Trazado_11597" data-name="Trazado 11597" d="M0,0H24.446V23.84H0Z" fill="none"/>
            <path id="Trazado_11598" data-name="Trazado 11598" d="M12.223,18H5.05A2.025,2.025,0,0,1,3,16V6A2.025,2.025,0,0,1,5.05,4H19.4a2.025,2.025,0,0,1,2.05,2v7.5" transform="translate(0 -0.064)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Trazado_11599" data-name="Trazado 11599" d="M3,6l9.223,6,9.223-6" transform="translate(0 -0.053)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Trazado_11600" data-name="Trazado 11600" d="M15,18h6" transform="translate(0.446 -0.12)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Trazado_11601" data-name="Trazado 11601" d="M18,15l3,3-3,3" transform="translate(0.446 -0.16)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </svg>
    );
}

export default SvgSentEmail;
