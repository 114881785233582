import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import ReactGA from "react-ga4";

// import i18n (needs to be bundled ;))
import i18next from './i18nextGasStation';

const { REACT_APP_API_GA_MEASUREMENT_ID } = process.env;
ReactGA.initialize(REACT_APP_API_GA_MEASUREMENT_ID)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)
