import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const { REACT_APP_API_TRANSLATION_FILES } = process.env;
const selectedLanguage = window.localStorage.getItem('language');
// Revisar porque guardar la instancia como varibale y luego pasarla al componente no funciona
//const myi18n = i18n
i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        ns: ['system_notification_messages'],
        lng: selectedLanguage,
        fallbackLng: 'en',
        backend: {
            loadPath: function (lng) {
                return `${window.location.origin}${REACT_APP_API_TRANSLATION_FILES}{{lng}}/{{ns}}.json`
            },
        }
    })

i18next.services.formatter.add('modalTitleFormat', (value, lng, options) => {
    const ns = { ns: options.ns };
    const words = value.split(' ');
    return (
    words
        .map(word => i18next.t(word.toLowerCase(), ns))
        .join(' ')
    )
});

export default i18next
