import React, { useState, useEffect, Fragment } from 'react';
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { 
    loadVehicles, 
    addVehicle, 
    updateVehicle, 
    loadManagers, 
    loadDriversByManager, 
    loadDrivers,
    loadGsDevices
} from '../../store/vehicles';
import { loadVehicleBrands } from '../../store/vehicleBrands';
import { loadVehicleModelsByBrand } from '../../store/vehicleModels';
import { Row, Col, Space, Form, Typography } from 'antd';
import CreateModal from '../Custom/CreateModal';
import CreateVehicleForm from './CreateVehicleForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import IconButton from '../Custom/IconButton';
import SvgEditIcon from "../icons/EditIcon"
import SvgDetailIcon from '../icons/DetailIcon';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import Labels from '../Custom/VolumeUnitLabel';
import CustomSearhInput from '../Custom/CustomSearchInput';
import ListGroups from './ListGroups';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Vehicles = () => {
    // const DELETE_PERMISSION_CODENAME = `delete_${MODULE_NAME}`;
    const MODULE_NAME = 'vehicle';
    const VIEW_PERMISSION_CODENAME = `read_${MODULE_NAME}`;
    const CHANGE_PERMISSION_CODENAME = `update_${MODULE_NAME}`;
    const ADD_PERMISSION_CODENAME = `create_${MODULE_NAME}`;

    const { getVolumeLabel } = Labels();
    const { t } = useTranslation(MODULE_NAME);

    const volumeLabel = getVolumeLabel();

    const vehicles_colums = () => {

		let sortedList = [...list];
		
		sortedList?.sort((a, b) => b.created_at.localeCompare(a.created_at));

        let vehicles_ = sortedList.map(vehicle => ({
            ...vehicle,
            'index': vehiclessLength--
        }));

        return vehicles_;
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [titleModal, setTitleModal] = useState("Create Vehicle");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const managers = useSelector(state => state.entities.vehicles.managersList);
    const drivers = useSelector(state => state.entities.vehicles.drivers);
    const { 
        loading, 
        list, 
        errors, 
        loadingAction, 
        gsTrackingDevices 
    } = useSelector(state => state.entities.vehicles);
    const { config } = useSelector(state => state.entities.authentication);
    const { hasResourcePermission } = PermissionValidator();
    let vehiclessLength = list.length;

    const vehicles_ = vehicles_colums();

    const canUserAdminCustomer = hasResourcePermission('admin_customer');

    useEffect(() => {
        dispatch(loadVehicles());
        dispatch(loadManagers());
        dispatch(loadDrivers());
        dispatch(loadVehicleBrands());
        dispatch(loadGsDevices());
    }, [dispatch]);

    useEffect(() => {
        form.setFields(errors);
        if (errors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, errors]);

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}> {record.index} </Text>
        },
        {
            title: 'Truck number',
            dataIndex: 'unit_code',
            id: 'unit_code',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (unit_code) => <Text> #{unit_code} </Text>
        },
        {
            title: 'Model',
            dataIndex: 'vehicle_model_name',
            id: 'vehicle_model_name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (vehicle_model_name, record) => <Text strong style={{ color: config.primaryColor }}>
                {vehicle_model_name} {record.year}
            </Text>
        },
        {
            title: 'Brand',
            dataIndex: 'vehicle_brand_name',
            id: 'vehicle_brand_name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (vehicle_brand_name) => <Text> {vehicle_brand_name} </Text>
        },
        {
            title: 'Plates',
            dataIndex: 'plate_number',
            id: 'plate_number',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (plate_number) => <Text> {plate_number} </Text>
        },
        {
            title: 'Tank capacity (Gallons)',
            dataIndex: 'tank_capacity',
            id: 'tank_capacity',
            suffix: `(${volumeLabel.plural})`,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (tank_capacity) => <Text> {tank_capacity}</Text>
        },
        {
            title: 'Group',
            dataIndex: 'fleets',
            id: 'fleets',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
            render: (fleets) => <Text> {fleets.map(fleet => <li>{fleet}</li>)} </Text>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasViewPermissions = hasResourcePermission(VIEW_PERMISSION_CODENAME);
                let hasEditPermissions = hasResourcePermission(CHANGE_PERMISSION_CODENAME);
                // let hasDeletePermissions = hasResourcePermission(DELETE_PERMISSION_CODENAME);
                return (
                    <Fragment>
                        <Space size="middle">
                            <IconButton
                                isVisible={hasEditPermissions}
                                hover_color='mdc-icon-button__edit'
                                onClick={() => showModal(record)}
                                icon={<SvgEditIcon />}
                            />
                        </Space>
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasDeletePermissions}
                                hover_color='mdc-icon-button__delete'
                                onClick={() => ConfirmModal({
                                    "onConfirmedDeletion": clickConfirmed,
                                    "moduleName": MODULE_NAME,
                                    "recordId": record.id,
                                    "name": record.unit_code
                                })}
                                icon={<SvgDeleteIcon />}
                            />
                        </Space> */}
                        <Space size="middle">
                            <IconButton
                                isVisible={hasViewPermissions}
                                hover_color='mdc-icon-button__detail'
                                onClick={() => showModal(record, true)}
                                icon={<SvgDetailIcon />}
                            />
                        </Space>
                    </Fragment>
                );
            }
        }
    ];

    const resetForm = () => {
        form.resetFields();
    };


    const showModal = (vehicle = [], isDetails = false) => {
        let newModalTitle = "Create Vehicle";
        form.qr_code = '';
        if (vehicle.id) {
            let parent = vehicle.parent;
            canUserAdminCustomer && parent ? dispatch(loadDriversByManager(parent)) : dispatch(loadDrivers());

            let vehicleDrivers = []
            if (vehicle.drivers !== undefined) {
                for (let driverId of vehicle.drivers) {
                    let driver = drivers.find(driver => driver.id === driverId)
                    if (driver) {
                        vehicleDrivers.push(driver.id);
                    }
                }
                vehicle.drivers = vehicleDrivers;
            }
            dispatch(loadVehicleModelsByBrand(vehicle.vehicle_brand));

            form.setFieldsValue(vehicle);
            form.qr_code = vehicle.qr_code;
            newModalTitle = isDetails ? "Vehicle Detail" : "Edit Vehicle";
        }

        setTitleModal(newModalTitle);
        setIsDetailsOnly(isDetails);
        setIsModalVisible(true);
    };

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    
    const handleSearch = (event) => {
        let value = (event.target.value).trim().toLowerCase();

        let filtered = vehicles_.filter(element => {
            const { plate_number, unit_code, fleets } = element;
            return (
                plate_number.toString().toLowerCase().includes(value) ||
                unit_code.toLowerCase().includes(value) ||
                fleets.some(fleet => fleet.toLowerCase().includes(value))
            );
        });

        setSearch(value);
        setUserSerch(filtered);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        resetForm();
    }

    const handleSave = (values) => {

        if (!isDetailsOnly) {
            if (!values.activeTracker) {
                values.tracking_device = null
            }
            if (values.id)
                dispatch(updateVehicle(values));
            else
                dispatch(addVehicle(values));
        } else {
            setIsDetailsOnly(false);
            setTitleModal("Edit Vehicle");
        }
    }

    const groupsFormOnClose = () => {
        dispatch(loadVehicles());
    }

    return (
        <Fragment>
            <CreateModal
                key={titleModal == "Create Vehicle" ? 1 : form.getFieldValue("unit_code")}
                okText={isDetailsOnly ? 'Edit' : 'Save'}
                width={1000}
                visible={isModalVisible}
                onCancel={handleCancel}
                okButtonProps={{ onClick: form.submit, loading: loadingAction }}
                title={titleModal}
            >
                <CreateVehicleForm
                    form={form}
                    getContainer={false}
                    onFinish={handleSave}
                    drivers={drivers}
                    managers={managers}
                    gsTrackingDevices={gsTrackingDevices}
                    isDetailsOnly={isDetailsOnly} 
                    loadingAction={loadingAction}
                    t={t}
                />
            </CreateModal>
            <Row>
                <Col xs={12}>
                    <h1 style={{ fontSize: '2em' }}>
                        {t('header')}
                    </h1>
                </Col>
                <Col xs={12}>
                    <CustomButton
                        onClick={showModal}
                        type="primary"
                        className='create-button'
                        style={{
                            width: '150px',
                            float: 'right',
                            display: hasResourcePermission(ADD_PERMISSION_CODENAME) ? '' : 'none'
                        }}
                    >
                        {t('button')}
                    </CustomButton>
                    <ListGroups   
                        className='mt-8' 
                        groupsFormOnClose={groupsFormOnClose} 
                        translationFunction={t} 
                    />
                </Col>
                <Col xs={0}>
                    <BreadcrumbCustom items={["Home", "Vehicle List"]} />
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <CustomSearhInput 
                        placeholder="Search here..." 
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <CustomTable
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                columns={columns}
                dataSource={searchValue ? usersSerch : vehicles_}
                rowKey='created_at'
                loading={loading}
            />
        </Fragment>
    );
}

export default Vehicles;
