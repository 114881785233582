import React from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import MenuItem from '@mui/material/MenuItem';
import { loadProductsByStation } from "../../store/products";

const CreateDispenserForm = (props) => {

    const { t } = props;
    const [name, setName] = React.useState()
    const [terminalNumber, setTerminalNumber] = React.useState('')

    let { dispenserTypes, products, stations } = props;

    const dispatch = useDispatch();

    const singleDispenserValue = dispenserTypes.length == 1
    const singleStationValue = products.length == 1

    const statusOptions = [
        {
            name: 'Active',
            value: true
        },
        {
            name: 'Inactive',
            value: false
        }
    ];

    let xs_col_i = 24;
    let xs_col_b = 0;
    let md_col_i = 24;
    let md_col_b = 0;
    let lg_col_i = 24;
    let lg_col_b = 0;
    if (props.camaraActive && !props.isDetailsOnly) {
        xs_col_i = 17;
        xs_col_b = 5;
        md_col_i = 20;
        md_col_b = 4;
        lg_col_i = 22;
        lg_col_b = 2;

    }

    const handleNameChange = (value) => {
        setName(value);
    }

    const handleTerminalNumberChange = (value) => {
        setTerminalNumber(value);
    }

	const onFormFinish = (values) => {
		setName("");
		setTerminalNumber("");
		props.onFinish(values)
	}

    return (
        <CustomForm
            form={props.form}
			onFinish={values => onFormFinish(values)}
            initialValues={{
                is_active: true,
                dispenser_type: singleDispenserValue ? dispenserTypes[0].id  : '',
                product: '',
                station: singleStationValue ? stations[0].id : ''
            }}
        >
            <CustomFormItem label="id" name="id" hidden={true}>
                <CustomTextInput disabled={props.isDetailsOnly} />
            </CustomFormItem>

            <Row>
                <Col xs={xs_col_i} md={md_col_i} lg={lg_col_i}>
                    <CustomFormItem name="name" rules={[{ required: true, message: 'Please input the name' }]}>
                        <CustomTextInput
                            id="name"
                            label="UID"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            disabled={props.isDetailsOnly}
                            maxLength={140}
                            onChange={event => handleNameChange(event.target.value)}
                            InputLabelProps={(props.form.getFieldValue('name') || name) && { shrink: true }}
                        />
                    </CustomFormItem>
                </Col>
                <Col flex="auto" xs={xs_col_b} md={md_col_b} lg={lg_col_b} >
                    {
                        props.camaraActive && !props.isDetailsOnly
                            ? <Button type="primary" style={{ width: '100%' }} onClick={props.showModalQr} > <CameraOutlined /> </Button>
                            : null
                    }
                </Col>
            </Row>
            <CustomFormItem name="station" rules={[{ required: true, message: 'Please select a station' }]}>
                <CustomTextInput
                    id="station"
                    label="Station"
                    size="small"
                    select
                    fullWidth
                    required
                    disabled={props.isDetailsOnly}
                    placeholder="Please select an station"
                    onChange={(e) => {
                        props.form.setFieldsValue({ product: "" });
                        dispatch(loadProductsByStation(e.target.value))
                    }}
                >
                    {props.stations.map(station => (
                        <MenuItem key={station.id} value={station.id} disabled={!station.is_active}>
                            {station.name}
                        </MenuItem>
                    ))}
                </CustomTextInput>
            </CustomFormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomFormItem name="product" rules={[{ required: true, message: 'Please select a product' }]}>
                        <CustomTextInput
                            id="product"
                            label="Product"
                            size="small"
                            select
                            fullWidth
                            required
                            loading={props.loadingProducts}
                            disabled={props.isDetailsOnly}
                            placeholder="Please select an product"
                        >
                            {props.products.map(product =>
                                <MenuItem key={product.id} value={product.id} disabled={!product.is_active}>
                                    {product.name}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
                <Col span={12}>
                    <CustomFormItem
                        name="is_active"
                    >
                        <CustomTextInput
                            label="Status"
                            size="small"
                            select
                            fullWidth
                            disabled={props.isDetailsOnly}
                            placeholder="Please select a status"
                        >
                            {statusOptions.map(option =>
                                <MenuItem
                                    key={option.name}
                                    value={option.value}
                                >
                                    {t(option.name.toLowerCase(), {ns: 'tags'})}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomFormItem name="terminal_number" rules={[{ required: true, message: 'Please input the terminal number' }]}>
                        <CustomTextInput
                            id="terminal_number"
                            label="Terminal Number"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            min={0}
                            max={32000}
                            maxLength={5}
                            style={{ width: "100%" }}
                            disabled={props.isDetailsOnly}
                            onChange={event => handleTerminalNumberChange(event.target.value)}
                            InputLabelProps={(props.form.getFieldValue('terminal_number') || terminalNumber) && { shrink: true }}
                        />
                    </CustomFormItem>
                </Col>
                <Col span={12}>
                    <CustomFormItem name="dispenser_type" rules={[{ required: true, message: 'Please select a dispenser type' }]}>
                        <CustomTextInput
                            id="dispenser_type"
                            label="Dispenser Type"
                            size="small"
                            select
                            fullWidth
                            required
                            disabled={props.isDetailsOnly}
                            placeholder="Please select an dispenser type"
                        >
                            {props.dispenserTypes.map(dispenserType =>
                                <MenuItem key={dispenserType.id} value={dispenserType.id}>
                                    {dispenserType.name}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={0}>
                    <CustomFormItem name="software_version" rules={[{ required: false, message: 'Please input the software version' }]}>
                        <CustomTextInput
                            id="software_version"
                            label="Software Version"
                            size="small"
                            fullWidth
                            disabled={props.isDetailsOnly}
                            maxLength={100}
                        />
                    </CustomFormItem>
                </Col>
            </Row>
        </CustomForm>
    );
}

export default CreateDispenserForm;
