import styled from 'styled-components';
import { Menu } from 'antd';

const CustomMenuItem = styled(Menu.Item)`
    color: #C2CFE0;
    border-bottom: none;

    &:hover {
        border-bottom: ${props => props.theme.primaryColor} solid thin;
        transition: all ease-in-out .3s;
        color: ${props => props.theme.primaryColor};

        & span {
            color: ${props => props.theme.primaryColor};
        }

        & svg {
            margin-left: 10px;
        }
    }

    &.ant-menu-item:hover > span > a {
        color: ${props => props.theme.primaryColor};
    }

    &.ant-menu-item-selected {
        background-color: #434E59 !important;
        border-radius: 6px;
        opacity: 1;
        width: 96% !important;
        
        &:hover {
            background-color: #434E59;
            border: none;
            margin: none;

            & svg {
                margin: 0;
            }
        }

        &::before {
            content: '';
            display: block;
            height: 7px;
            width: 7px;
            background-color: ${props => props.theme.primaryColor};
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translateY(-50%);
        }
    }
`;

export default CustomMenuItem;

