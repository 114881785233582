import React, { useState, useEffect, Suspense } from 'react'
import { Layout, Row, Col, Spin } from 'antd';
import SideBar from '../components/SideBar';
import DrawerBar from '../components/DrawerBar';
import MainHeader from '../components/MainHeader';
import Content from '../components/Content';
import CustomSpinner from '../components/Custom/CustomSpinner';
import '../assets/css/App.scss'
import styled from 'styled-components';
import { getLoginRoles } from '../store/roles'
import { getCompanyConfiguration } from '../store/authentication'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const { Footer } = Layout;

const ContentFooter = styled(Footer)`
    text-align: center;
    font-size: 1em;
    font-weight: 10px;
    }
`
const Home = () => {
    const [collapsedSideBar, setCollapsedSideBar] = useState(false);
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation(['home_layout', 'common_labels']);
    const { authData, user } = useSelector(state => state.entities.authentication);
    const dispatch = useDispatch();
    const triggerLoginEvent = window.localStorage.getItem("triggerLoginAnalyticEvent")

    const { company } = user;

    if (triggerLoginEvent) {
        const userExternalId = authData.user.external_id
        ReactGA.event(`gs_user_login`, {"by_external_id": userExternalId})

        window.localStorage.setItem("triggerLoginAnalyticEvent", false)

    }

    useEffect(() => {
        dispatch(getCompanyConfiguration(company));
    }, []);

    useEffect(() => {
        if (authData.user.primary_role) {
            dispatch(getLoginRoles(authData.user.primary_role.id))
        } else {
            authData && dispatch(getLoginRoles(""))
        }
    }, [authData]);

    const onBreakPoint = (broken) => {
        setCollapsedSideBar(broken)
    }

    const onClose = () => {
        setCollapsedSideBar(!collapsedSideBar);
        setVisible(false);
    };

    return (
            <Layout
                className="site-layout"
                hasSider
                style={{ minHeight: '100vh' }}
            >
                <Row>
                    <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                        <DrawerBar 
                            collapsed={collapsedSideBar} 
                            visible={visible} 
                            onClose={onClose} 
                            translationFunction={t}
                        />
                    </Col>
                    <Col className='h-100'>
                        <SideBar
                            collapsed={collapsedSideBar}
                            visible={visible}
                            onClose={onClose}
                            onBreakPoint={onBreakPoint}
                            translationFunction={t}
                        />
                    </Col>
                </Row>
                <Layout>
                    <MainHeader
                        onClose={onClose}
                        translationFunction={t}
                    />
                        <Suspense fallback={<CustomSpinner size="large" />}>
                            {authData && <Content/>}
                        </Suspense>
                    <ContentFooter>
                        Gas Station ©2024 Created by Pegasus Control
                    </ContentFooter>
                </Layout>
            </Layout>
    );
};

export default Home;
