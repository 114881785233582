import React, { Fragment, useReducer, View, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Layout, Row } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined, LoginOutlined } from '@ant-design/icons';
import CustomMenu from './Custom/CustomMenu';
import { logout } from '../store/authentication';
import SvgChevronDown from './icons/ChevronDown';
import styled from 'styled-components';
import {loadFakeLogin} from '../store/authentication'
import RolesIcon from './icons/RolesIcon'

const { SubMenu, Item } = CustomMenu;
const { Header } = Layout;

const StyledCustomMenu = styled(CustomMenu)`
    float: right;
    border: 1px #eaeaea solid;
    border-radius: 10px;
`;

const StyledHeader = styled(Header)`
    background-color: white;
    padding: 0;
    z-index: 666;
    box-shadow: 1px 1px 10px #C2CFE066;
    position: sticky;
    top: 0;
`;

const MainHeader = (props) => {
    const dispatch = useDispatch();
    const { translationFunction: t } = props;
    const { authData, config } = useSelector(state => state.entities.authentication);
    const {profile_permissions, role_name} = useSelector(state => state.entities.authentication.user);
    const {loginRoles} = useSelector(state => state.entities.roles);

    const {primary_role, roles} = loginRoles

    const [value, setValue] = useState();

    let navigate = useNavigate();

    const handleLogOut = () => {
        dispatch(logout())
    }

    const getName = () => {
        return authData.user.first_name + " " + authData.user.last_name;
    }

    const getEmail = () => {
        return authData.user.email;
    }

    const fakeLogin = (id) => {
        dispatch(loadFakeLogin(
            authData.user.email,
            "******",
            id
        ));
    }

    return (
        <StyledHeader
            className="site-layout-background pl-3" 
        > 
            <Row>
                <Col lg={4} >
                    {React.createElement(props.collapsedSideBar ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => props.onClose(),
                    })}
                </Col>
                <Col
                    xs={19}
                >
                        <StyledCustomMenu
                            mode="horizontal"
                            selectable={false}
                            disabledOverflow={true}
                        >
                            <SubMenu
                                key="SubMenu"
                                className='mr-2'
                                title={
                                        <Row>
                                            <Col style={{
                                                marginTop: "10px",
                                                display: "inline"
                                            }}>
                                                <RolesIcon className="mr-5" />
                                            </Col>
                                            <Col className='ml-2'>
                                                {getName() + ' '}
                                                < SvgChevronDown className='ml-2' />
                                            </Col>
                                        </Row>
                                }
                            >
                                <Item
                                    className="text-dark h-50"
                                    key="setting:2"
                                    disabled={false}
                                >
                                    <div
                                        className="m-0"
                                        style={{
                                            paddingTop: '10px'
                                        }}
                                    >
                                        <p className='m-0'
                                            style={{
                                                lineHeight: 1.5
                                            }}
                                        >
                                            {t('header.menu.title')} 
                                            <span  className='m-1' style={{color:"#65C0C4"}
                                            }>{primary_role && t(primary_role.name, {ns: 'common_labels'})}</span>
                                        </p>
                                        <p
                                            className="mt-0 m-0"
                                            style={{
                                                fontStyle:"italic",
                                                color:"#bababa"
                                            }}
                                        >
                                            {getEmail()}
                                        </p>
                                    </div>
                                </Item>
                                 { roles?.length > 0 &&
                                   <SubMenu
                                        className="border-top"
                                        key="RoleMenu"
                                        title={
                                                <Row>
                                                    <Col>
                                                        {t('header.menu.role_setting')}
                                                    </Col>
                                                </Row>
                                        }
                                    >
                                        {
                                            roles && 
                                            roles.map((role) => (
                                                <Item 
                                                    className="border-bottom" 
                                                    key={role.id} 
                                                    onClick={
                                                        event => fakeLogin(event.key)
                                                    }
                                                >
                                                    {t(role.name, {ns:'common_labels'})}
                                                </Item> 
                                            ))
                                        }
                                    </SubMenu>
                                }
                                <Item
                                    key="setting:3"
                                    icon={<SettingOutlined />}
                                    onClick={() => navigate(`/settings`)}
                                    className='border-top'
                                >
                                    {t('header.menu.settings')}
                                </Item>
                                <Item
                                    key="setting:1"
                                    icon={<LoginOutlined />}
                                    onClick={handleLogOut}
                                    className='border-top'
                                >
                                    {t('header.menu.logout')}
                                </Item>
                            </SubMenu>
                        </StyledCustomMenu>
                </Col>
            </Row>
        </StyledHeader>
    );
}

export default MainHeader;
