function InactiveAuthCodeIcon() {
    return (
        <svg width="16.98" height="17.025" viewBox="0 0 16.98 17.025">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rectángulo_17984" data-name="Rectángulo 17984" width="16.98" height="17.025" fill="#9eaab5" stroke="#9eaab5" stroke-width="0.5"/>
                </clipPath>
            </defs>
            <g id="Grupo_15288" data-name="Grupo 15288" transform="translate(0.001)">
                <g id="Grupo_15287" data-name="Grupo 15287" transform="translate(-0.001)" clip-path="url(#clip-path)">
                <path id="Trazado_13888" data-name="Trazado 13888" d="M8.351,9.168c-.073.1-.116.153-.158.21a1.617,1.617,0,0,1-1.291.709c-.353.016-.434.1-.456.438a1.593,1.593,0,0,1-1.56,1.584.518.518,0,0,0-.47.31A3.15,3.15,0,0,1,1.34,14.075a1.566,1.566,0,0,1-1.32-1.9A4.01,4.01,0,0,1,1.154,9.832q1.835-1.856,3.679-3.7a2.615,2.615,0,0,1,.31-.219,3.618,3.618,0,0,1-.336-.254Q2.556,3.4.31,1.149a1.744,1.744,0,0,1-.2-.222.525.525,0,0,1,.065-.7A.521.521,0,0,1,.849.165a1.734,1.734,0,0,1,.243.218Q7.384,6.691,13.674,13a1.753,1.753,0,0,1,.2.222.541.541,0,0,1-.759.751,1.888,1.888,0,0,1-.22-.2q-2.171-2.176-4.341-4.355c-.07-.07-.127-.154-.2-.247M5.863,6.718C4.5,8.062,3.161,9.373,1.84,10.7A2.643,2.643,0,0,0,1.1,12.51a.406.406,0,0,0,.38.474,2.036,2.036,0,0,0,1.977-1.093,1.517,1.517,0,0,1,1.3-.871c.434-.037.574-.172.6-.607a1.421,1.421,0,0,1,1.41-1.419.663.663,0,0,0,.678-.521A.248.248,0,0,0,7.4,8.265c-.515-.527-1.037-1.047-1.536-1.547" transform="translate(1.502 1.432)" fill="#9eaab5" stroke="#9eaab5" stroke-width="0.5"/>
                <path id="Trazado_13889" data-name="Trazado 13889" d="M103.53,4.982a3.783,3.783,0,0,1-.831,2.4,5.263,5.263,0,0,1-1.454,1.309.561.561,0,0,1-.832-.146c-.168-.286-.053-.582.275-.8a3.429,3.429,0,0,0,1.7-2.3,2.654,2.654,0,0,0-.722-2.34c-.4-.425-.813-.836-1.234-1.239a2.7,2.7,0,0,0-3.773-.01,10.325,10.325,0,0,0-.9,1.053.566.566,0,0,1-.741.2A.522.522,0,0,1,94.8,2.4C96.027.313,98.331-.744,100.567.6a4.687,4.687,0,0,1,.778.638c.384.363.755.739,1.12,1.122a3.808,3.808,0,0,1,1.065,2.627" transform="translate(-88.049 1.498)" fill="#9eaab5" stroke="#9eaab5" stroke-width="0.5"/>
                <path id="Trazado_13890" data-name="Trazado 13890" d="M158.221,47.911a1.5,1.5,0,0,1,.351.208c.528.512,1.046,1.035,1.562,1.56a.549.549,0,0,1,.035.821.542.542,0,0,1-.8-.041c-.53-.523-1.054-1.053-1.578-1.582a.527.527,0,0,1-.137-.622.592.592,0,0,1,.567-.345" transform="translate(-147.476 -43.79)" fill="#9eaab5" stroke="#9eaab5" stroke-width="0.5"/>
                </g>
            </g>
        </svg>
    )
}

export default InactiveAuthCodeIcon