import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

const { Item } = Form;

const CustomFormItem = (props) => {

    const { t } = useTranslation('form_error_messages');
    const { rules } = props;

    if (rules) {
        props = {
            ...props, 
            rules: rules.map( rule => {
                rule.message = t(rule.message);
                return rule
            })
        };
    };

    return (<Item {...props} />)
};

export default CustomFormItem;
