import styled from 'styled-components';
import { Button } from 'antd';

let colorPrimary = '#65C0C4';
const CustomButton = styled(Button)`
    ${props => {
        if (props['loginColor']) {
            if (props.loginColor === 'fleet') {
                colorPrimary = props.theme.primaryColorFleet;
            } else {
                colorPrimary = props.theme.primaryColorStation
            }
        } else {
            colorPrimary = props.theme.primaryColor
        }
    }};
    background: var(--unnamed-color-65c0c4) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-65c0c4);
    background: ${props => colorPrimary};
    border: 1px solid ${props => colorPrimary};
    border-radius: 10px;
    text-shadow: none;
    opacity: 1;
    :hover {
        background-color: ${props => colorPrimary};
        border: 1px solid ${props => colorPrimary};
        opacity: 0.8;
    }
    :active {
        background-color: ${props => colorPrimary};
        border: 1px solid ${props => colorPrimary};
    }
    :focus {
        background-color: ${props => colorPrimary};
        border: 1px solid ${props => colorPrimary};
    }

    &.ant-btn.ant-btn-loading {
        cursor: not-allowed;
    }

    &.create-button {
        width: 200px;
        height: 40px;
        background-color: ${props => colorPrimary};
        color: #FFFFFF;
        border: 1px solid var(--unnamed-color-65c0c4);
        border-radius: 6px;
        box-shadow: 0px 3px 10px ${props => colorPrimary};
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
        }
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &.mincon_button {
        text-align: left;
        background: none;
        border-color: transparent    
    }

    &.maxcon_button {
        text-align: left;
        background: none;
        border-color: transparent  
    }

    &.login-button {
        height: 70px;
    }
`;

export default CustomButton;