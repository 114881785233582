import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

const CustomMaterialMenuItem = styled(MenuItem)( props => ({

    '&' : {

        // 'height': '2em',

        ':hover' : {
            'background-color': 'rgba(255, 0, 0, 0.8)',
        },

        '&.MuiButtonBase-root' : {
            '::after' : {
                'content': `''`,
                'position': 'absolute',
                'border-radius': '100%',
                'height': '21px',
                'width': '21px',
                'display': 'block',
                'border-style': 'dotted',
                'border-width': '2px',
                'right': '35px',
                'opacity': 0.60,
            }
        },
        
        '&.Mui-selected' : {
            '::after' : {
                'content': `'✓'`,
                'font-size': '16px',
                'text-align': 'center',
                'line-height': '18px',
                'border-style': 'solid',
                'opacity': 1,
                'color': 'rgb(48, 155, 145)',
            }
        },

        '&.Mui-disabled' : {
            '::after' : {
                'content': `'not available'`,
                'font-size': '12px',
                'position': 'absolute',
                'border-radius': '0%',
                'display': 'block',
                'border': 'none',
                'right': '58px',
                'opacity': 0.60,
                'color': 'rgb(122, 122, 122)'
            }
        }

    }
}));

export default CustomMaterialMenuItem;
