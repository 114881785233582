import React, { Fragment } from 'react';
import { useSelector } from "react-redux";

import { Row, Col, Tabs } from 'antd';
import styled from 'styled-components';
import ChangePasswordForm from './FormChangePassword.component';
import SystemConfigurationForm from './SystemConfigurationForm';
import CustomTextInput from '../Custom/CustomTextInput';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const CustomTabPane = styled(TabPane)`
    font-size: 14px!important;
`;

const Settings = () => {
    const { user } = useSelector(state => state.entities.authentication);
    const { i18n, t } = useTranslation(['settings', 'common_labels', 'currency']);

    const isStationOwner = user.role_name === "Station Owner";

    const defaultLanguage = i18n.languages[0];

    const titleStyle = {fontSize: '2em'};
    const tabTitleStyle = { color: '#425D5D', fontSize: '18px' };
    const whiteBgStyle = { backgroundColor: 'white'};

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
        window.localStorage.setItem('language', value);
    }

    const languageOptions = [
        { value: 'es', key: 'es', label: 'Español' },
        { value: 'en', key: 'en', label: 'English' }
    ];

    return (
        <Fragment>
            <Row>
                <Col span={12}>
                    <h1 style={titleStyle}>
                        {t('sections.header')}
                    </h1>
                </Col>
            </Row>
      
           <Row style={whiteBgStyle}>

                <Col xs={0} md={12} sm={24} className='m-3'>
                    <Tabs tabPosition="left">

                        <CustomTabPane 
                            tab={t('sections.security.tab')}
                            key="1"
                        >
                            <ChangePasswordForm t={t} title={t('sections.security.title')} />
                        </CustomTabPane>

                        <CustomTabPane
                            tab={t('sections.language.tab')}
                            key="2"
                        >
                             <Row>
                                <Col md={24} sm={24} 
                                    className='m-2 mt-3'
                                    style={tabTitleStyle} 
                                >
                                    {t('sections.language.title')}
                                </Col>
                            </Row>
                            <CustomTextInput
                                id="language"
                                label="Language"
                                size="small"
                                select
                                fullWidth
                                defaultValue={defaultLanguage}
                                placeholder="Please select your language"
                                onChange={e => changeLanguage(e.target.value)}
                            >
                                {languageOptions.map(option =>
                                    <MenuItem
                                        key={option.key}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                )}
                            </CustomTextInput>
                        </CustomTabPane>

                        { isStationOwner &&
                            <CustomTabPane
                                tab={t('sections.system.tab')}
                                key="3"
                            >
                                <div>
                                    <Row>
                                        <Col md={24} sm={24} className='m-2 mt-3'
                                        style={tabTitleStyle}
                                    >
                                            {t('sections.system.title')}
                                        </Col>
                                    </Row>
                                    <SystemConfigurationForm />
                                </div>
                            </CustomTabPane>
                        }

                    </Tabs>
                </Col>

                  <Col xs={22} md={0} sm={0} className='m-3'>
                    <Tabs tabPosition="top">
                         <CustomTabPane tab="Security" key="1">
                            <ChangePasswordForm />
                        </CustomTabPane>
                    </Tabs>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Settings;
