import { notification } from 'antd';
import i18next from '../../i18nextGasStation';


export const showNotification = (status, method, errors) => {

    const { t } = i18next;
    const ns = {
        ns: 'system_notification_messages'
    };
    const
        successMessage = t('success.message', ns),
        createdSuccessMessage = t('success.created', ns),
        emailSuccessMessage = t('success.email_sent', ns),
        deletedSuccessMessage = t('success.deleted', ns),
        editedSuccessMessage = t('success.edited', ns),
        errorMessage = t('error.message', ns),
        successfulKeyWord = t('success.successful', ns),
        failedKeyWord = t('error.failed', ns),
        internalErrorMessage = t('error.server_error', ns);

    let type = "";
    let message = "";
    let description = "";

    if (status === 201) {
        type = "success";
        message = successMessage;
        description = createdSuccessMessage;
    }

    if (status === 202) {
        type = "success";
        message = successMessage;
        description = emailSuccessMessage;
    }

    else if (status === 204) {
        type = "success";
        message = successMessage;
        description = deletedSuccessMessage;
    }

    else if (status === 200 && (method === 'put' || method === 'patch' || method === 'post')) {      
        type = "success";
        message = successMessage;
        description =  errors ? errors : editedSuccessMessage;
    }

    else if (status === 207) {
        let errorsLen = errors.errors.length;
        let successLen = errors.success.length;
        type = errorsLen === 0 ? "success" : "error"; 
        message = errorsLen === 0 ? successMessage : errorMessage;
        description = errorsLen === 0 ? createdSuccessMessage :  `${successfulKeyWord} ${successLen}, ${failedKeyWord} ${errorsLen}`;
    }

    else if (status >= 400 && status <= 499 ) {
        let array_values = [];
        Object.entries(errors).forEach(([key, value]) => {
            array_values.push(value);

        });
        
        type = "error";
        message = errorMessage;
        description = array_values.join();
    }

    else if (status >= 500) {
        type = "error";
        message = errorMessage;
        description = internalErrorMessage;
    }

    return (notification[type]({
        message: message,
        description: description,
    }));
}
