import React from 'react';
import { Menu } from 'antd';
import CustomMenuItem from './Custom/CustomMenuItem';
import { Link } from "react-router-dom";
import SvgServices from './icons/Services';
import SvgUsers from './icons/Users';
import SvgStations from './icons/Stations';
import SvgProducts from './icons/Products';
import SvgAuthorizations from './icons/Authorizations';
import SvgDispensers from './icons/Dispensers';
import SvgVehicles from './icons/Vehicles';
import { PermissionValidator } from './shared/PermissionValidator';
import { useLocation } from 'react-router-dom'
import styled from 'styled-components';

let subdomain = window.location.host.split('.')[0];

if (subdomain.includes("localhost") || subdomain.includes("dashboard")) {
    subdomain = 'app'
}

const LogoContainer = styled.div`
    background-color: ${subdomain === 'thompsongas' ?  '#fff' : 'none'}; 
    position: sticky; 
    top: 0;
`;

const Img = styled.img`
    width: 90%;
`;

const StyledMenu = styled(Menu)`
    position: sticky;
    top: 0;
`;

const MenuBar = (props) => {

    const { translationFunction: t } = props;

    const { REACT_APP_API_BUCKER_LOGOS } = process.env;
    const { hasResourcePermission } = PermissionValidator();

    let { pathname } = useLocation();
    let url = `${REACT_APP_API_BUCKER_LOGOS}${subdomain}`;

    return (
           <StyledMenu
                theme="dark"
                selectedKeys={[pathname === '/' ? '/services' : pathname]}
                mode="inline"
            >
                <LogoContainer
                    className="logo text-center"
                >
                    {
                        hasResourcePermission('access_station') ? 
                            (props.collapsed ?  
                                <Img src={`${url}/logoMenuIcon`} alt="logo" />  : 
                                <Img src={`${url}/logoMenu`} alt="logo" />) :
                                    (props.collapsed ?   
                                        <Img src={`${url}/logoMenuFleetIcon`} alt="logo" /> :  
                                        <Img src={`${url}/logoMenuFleet`} alt="logo" />  )
                    }
                </LogoContainer>

                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_service') ? '' : 'none' }}
                    key="/services"
                    onClick={props.onClose}
                    icon={<SvgServices />}
                >
                    <Link to="/services">
                        {
                            hasResourcePermission('access_station') 
                                ? t('side_menu.sales') 
                                : t('side_menu.fuel_consumption')
                        }
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_user') ? '' : 'none' }}
                    key="/users"
                    icon={<SvgUsers />}
                    onClick={props.onClose}
                >
                    <Link to="/users">
                        {
                            hasResourcePermission('access_vehicle') 
                                ? t('side_menu.drivers')
                                : t('side_menu.users')
                        }
                    </Link>
                </CustomMenuItem>
                 <CustomMenuItem
                    style={{ display: hasResourcePermission('access_station') ? '' : 'none' }}
                    key="/stations"
                    onClick={props.onClose}
                    icon={<SvgStations />}
                >
                    <Link to="/stations">
                        {t('side_menu.stations')}
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_customer') ? '' : 'none' }}
                    key="/customers"
                    icon={<SvgUsers />}
                    onClick={props.onClose}
                >
                    <Link to="/customers">
                        {t('side_menu.customers')}
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_product') ? '' : 'none' }}
                    key="/products"
                    onClick={props.onClose}
                    icon={<SvgProducts />}
                >
                    <Link to="/products">
                        {t('side_menu.products')}
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_vehicle') ? '' : 'none' }}
                    key="/vehicles"
                    onClick={props.onClose}
                    icon={<SvgVehicles />}
                >
                    <Link to="/vehicles">
                        {t('side_menu.vehicles')}
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_dispenser') ? '' : 'none' }}
                    key="/dispensers"
                    onClick={props.onClose}
                    icon={<SvgDispensers />}
                >
                    <Link to="/dispensers">
                        {t('side_menu.dispensers')}
                    </Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_authorization') ? '' : 'none' }}
                    key="/authorizations"
                    onClick={props.onClose}
                    icon={<SvgAuthorizations />}
                >
                    <Link to="/authorizations">Password ID</Link>
                </CustomMenuItem>
                <CustomMenuItem
                    style={{ display: hasResourcePermission('access_dispenser') ? '' : 'none' }}
                    key="/business_intelligence"
                    onClick={props.onClose}
                    icon={<SvgAuthorizations />}
                >
                    <Link to="/business_intelligence">BI</Link>
                </CustomMenuItem>
            </StyledMenu>
    );
}
export default MenuBar;
