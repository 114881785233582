import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import CustomButton from '../Custom/CustomButton';
import CustomSpinner from '../Custom/CustomSpinner';

const BussinessIntelligence = () => {

    const location = useLocation();
    const { i18n, t } = useTranslation('bi');
    const selectedLanguage = i18n.languages[0];
    const currentYear = new Date().getFullYear();
    let initialParam = null;
    let lastParam = null;

    const [loading, setLoading] = useState(true);
    const [iframeKey, setIframeKey] = useState(0);
    const [initialYear, setInitialYear] = useState(currentYear - 1);
    const [finalYear, setFinalYear] = useState(currentYear);

    const test = (event) => {
        const searchP = new URLSearchParams(event.data.metabase?.location?.search);

        initialParam = searchP.get("a%C3%B1o_inicial") || searchP.get("start_year");
        lastParam = searchP.get("a%C3%B1o_final") || searchP.get("last_year");
    };

    window.addEventListener('message', test);

    const dashboards = {
        "en": "https://bi.pegasus.com.mx/public/dashboard/b80e06bc-f680-4606-9267-0f7263a281a6?start_year={lastYear}&last_year={currentYear}",
        "es": "https://bi.pegasus.com.mx/public/dashboard/9d987d64-4ef0-4265-8403-a13561cd153b?a%25C3%25B1o_inicial={lastYear}&a%25C3%25B1o_final={currentYear}",
    };

    const handleLoading = () => {
        setLoading(false);
    };

    const reloadIframe = () => {
        if (initialParam && lastParam) {
            setInitialYear(initialParam);
            setFinalYear(lastParam);
        };
        setIframeKey(iframeKey + 1);
        setLoading(true);
    };

    const getUrl = () => {
        const url = dashboards[selectedLanguage].
                    replace('{lastYear}', initialYear).
                    replace('{currentYear}', finalYear);
        return url
    }


    const iframeStyle = {
        width: "100%",
        minHeight: "300vh"
    };

    const headerStyle = { fontSize: '2em' };

    const iconFill = { color: 'white' };


    return (
        <>
            <Row justify="space-between">
                <Col>
                    <h1 style={headerStyle}>Business Intelligence</h1>
                </Col>
                <Col>
                    <CustomButton
                        type="primary"
                        className="create-button"
                        loginColor={location.pathname.includes("fleet") ? 'fleet' : 'station' }
                        disabled={loading}
                        style={{
                            float: 'right'
                        }}
                        onClick={reloadIframe}
                    >
                        {t('reload_button')}
                    </CustomButton>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {loading && <CustomSpinner size="large" spinning={loading} />}
                    <iframe
                        key={iframeKey}
                        style={iframeStyle}
                        src={getUrl()}
                        frameBorder="0"
                        allowtransparency
                        scrolling="no"
                        onLoad={handleLoading}
                    />
                </Col>
            </Row>
        </>
    );
}

export default BussinessIntelligence;
