//import { PermissionValidator } from '../shared/PermissionValidator';
import { useState, useEffect } from 'react';
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { loadDriversByManager } from '../../store/vehicles';
import { loadVehicleModelsByBrand } from '../../store/vehicleModels';
import { Row, Col, Checkbox } from 'antd';
import QRCode from 'qrcode.react';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomCheckbox from '../Custom/CustomCheckbox';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import CustomButton from '../Custom/CustomButton';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';
import vehicleIcon from './../../assets/icons/vehicle.png';
import MenuItem from '@mui/material/MenuItem';
import 'antd/dist/antd.css';

const generateArrayOfYears = () => {
    var max = new Date().getFullYear() + 1
    var min = max - 10
    var years = []

    for (var i = max; i >= min; i--) {
        years.push(i.toString())
    }
    return years
}
let years = generateArrayOfYears();


const CreateVehicleForm = (props) => {

    const { form, t } = props;
    const dispatch = useDispatch();
    const { userHasResourcePermission } = PermissionValidator();

    // Permissions
    const canAdminQr = userHasResourcePermission('admin_qr');
    const canReadTracker = userHasResourcePermission('read_tracker');

    const [ /*isParentSet,*/ setIsParentSet] = useState(props.form.getFieldValue('parent') !== undefined);
    const [ isActive, setIsActive ] = useState(!!(form.getFieldValue("tracking_device")));
    const { list: brandsList } = useSelector(state => state.entities.vehicleBrands);
    const { byBrand, loading } = useSelector(state => state.entities.vehicleModels);

    const active = (e) => {
        setIsActive(e.target.checked)
    }

    useEffect(() => {
        form.setFieldsValue({"activeTracker": isActive});
    }, [isActive]);

    const imageSourceToPrint = (source) => {
        return `
            <html>
                <head>
                    <script>
                        function printPage(){
                            addEventListener('afterprint', (event) => window.close());
                            window.print();
                        }
                    </script>
                </head>
                <body onload='printPage()'>
                    <img width='400' style='display: block;margin: 100 auto' src='${source}' />
                    <p style="font-family:sans-serif; text-align:center; font-weight:bold; font-size:13pt;">
                        ${t('unit_qr_label')}: ${props.form.getFieldValue('unit_code')}
                        <br/><br/>
                        ${t('plate_qr_label')}: ${props.form.getFieldValue('plate_number')}
                    </p>
                </body>
            </html>
        `;
    }

    const downloadQr = () => {
        const canvas = document.getElementById("qr_code");
        const QrCodeUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const pagelink = "about:blank";
        const pwa = window.open(pagelink, "_new");
        pwa.document.open();
        pwa.document.write(imageSourceToPrint(QrCodeUrl));
        pwa.document.close();
    }

    const driversCascade = props.drivers.map(driver => ({
        label: driver.first_name + " " + driver.last_name,
        value: driver.id,
        disabled: !driver.is_active
    }));

    driversCascade && driversCascade.sort((a, b) => a.label.localeCompare(b.label));

    const fleets = props.form.getFieldValue("fleets");
    let groupsCascade = [];
    if (fleets) {
        groupsCascade = fleets.map(group => ({
            label: group,
            value: group
        }));
    }
    
    return (
        <CustomForm
            form={props.form}
            onFinish={props.onFinish}
            initialValues={{
                is_active: true,
                drivers: '',
                parent: '',
                vehicle_brand: '',
                vehicle_model: '',
                year: '',
                unit_code: '',
                tank_capacity: '',
                price: '',
                external_id: ''
            }}
        >
            <Row gutter={16}>
                <Col span={props.isDetailsOnly ? 14 : 24}>
                    <CustomFormItem
                        label="id"
                        name="id"
                        hidden={true}>
                        <CustomTextInput />
                    </CustomFormItem>
                    <Row gutter={16}>
                        <Col span={12}>
                            <CustomFormItem
                                name="unit_code"
                                rules={[{
                                    required: true,
                                    message: 'Please input the Truck number'
                                }]}
                            >
                                <CustomTextInput
                                    id="unit_code"
                                    label="Truck number"
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                />
                            </CustomFormItem>
                        </Col>
                        <Col span={12}>
                            <CustomFormItem
                                name="plate_number"
                                rules={[{
                                    required: true,
                                    message: 'Please input the plate'
                                }]}
                            >
                                <CustomTextInput
                                    id="plate_number"
                                    label="Plate"
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                />
                            </CustomFormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <CustomFormItem
                                name="vehicle_brand"
                                rules={[{
                                    required: true,
                                    message: 'Please select a brand'
                                }]}
                            >
                                <CustomTextInput
                                    id="vehicle_brand"
                                    label="Brand"
                                    size="small"
                                    form={props.form}
                                    select
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                    onChange={(e) => {
                                        props.form.setFieldsValue({
                                            'vehicle_model': ''

                                        });
                                        dispatch(loadVehicleModelsByBrand(e.target.value));
                                    }}
                                >
                                    {brandsList.map(brand =>
                                        <MenuItem
                                            key={brand.id}
                                            value={brand.id}
                                        >
                                            {brand.name}
                                        </MenuItem>
                                    )}
                                </CustomTextInput>
                            </CustomFormItem>
                        </Col>
                        <Col span={12}>
                            <CustomFormItem
                                name="vehicle_model"
                                rules={[{
                                    required: true,
                                    message: 'Please select a model'
                                }]}
                            >
                                <CustomTextInput
                                    id="vehicle_model"
                                    label="Model"
                                    size="small"
                                    form={props.form}
                                    select
                                    fullWidth
                                    loading={loading}
                                    disabled={props.isDetailsOnly}
                                >
                                    {byBrand.map(model => {
                                        return <MenuItem
                                            key={model.id}
                                            value={model.id}
                                        >
                                            {model.name}
                                        </MenuItem>
                                    }
                                    )}
                                </CustomTextInput>
                            </CustomFormItem>
                        </Col>
                        <Col span={12}>
                            <CustomFormItem
                                name="year"
                                rules={[{
                                    required: true,
                                    message: 'Please select a year'
                                }]}
                            >
                                <CustomTextInput
                                    id="year"
                                    label="Year"
                                    size="small"
                                    form={props.form}
                                    select
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                >
                                    {years.map(year =>
                                        <MenuItem
                                            key={year}
                                            value={year}
                                        >
                                            {year}
                                        </MenuItem>
                                    )}
                                </CustomTextInput>
                            </CustomFormItem>

                        </Col>
                        <Col span={12}>
                            <CustomFormItem
                                name="tank_capacity"
                                rules={[{
                                    required: true,
                                    message: 'Please input the Tank Capacity'
                                }]}
                            >
                                <CustomTextInput
                                    id="tank_capacity"
                                    label="Tank Capacity"
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                />
                            </CustomFormItem>
                        </Col>
                        <Col span={0}>
                            <CustomFormItem
                                name="price"
                            >
                                <CustomTextInput
                                    id="price"
                                    label="Price"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled={props.isDetailsOnly}
                                />
                            </CustomFormItem>
                        </Col>
                    </Row>
                    <CustomFormItem
                        name="parent"
                        hidden={true}
                    >
                        <CustomTextInput
                            id="parent"
                            label="Manager"
                            size="small"
                            select
                            fullWidth
                            disabled={props.isDetailsOnly}
                            placeholder="Please select manager"
                            onChange={(e) => {
                                props.form.setFieldsValue({ drivers: "" })
                                setIsParentSet(true)
                                dispatch(loadDriversByManager(e.target.value));
                            }}
                        >
                            {props.managers.map(manager =>
                                <MenuItem
                                    key={manager.id}
                                    value={manager.id}
                                    disabled={!manager.is_active}
                                >
                                    {`${manager.first_name} ${manager.last_name}`}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                    <CustomFormItem
                        name="drivers"
                        rules={[{
                            required: true,
                            message: 'Please select a driver'
                        }]}
                    >
                        <CustomMultipleSelect
                            label='Drivers'
                            fieldName='drivers'
                            form={props.form}
                            options={driversCascade}
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                    <CustomFormItem
                        name="activeTracker"
                        hidden={props.isDetailsOnly || !canReadTracker}
                    >
                        <CustomCheckbox
                            onChange={e => active(e)}
                            checked={isActive}
                        >
                            add GS Tracker
                        </CustomCheckbox>
                    </CustomFormItem>
                    <CustomFormItem
                        name="tracking_device"
                        hidden={!isActive || !canReadTracker}
                        rules={[
                            {
                                required: isActive,
                                message: "Please input the GS Tracker id"
                            },
                        ]}
                    >
                        <CustomTextInput
                            id="tracker"
                            label="GS Tracker ID"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={props.isDetailsOnly | props.loadingAction}
                            required={isActive}
                        />
                    </CustomFormItem>
                    {props.isDetailsOnly ?
                        <CustomFormItem
                            name="fleets"
                        >
                            <CustomMultipleSelect
                                label='Groups'
                                fieldName='fleets'
                                form={props.form}
                                options={groupsCascade}
                                disabled={props.isDetailsOnly}
                            />
                        </CustomFormItem>
                        : null
                    }
                    <CustomFormItem
                        name="external_id"
                        rules={[{
                            min: 10
                        }]}
                        hidden={!canAdminQr}
                    >
                        <CustomTextInput
                            disabled={props.isDetailsOnly}
                            id="external_id"
                            label="External ID"
                            variant="outlined"
                            size="small"
                            inputProps={{
                                maxLength: 10
                            }}
                        />
                    </CustomFormItem>
                </Col>
                <Col span={props.isDetailsOnly ? 10 : 0}>
                    <Row justify="center">
                        <Col>
                            <QRCode id="qr_code"
                                value={props.form.qr_code}
                                size={256}
                                level={'H'}
                                fgColor={'#434E59'}
                                imageSettings={{
                                    src: vehicleIcon,
                                    x: null,
                                    y: null,
                                    height: 60,
                                    width: 60,
                                    excavate: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <CustomButton onClick={() => downloadQr()}>
                                {t('print_qr_button')}
                            </CustomButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </CustomForm>
    );
}

export default CreateVehicleForm;
