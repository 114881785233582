import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { current } from '@reduxjs/toolkit';

const url = '/users/';
const slice = createSlice({
    name: 'users',
    initialState: {
        list: [],
        managersList: [],
        loading: false,
        lastFetch: null,
        errors: [],
        loadingAction: false,
        loadingMangers: false,
    },
    reducers: {
        usersRequestStarted: (users, action) => {
            users.loading = true;
        },
        managersRequestStarted: (users, action) =>{
            users.loadingMangers = true;
        },
        usersRequestFailed: (users, action) => {
            users.loading = false;
        },
        usersReceived: (users, action) => {
            users.list = action.payload.data;
            users.loading = false;
        },
        usersAddUpdateRequest: (users, action) => {
            users.loadingAction = true;
        },
        userAdded: (users, action) => {
            users.errors = [];
            users.list.unshift(action.payload.data);
            users.loadingAction = false;
        },
        userAddRequestFailed: (users, action) => {
            let responseData = action.payload.data;
            users.errors = [];
            for (const field in responseData) {
                users.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            users.loading = false;
            users.loadingAction = false;
        },
        userDeleted: (users, action) => {
            users.list = users.list.filter(user => user.id !== action.payload.requestData);
            users.loadingAction = false;
        },
        userUpdated: (users, action) => {

            users.errors = [];

			const index = users.list.findIndex(
				user => {
					return user.id === action.payload.data.id && 
						user.role === action.payload.data.role
				}
			)

			action.payload.data.index = index + 1

			users.list[index] = action.payload.data

            users.loadingAction = false;
        },
        managersReceived: (users, action) => {
            users.managersList = action.payload.data;
            users.loadingMangers = true;
        },
        userSetPassword: (users, action) => {
            users.loadingAction = false;
        },
        managerRequestFailed: (users, action) => {
            users.loadingMangers = false;
        },
    }
});

export default slice.reducer;
export const loadUsers = () => apiCallBegan({
	url: `${url}get_users`,
    onStart: slice.actions.usersRequestStarted.type,
    onSuccess: slice.actions.usersReceived.type,
    onError: slice.actions.usersRequestFailed.type
});

export const addUser = user => apiCallBegan({
    url,
    method: 'post',
    data: user,
    onStart: slice.actions.usersAddUpdateRequest.type,
    onSuccess: slice.actions.userAdded.type,
    onError: slice.actions.userAddRequestFailed.type
});

export const deleteUser = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onStart: slice.actions.usersAddUpdateRequest.type,
    onSuccess: slice.actions.userDeleted.type,
    onError: slice.actions.userAddRequestFailed.type
});

export const changeStatus = (id, isActive) => apiCallBegan({
    url: `${url}${id}/`,
    method: 'patch',
    data: { is_active: isActive },
    onStart: slice.actions.usersAddUpdateRequest.type,
    onSuccess: slice.actions.userUpdated.type,
    onError: slice.actions.userAddRequestFailed.type
});

export const updateUser = (user) => apiCallBegan({
    url: `${url}${user.id}/`,
    method: 'put',
    data: user,
    onStart: slice.actions.usersAddUpdateRequest.type,
    onSuccess: slice.actions.userUpdated.type,
    onError: slice.actions.userAddRequestFailed.type
});


export const setPassword = (value) => apiCallBegan({
    url: `${url}set_password`,
    method: 'post',
    data: value,
    onStart: slice.actions.usersAddUpdateRequest.type,
    onSuccess: slice.actions.userSetPassword.type,
    onError: slice.actions.userAddRequestFailed.type
});

export const loadManagers = () => apiCallBegan({
    url: `${url}managers/`,
    onStart: slice.actions.managersRequestStarted.type,
    onSuccess: slice.actions.managersReceived.type,
    onError: slice.actions.managerRequestFailed.type
});

export const fakeLogin = (data) => apiCallBegan({
    url: `${url}fakeLogin/`,
    method: 'post',
    data: data,
});
