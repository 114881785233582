import * as React from "react";

function SvgGasIcon({ title, titleId, ...props }) {
  return (
   <svg id="Grupo_14956" data-name="Grupo 14956" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path id="Trazado_11795" data-name="Trazado 11795" d="M0,0H24V24H0Z" fill="none"/>
    <line id="Línea_258" data-name="Línea 258" x1="1" y2="1" transform="translate(17 7)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Trazado_11796" data-name="Trazado 11796" d="M14,11h1a2,2,0,0,1,2,2v3a1.5,1.5,0,0,0,3,0V9L17,6" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Trazado_11797" data-name="Trazado 11797" d="M4,20V6A2,2,0,0,1,6,4h6a2,2,0,0,1,2,2V20" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line id="Línea_259" data-name="Línea 259" x2="12" transform="translate(3 20)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line id="Línea_260" data-name="Línea 260" x2="10" transform="translate(4 8)" fill="none" stroke="#3d80ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
   </svg>
  );
}

export default SvgGasIcon;
