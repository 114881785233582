import styled from 'styled-components';
import { Radio } from 'antd';

const CustomRadioButton = styled(Radio.Button)`

    & {
        height: 100%;
    }

    span {
        color: #9EAAB5;
    }

    
    &.ant-radio-button-wrapper:not(:first-child):not(:last-child) {
    }
    
    &.ant-radio-button-wrapper:first-child {
        border-left: 1px solid #C2CFE0;
        border-radius: 10px 0px 0px 10px;
    }

    &.ant-radio-button-wrapper:last-child {
        border-right: 1px solid #C2CFE0;
        border-radius: 0px 10px 10px 0px;
    }

    &.ant-radio-button-wrapper:not(:first-child)::before {
        transition: none !important;
        position: absolute;
        top: 20%;
        left: -1px;
        display: block;
        box-sizing: content-box;
        width: 1px;
        height: 50%;
        padding: 1px 0;
        background-color: #C2CFE0;
        content: '';
    }
    
    &.ant-radio-button-wrapper {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 26px;
        padding-right: 26px;
        span {
            color: #434E59;
            font: normal normal 13px MetropolisRegular !important;
        }
        transition: none !important;
        background: none !important;
        border: 0px;
        border-top: 1px solid #C2CFE0;
        border-bottom: 1px solid #C2CFE0;
        opacity: 1;
    }

    &.ant-radio-button-wrapper-checked {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        border-color: #C2CFE0 !important;
        outline: none !important;
        box-shadow: none !important;
        span {
            font: normal normal 13px MetropolisBold !important;
            color: white;
        }
    }

    &.ant-radio-button-wrapper-checked div {
        display:inlinez-block;
        background-color: #434E59;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 6px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export default CustomRadioButton;
