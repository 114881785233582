import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from "react-ga4";

import Users from './Users/Users';
import Customers from './Customers/Customers';
import Stations from './Stations/Stations';
import Products from './Products/Products';
import Vehicles from './Vehicles/Vehicles';
// import Roles from './Roles/Roles';
import Dispensers from './Dispensers/Dispensers';
import Services from './Services/Services';
import Settings from './Settings/Settings';
import Authorizations from './Authorizations/Authorizations';
import EmailVerification from './Authentication/EmailVerification';
import PasswordReset from './Login/PasswordReset';
import QrTagAdmin from './Login/QrTagAdmin';
import BusinessIntelligence from './BusinessIntelligence/BusinessIntelligence';

const ContentLayout = styled(Layout)`
    padding: 24px;
`

const Content = () => {

    const { authData } = useSelector(state => state.entities.authentication);
    let location = useLocation();

    useEffect(() => {
        const section = location.pathname.slice(1)
        const userExternalId = authData.user.external_id

        if (section !== '') {
            ReactGA.event(`${section}_page_view`, {"by_external_id": userExternalId})
        }
    }, [location])

    return (
        <ContentLayout>
            <Routes>
                <Route index element={<Services />} />
                <Route path="/users" element={<Users />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/stations" element={<Stations />} />
                <Route path="/products" element={<Products />} />
                <Route path="/vehicles" element={<Vehicles />} />
                <Route path="/dispensers" element={<Dispensers />} />
                <Route path="/business_intelligence" element={<BusinessIntelligence />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/services" element={<Services />} />
                <Route path="/authorizations" element={<Authorizations />} />
                <Route path="/email_verification/:token" element={<EmailVerification />} />
                <Route path="/password_reset" element={<PasswordReset />} />
                <Route path="/qr_admin" element={<QrTagAdmin />} />
                <Route
                path="*"
                element={<Navigate to="/" />}
                />
            </Routes>
        </ContentLayout>
    );
}

export default Content;
