import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';
import CustomTable from '../Custom/CustomTable';
import CustomCheckbox from '../Custom/CustomCheckbox';
import 'antd/dist/antd.css';


const { Text } = Typography;

const CreateGroupForm = (props) => {
    const { list } = useSelector(state => state.entities.vehicles);
    
    const vehiclesCascade = list.map(vehicle => ({
        label:  `#${vehicle.unit_code} ${vehicle.vehicle_model_name} ${vehicle.year} ${vehicle.vehicle_brand_name}`,
        value: vehicle.id
    }));

    const { vehicles, id } = props.form.getFieldsValue();

    let vehiclesGroups = [];
    if (vehicles) {
        list.forEach(item => {
            vehicles.forEach(id => {
                if (id === item.id) {
                    vehiclesGroups.push(item);
                }
            });
        });
    }

    const columns = [

        {
            title: 'Truck number',
            dataIndex: 'unit_code',
            id: 'unit_code',
            render: (unit_code) => <Text> #{unit_code} </Text>
        },
        {
            title: 'Model',
            dataIndex: 'vehicle_model_name',
            id: 'vehicle_model_name',
            render: (vehicle_model_name, record) => <Text strong>  {vehicle_model_name} {record.year} </Text>
        },
        {
            title: 'Brand',
            dataIndex: 'vehicle_brand_name',
            id: 'vehicle_brand_name',
            render: (vehicle_brand_name) => <Text> {vehicle_brand_name} </Text>
        },
    ]


    return (
         <Fragment>
            <CustomForm
                form={props.form}
                onFinish={props.onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <CustomFormItem
                            label="id"
                            name="id"
                            hidden={true}
                        >
                            <CustomTextInput />
                        </CustomFormItem>

                        <CustomFormItem
                            label="manager"
                            name="manager"
                            hidden={true}
                        >
                            <CustomTextInput />
                        </CustomFormItem>

                        <Row gutter={16}>
                            <Col xs={24}>
                                <CustomFormItem
                                    name="name"
                                    rules={[{
                                        required: true,
                                        message: 'Please input the name'
                                    }]}
                                >
                                    <CustomTextInput
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        disabled={props.isDetailsOnly}
                                    />
                                </CustomFormItem>
                            </Col>
                        </Row>
        
                        <CustomFormItem
                            name="vehicles"
                            rules={[{
                                required: true,
                                message: 'Please select a vehicles'
                            }]}
                        >
                            <CustomMultipleSelect
                                label='Vehicles'
                                fieldName='vehicles'
                                form={props.form}
                                options={vehiclesCascade}
                                disabled={props.isDetailsOnly}
                            />
                        </CustomFormItem>

                        <CustomFormItem
                            name="tax_excluded"
                            valuePropName="checked"
                            initialValue={false}
                        >
                            <CustomCheckbox
                                disabled={props.isDetailsOnly}
                            >
                                Exclude this fleet in the tax credit
                            </CustomCheckbox>
                        </CustomFormItem>
                    </Col>
                </Row>
            </CustomForm>
            {id ? <CustomTable
                columns={columns}
                style={{ padding: '0px' }}
                dataSource={vehiclesGroups}
                rowKey='id'
            />
                : null
            }
   
        </Fragment>
    );
}

export default CreateGroupForm;
