import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from 'antd';
import {  tagsDispenser, updateTag } from '../../store/dispensers';
import CreateModal from '../Custom/CreateModal';
import CustomTable from '../Custom/CustomTable';
import IconButton from '../Custom/IconButton';
import ConfirmModal from '../Custom/ConfirmationModal';
import SvgDeleteIcon from '../icons/DeleteIcon';


const { Text } = Typography;

const TagsDispenser = (props) => {

    const { t } = props;
    const titleModal = "Tags Dispenser";
    const [isModalVisible, setIsModalVisible] = useState(false);

    const dispatch = useDispatch();
    const { tags, loadingTags } = useSelector(state => state.entities.dispensers);
    const { config } = useSelector(state => state.entities.authentication);

    // console.log('CHECANDO ESTADO:', tags);

    const columns = [
        {
            title: 'Number Tag',
            dataIndex: 'tag_name',
            id: 'tag_name',
            render: (name) => <Text strong style={{ color: config.primaryColor }}> {name} </Text>
        },
        {
            title: 'Registered User',
            dataIndex: 'user_name',
            id: 'user_name',
            key: 'user_name',
            render: (user) => <Text >{user} </Text>
        },
         {
            title: 'Actions',
            dataIndex: 'id',
            key: 'tag_id',
            render: (id, record) =>  <IconButton 
                                        hover_color='mdc-icon-button__delete'
                                        onClick={() => ConfirmModal({
                                            "onConfirmedDeletion": changeStatusTag,
                                            "moduleName": "Tag",
                                            "recordId": record.tag_id,
                                            "name": record.tag_name
                                        })
                                        }
                                        icon={<SvgDeleteIcon/>}
                                    />
                       
        }
    ];
 

    const showModal = () => {
        // console.log('ssss');
        // const car = list.map(function(dispenser) {
        //     return dispenser.users
        // });

        // const nuevo = [].concat.apply([], car);
        // console.log(nuevo);

        // console.log(props.dispenser.users);
        dispatch(tagsDispenser(props.dispenser.id))
        setIsModalVisible(true);
        // console.log('tags antes del delete'tags);
    };


    const changeStatusTag = (tag) => {
        // console.log(tag, 'DELETE')
        let data = { "is_active": false }
        // console.log(tags.users.filter(user => user.tag_id != tag));
        dispatch(updateTag(tag, data))
        // console.log(tags);
    };
    

    const handleCancel = () => {
        setIsModalVisible(false);
    }


    return (
        <Fragment>
            <Button type="text" onClick={showModal}>
                {t('tags_list')}
            </Button>
            <CreateModal 
                title={titleModal}
                width={1000} 
                visible={isModalVisible} 
                onCancel={handleCancel}
                cancelText={"Close"}
            >
                {
                    props.disepnser ? props.disepnser.name : null
                }
                <CustomTable columns={columns} dataSource={tags.users} rowKey='id' loading={loadingTags} />
            </CreateModal>

        </Fragment>
    );
}

export default TagsDispenser;
