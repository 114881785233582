import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/authorizations/';
const slice = createSlice({
    name: 'authorizations',
    initialState: {
        list: [],
        errorList: [],
        userList: [],
        loading: false,
        lastFetch: null,
        loadingAction: false,
    },
    reducers: {
        authorizationsRequested: (authorizations, action) => {
            authorizations.loading = true;
        },
        authorizationsRequestFailed: (authorizations, action) => {
            authorizations.loading = false;
        },
        authorizationsReceived: (authorizations, action) => {
            authorizations.list = action.payload.data;
            authorizations.loading = false;
            authorizations.errorList = []
        },
        authorizationsAddedRequest: (authorizations, action) => {
            authorizations.loadingAction = true;
        },
        authorizationsAdded: (authorizations, action) => {
            action.payload.data.success.forEach(element => {
                authorizations.list.unshift(element);
            });
            authorizations.errorList = action.payload.data.errors
            authorizations.loadingAction = false;
        },
        authorizationsAddedRequestFailed: (authorizations, action) => {
            authorizations.loadingAction = false;
        },
        authorizationUsersReceived: (authorizations, action) => {
            authorizations.userList = action.payload.data;
            authorizations.loading = false;
        },
        forwardEmailNotificationRequested: (authorizations, action) => {
            authorizations.loadingAction = true;
        },
        forwardEmailNotificationReceived: (authorizations, action) => {
            authorizations.loadingAction = false;
        },
        forwardEmailNotificationFailed: (authorizations, action) => {
            authorizations.loadingAction = false;
        },
        authorizationEnptyErrors: (authorizations, action) => {
            authorizations.errorList = []
        },
        codeInactiveRequested: (authorizations, action) => {
            authorizations.loadingAction = true;
        },
        codeInactiveReceived: (authorizations, action) => {
            authorizations.loadingAction = false;
        },
        codeInactiveFailed: (authorizations, action) => {
            authorizations.loadingAction = false;
            authorizations.errorList = []
        },
        authorizationEnptyErrors: (authorizations, action) => {
            authorizations.errorList = []
        },
    }
});

export default slice.reducer;
export const loadAuthorizations = () => apiCallBegan({
    url,
    onStart: slice.actions.authorizationsRequested.type,
    onSuccess: slice.actions.authorizationsReceived.type,
    onError: slice.actions.authorizationsRequestFailed.type
});

export const addAuthorization = authorization => apiCallBegan({
    url,
    method: 'post',
    data: authorization,
    onStart: slice.actions.authorizationsAddedRequest.type,
    onSuccess: slice.actions.authorizationsAdded.type,
    onError: slice.actions.authorizationsAddedRequestFailed.type
});

export const loadAuthorizationUsers = () => apiCallBegan({
    url: `${url}users/`,
    onStart: slice.actions.authorizationsRequested.type,
    onSuccess: slice.actions.authorizationUsersReceived.type,
    onError: slice.actions.authorizationsRequestFailed.type
});

export const forwardEmailNotification = (auth) => apiCallBegan({
    url: `${url}forward_authorization_code/`,
	method: 'post',
	data: auth,
    onStart: slice.actions.forwardEmailNotificationRequested.type,
    onSuccess: slice.actions.forwardEmailNotificationReceived.type,
    onError: slice.actions.forwardEmailNotificationFailed.type
});

export const inactiveUserCode= (auth) => apiCallBegan({
    url: `${url}inactive_user_code/`,
	method: 'post',
	data: auth,
    onStart: slice.actions.codeInactiveRequested.type,
    onSuccess: slice.actions.codeInactiveReceived.type,
    onError: slice.actions.codeInactiveFailed.type
});

export const { authorizationEnptyErrors } = slice.actions;