import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Form } from 'antd';
import { loadfleets, deletefleet, addFleet, updatefleet } from '../../store/fleets';
import CreateModal from '../Custom/CreateModal';
import CustomTable from '../Custom/CustomTable';
import IconButton from '../Custom/IconButton';
import ConfirmModal from '../Custom/ConfirmationModal';
import SvgDeleteIcon from '../icons/DeleteIcon';
import CreateGroupForm from './CreateGroupForm';
import CustomButton from '../Custom/CustomButton';


const { Text } = Typography;

const ListGroups = (props) => {
    
    const titleModal = "List of groups";
    const { translationFunction } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    
    const [titleModalForm, setTitleModalForm] = useState("Create Group");
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFormVisible, setIsModalFormVisible] = useState(false);

    const { list, loading, errors, loadingAction } = useSelector(state => state.entities.fleets);
    const { config, user } = useSelector(state => state.entities.authentication);

    let groupsLength = list.length;

    let groupsList = list.map(group => ({
        ...group,
        'index': groupsLength--
    }));


    const columns = [
        {
            title: 'Number',
            dataIndex: 'index',
            id: 'index',
            render: (index) => <Text strong > #{index}  </Text>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name) => <Text t strong style={{ color: config.primaryColor }} >{name}</Text>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModalForm(record, true);
                    }
                }
            },
        },
        {
            title: 'Vehicles',
            dataIndex: 'vehicles',
            key: 'vehicles',
            render: (vehicles) => <Text >{`${vehicles ? vehicles.length : 0}`}</Text>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModalForm(record, true);
                    }
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
             render: (id, record) => (
                 <IconButton
                     hover_color='mdc-icon-button__delete'
                     onClick={() => ConfirmModal({
                        "onConfirmedDeletion": deleteGroup,
                        "moduleName": "Groups",
                        "recordId": record.id,
                        "name": record.name
                    })
                    }
                    icon={<SvgDeleteIcon/>}
                />
            )
                       
        }
    ];
 

    const showModal = () => {
        dispatch(loadfleets());
        setIsModalVisible(true);
    };


    const deleteGroup = (group) => {
        dispatch(deletefleet(group));
    };
    

    const handleCancel = () => {
        setIsModalVisible(false);
    }
    

    /**
     * Form Group
     */

    const resetForm = () => {
        form.resetFields();
    };
    
    const handleFormCancel = () => {
        setIsModalFormVisible(false);
        resetForm();
    }

    const showModalForm = (group = [], isDetails = false) => {
        let newModalTitle = "Create Group";
        if (group.id) {
            form.setFieldsValue(group);
            newModalTitle = isDetails ? "Group Detail" : "Edit Group";
        }
        setTitleModalForm(newModalTitle);
        setIsDetailsOnly(isDetails);
        setIsModalFormVisible(true);

    };
    
    
    useEffect(() => {
        form.setFields(errors);
        if (errors.length === 0) {
            form.resetFields();
            setIsModalFormVisible(false);
        }
    }, [form, errors]);


     const handleSave = (values) => {
         if (!isDetailsOnly) {
             if (!values.id) {
                 if (!values.manager) {
                     values['manager'] = user.id
                 }
                 dispatch(addFleet(values));
             } else {
                 dispatch(updatefleet(values));
             }
         } else {
            setIsDetailsOnly(false);
            setTitleModalForm("Edit Group");
         }
    }


    return (
        <Fragment>
            <CustomButton
            className='mr-3'
                style={{
                    width: '150px',
                    height: '40px',
                    float: 'right',
                    'background-color': 'white'                
                }}
                onClick={showModal}>
                {translationFunction('groups_button')}
            </CustomButton>
            
            <CreateModal
                title={titleModal}
                width={1000}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                afterClose={() => props.groupsFormOnClose()}
            >
                <CustomButton
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={showModalForm}
                >
                    {translationFunction('create_group_button')}
                </CustomButton>

                <CustomTable
                    columns={columns}
                    dataSource={groupsList}
                    rowKey='id'
                    loading={loading}
                />
            </CreateModal>

            <CreateModal
                title={titleModalForm}
                visible={isModalFormVisible} 
                onCancel={handleFormCancel}
                cancelText={"Cancel"}
                okText={isDetailsOnly ? 'Edit' : 'Save'}
                okButtonProps={{ onClick: form.submit, loading: loadingAction }}
                
            >
            <CreateGroupForm
                    form={form}
                    getContainer={false}
                    isDetailsOnly={isDetailsOnly}
                    onFinish={handleSave}
                />
            </CreateModal>

        </Fragment>
    );
}

export default ListGroups;
