import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Labels = () => {
    const { t } = useTranslation(["volume_units", "currency"]);
    const { config } = useSelector(state => state.entities.authentication);

    const { volume_unit, currency } = config;

    /**
     * labels type definition
     * @typedef {Object} labels
     * @property {string} upper - Label in upper case.
     * @property {string} abbreviated - Abbreviated label.
     * @property {string} distance - Distance label equivalent.
     * @property {string} plural - Plural of the label.
     */
    const labels = (context) => {
        return {
            upper: context().toUpperCase(),
            abbreviated: context({context: "abbreviated"}),
            plural: context({count: 2}),
            distance: context({context: "distance"}),
        }
    }

    /**
     * Returns the volume label
     * @returns {labels}
     */
    function getVolumeLabel() {
        let context = options => t(volume_unit, {ns: "volume_units", ...options});
        
        return labels(context)
    }

    /**
     * Returns the currency label
     * @returns {labels}
     */
    function getCurrencyLabel() {
        let context = options => t(currency, {ns: "currency", ...options});

        return labels(context)
    }

    return {
        getVolumeLabel,
        getCurrencyLabel
    }
}

export default Labels;
