import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/services/';
const slice = createSlice({
    name: 'services',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        reports: {},
        loadingReport: false,
        servicesVehicle: [],
        loadingVehicle: false,
        groups: [],
        loadingGroups: false
    },
    reducers: {
        servicesRequested: (services, action) => {
            services.loading = true;
        },
        servicesRequestFailed: (services, action) => {
            services.loading = false;
        },
        servicesReceived: (services, action) => {
            services.list = action.payload.data;
            services.loading = false;
        },
        servicesReportRequested: (services, action) => {
            services.loadingReport = true;
        },
        servicesReportRequestFailed: (services, action) => {
            services.loadingReport = false;
        },
        servicesReportReceived: (services, action) => {
            services.reports = action.payload.data;
            services.loadingReport = false;
        },
        servicesVehicleRequested: (services, action) => {
            services.loadingVehicle = true;
            services.servicesVehicle = [];
        },
        servicesVehicleRequestFailed: (services, action) => {
            services.loadingVehicle = false;
        },
        servicesVehicleReceived: (services, action) => {
            services.servicesVehicle = action.payload.data;
            services.loadingVehicle = false;
            let x = services.groups.map(item => {
                let veh = item.vehicles.map(vehicle => {
                    if (vehicle.id === action.payload.params.id) {
                        vehicle['services'] = action.payload.data
                    }
                    return vehicle
                })
                item['vehicles'] = veh;
                return item
            });
            services.groups = x;
        
        },
        fleetsReportsRequested: (services, action) => {
            services.loadingGroups = true;
        },
        fleetsReportsReceived: (services, action) => {
            services.groups = action.payload.data;

            let groups = action.payload.data.map(element => {
                let vehicleItem = element.vehicles.map(vehicle => {
                    vehicle['services'] = []
                    return vehicle
                })
                element['vehicles'] = vehicleItem;

                return element
            });
            groups = groups.sort((a, b) => (a.name > b.name ? 1 : -1));
            services.groups = groups;
            services.loadingGroups = false;
        },
        fleetsReportsRequestFailed: (services, action) => {
            services.loadingGroups = false;
        },
    }
});

export default slice.reducer;
export const loadServices = (start, end, offset) => apiCallBegan({
    url: start ? `${url}?start_date=${start}&end_date=${end}&offset=${offset}` : `${url}?offset=${offset}`,
    onStart: slice.actions.servicesRequested.type,
    onSuccess: slice.actions.servicesReceived.type,
    onError: slice.actions.servicesRequestFailed.type
});

export const loadServicesReports = (start, end, offset) => apiCallBegan({
    url: start ? `${url}reports/?start_date=${start}&end_date=${end}&offset=${offset}` :`${url}reports/?offset=${offset}`,
    onStart: slice.actions.servicesReportRequested.type,
    onSuccess: slice.actions.servicesReportReceived.type,
    onError: slice.actions.servicesReportRequestFailed.type
});

export const loadServicesByVehicle = (start, end, id, offset) => apiCallBegan({
    url: start ? `/vehicles/${id}/services/?start_date=${start}&end_date=${end}&offset=${offset}` : `/vehicles/${id}/services/?offset=${offset}`,
    onStart: slice.actions.servicesVehicleRequested.type,
    onSuccess: slice.actions.servicesVehicleReceived.type,
    onError: slice.actions.servicesVehicleRequestFailed.type,
    params: {"id": id}
});


export const loadfleetsReports = (start, end, offset) => apiCallBegan({
    url: `/fleets/reports/?start_date=${start}&end_date=${end}&offset=${offset}`,
    onStart: slice.actions.fleetsReportsRequested.type,
    onSuccess: slice.actions.fleetsReportsReceived.type,
    onError: slice.actions.fleetsReportsRequestFailed.type
});
