import * as React from "react";

function SvgBusIcon({ title, titleId, ...props }) {
  return (
  <svg id="Grupo_14961" data-name="Grupo 14961" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
  <path id="Trazado_13600" data-name="Trazado 13600" d="M0,0H24V24H0Z" fill="none"/>
  <circle id="Elipse_178" data-name="Elipse 178" cx="2" cy="2" r="2" transform="translate(4 15)" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <circle id="Elipse_179" data-name="Elipse 179" cx="2" cy="2" r="2" transform="translate(16 15)" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <path id="Trazado_13601" data-name="Trazado 13601" d="M4,17H2V6A1,1,0,0,1,3,5H17c2.761,0,5,3.134,5,7v5H20m-4,0H8" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <path id="Trazado_13602" data-name="Trazado 13602" d="M16,5l1.5,7H22" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <line id="Línea_268" data-name="Línea 268" x2="15" transform="translate(2 10)" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <line id="Línea_269" data-name="Línea 269" y2="5" transform="translate(7 5)" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  <line id="Línea_270" data-name="Línea 270" y2="5" transform="translate(12 5)" fill="none" stroke="#ca61d8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </svg>

  );
}

export default SvgBusIcon;
