import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import CustomTextInput from './CustomTextInput';
import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
import CustomMaterialMenuItem from './CustomMaterialMenuItem';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const CustomMultipleSelect = (props) => {
    const { config } = useSelector(state => state.entities.authentication);
    const [selectedItems, setSelectedItems] = useState([]);
    const [dispensers, setDispensers] = useState([]);


    useEffect(() => {
        let formValues = props.form.getFieldsValue();
        let items = formValues[props.fieldName];

        setSelectedItems(typeof items === 'object' ? items : []);

        let dispensers_list = []
        props.options.forEach(dispenser => {
            if (!dispenser.disabled) {
                let item = { 
                    label: dispenser.label,
                    value: dispenser.value,
                    disabled: dispenser.disabled
                }

                dispensers_list.push(item)
            }    
        });
    
        let x = {label: "Select all", value: "all", disabled: false};
        let no_password_users = {label: "All Users have active Password ID", value: "none", disabled: true};

         if (dispensers_list.length > 1) {
            dispensers_list.unshift(x);
         }

         if (dispensers_list.length == 0 && props.className == "password_id_user_select") {
            dispensers_list.unshift(no_password_users);
         }

        setDispensers(dispensers_list);
    }, [props]);

    const handleChangeItems = (event) => {

        if (event.target.value.includes("all")) {
            return;
        }
        const { target: { value }, } = event;

        let items = typeof value === 'string' ? value.split(',') : value;

        items = items.filter(option => option != 'all');

        if (props.onChangeUsers) {
            props.onChangeUsers(props.options.filter(user => {
                return items.includes(user.value) ? user.email : "";
            }));
        }

        setSelectedItems(items);
        props.form.setFieldsValue({ [props.fieldName]: items, });

    };

    const handleRemoveItem = (value) => {

        let newSelectedItems = value == "all" ? [] : selectedItems.filter(item => item !== value && item != "all")

        if (props.onChangeUsers) {
            props.onChangeUsers(props.options.filter(user => {
                return newSelectedItems.includes(user.value) ? user.email : "";
            }));
        }

        setSelectedItems(newSelectedItems);
        props.form.setFieldsValue({ [props.fieldName]: newSelectedItems });

    }

    const handleAll = () => {

        let selectedDisabledOptions = [];
        // Obtener todas las opciones disponibles
        let allOptions = props.options.filter(option => {
            if (option.disabled && selectedItems.indexOf(option.value) != -1) {
                selectedDisabledOptions.push(option.value)
                return option.value
            }
            return !option.disabled;
        }).map(a => a.value);
        // Comparar opciones disponibles con las seleccionadas
        let items = selectedItems.length == allOptions.length ? selectedDisabledOptions : allOptions

        if (props.onChangeUsers) {
            props.onChangeUsers(props.options.filter(user => {
                return items.includes(user.value) ? user.email : "";
            }));
        }

        setSelectedItems(items);
        props.form.setFieldsValue({ [props.fieldName]: items });
    }

    return (
        <CustomTextInput
            label={`${props.label}`}
            size="small"
            select
            fullWidth
            SelectProps={{
                labelId: `${props.fieldName}-label`,
                id: `${props.fieldName}`,
                multiple: true,
                value: selectedItems,
                disabled: props.disabled,
                onChange: handleChangeItems,
                renderValue: (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                            let option = props.options.find(option => (
                                option.value === value
                            ));
                            if (option === undefined)
                                return
                            return <Chip
                                className='mt-1'
                                key={option.value}
                                label={option.label}
                                sx={[{
                                    backgroundColor: `${config.primaryColor}66`,
                                }]}
                                onDelete={props.disabled ? false : () => handleRemoveItem(value)}
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                                />
                            })
                        }
                    </Box>
                )
            }}
        >
            {dispensers.map(function(option) { 
                return(
                <CustomMaterialMenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    onClick={option.value == "all" ? () => handleAll() : ''}
                    divider={true}
                >
                    {`${option.label} ${option.description ? option.description : '' }`}
                </CustomMaterialMenuItem>
                )
            })}
        </CustomTextInput>
    );
}

export default CustomMultipleSelect;