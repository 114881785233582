import React, { useState, Suspense } from 'react';
import { Layout, Row, Col, Form, Typography,Input, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import CustomButton from '../Custom/CustomButton';
import CustomForm from '../Custom/CustomForm';
import CustomLink from '../Custom/CustomLink';
import styled from 'styled-components';
import SvgPadlock from '../icons/Padlock';
import CustomTextInput from '../Custom/CustomTextInput';
import './Login.css'
import { useDispatch , useSelector} from 'react-redux';
import { loadLogin } from '../../store/authentication';
import { Link, Route, Routes, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Content, Header } = Layout;

const Img = styled.img`
    width: 90%;
`;

const StyledColContainer = styled(Col)`
    background-color: #FFF;
    box-shadow: 0px 3px 10px #65C0C458;
`;

const Login = () => {
    const { t, i18n } = useTranslation('login');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [email, setEmail] = useState(null);
    const [language, setLanguage] = useState(i18n.languages[0]);
    const primaryColor = '#65C0C4';
    const mainContainerStyle = {marginTop: '10%'};
    const textCenteredStyle = {textAlign: 'center'};
    const fullWidthStyle = {width: '100%'};
    const buttonStyle = {minHeight: '70px'};
    let { loading  } = useSelector(state => state.entities.authentication);

    const handleSave = () => {
        let data = form.getFieldsValue();
        setEmail(data.email)
        let total = count + 1;
        if (data.email === email) {
            setCount(total)
        } else {
            setCount(1)
            total = 1
        }
        
        window.localStorage.setItem("triggerLoginAnalyticEvent", true)
        dispatch(loadLogin(data.email, data.password, total));
    }

    const changeLenguage = () => {
        const selectedLanguage = language === "en" ? "es" : "en";
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        window.localStorage.setItem('language', selectedLanguage);
    }

    const { REACT_APP_API_BUCKER_LOGOS } = process.env;

    let subdomain = window.location.host.split('.')[0];
    if (subdomain.includes("localhost") || subdomain.includes("dashboard") ) {
        subdomain = 'app'
    }
    
    let url = `${REACT_APP_API_BUCKER_LOGOS}${subdomain}`

    return (
        <>
        <Row justify='center'>
            <StyledColContainer xs={20} sm={16} md={10}>
                <Col className='mb-5' offset={4} span={16} style={mainContainerStyle}>
                    <Col offset={7} span={12}>
                        {
                            location.pathname.includes("fleet") ? <Img src={`${url}/logoLoginFleet`} alt="logo" />
                                :  <Img src={`${url}/logoLogin`} alt="logo" />
                        }
                    </Col>
                    <Row className="margin-login flex items-center justify-center">
                        <Col span={7}>
                            <hr className="divider"/>
                        </Col>
                        <Col span={10} style={textCenteredStyle}>
                            <span className="font-semibold">
                                {t('title')}
                            </span>
                        </Col>
                        <Col span={7}>
                            <hr className="divider"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={textCenteredStyle}>
                            <span className="font-subtitle">
                                {t('welcome_message')}
                            </span>
                        </Col>
                    </Row>
                    <Row style={mainContainerStyle}>
                        <CustomForm 
                            form={form}
                            style={fullWidthStyle}
                            onFinish={handleSave}
                        >
                            <CustomForm.Item name="email" rules={[{ type: 'email', required: true, message: t('inputs.email.message')}]}>
                                      <CustomTextInput
                                            id="email"
                                            label={t('inputs.email.place_holder')}
                                            variant="outlined"
                                            size="small"
                                            required
                                            fullWidth
                                            maxLength={60}
                                        />
                            </CustomForm.Item>
                            <CustomForm.Item name="password" rules={[{ required: true, message: t('inputs.password.message') }]}>
                                    <CustomTextInput
                                        id="password"
                                        label={t('inputs.password.place_holder')}
                                        type="password"
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        maxLength={60}
                                    />
                            </CustomForm.Item>
                            <Row 
                                className='mb-3'
                                justify='end'
                            >
                                <CustomLink 
                                    to="/password_reset"
                                > 
                                    {t('restore_password')}
                                </CustomLink>
                            </Row>
                            <CustomForm.Item>
                                <CustomButton type="primary"
                                    htmlType="submit"
                                    loginColor={location.pathname.includes("fleet") ? 'fleet' : 'station' }
                                    style={buttonStyle}
                                    block loading={loading}
                                >
                                    <span className="textButton">
                                        {t('title')}
                                    </span>
                                </CustomButton>
                            </CustomForm.Item>
                        </CustomForm>
                    </Row>
                </Col>
            </StyledColContainer>
        </Row>
        <Row style={{flexGrow: 2}} align="bottom">
            <CustomButton 
                className="m-2" 
                type="primary"
                loginColor={location.pathname.includes("fleet") ? 'fleet' : 'station' }
                shape="circle" 
                onClick={changeLenguage} 
            >
                {language}
            </CustomButton>
        </Row>
        </>
    );
}

export default Login;
