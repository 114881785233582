import React, { useState, useEffect, Fragment } from 'react'
import { PermissionValidator } from '../shared/PermissionValidator';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Form, Typography } from 'antd';
import {
    loadCustomers,
    addCustomer,
    // deleteCustomer,
    updateCustomer,
    loadOwners,
    loadCustomersStations
} from '../../store/customers';
import CreateModal from '../Custom/CreateModal';
import CreateCustomerForm from '../Customers/CreateCustomerForm';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import ModuleHeader from '../Custom/ModuleHeader'
import IconButton from '../Custom/IconButton';
import SvgEditIcon from "../icons/EditIcon"
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomSearhInput from '../Custom/CustomSearchInput';
import CustomTag from '../Custom/CustomTag';
import { STATUS } from '../constants';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Customers = () => {
    const MODULE_NAME = 'customer';
    const { t } = useTranslation([MODULE_NAME, 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels', 'tags']);
    const UPDATE_PERMISSION_CODENAME = `update_${MODULE_NAME}`;
    const CREATE_PERMISSION_CODENAME = `create_${MODULE_NAME}`;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailsOnly, setIsDetailsOnly] = useState(false);
    const [modalTitle, setModalTitle] = useState("Create Customer");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { hasResourcePermission, hasProfilePermission } = PermissionValidator();
    const { list, loading, loadingAction, stations } = useSelector(state => state.entities.customers);
    const fleetOwners = useSelector(state => state.entities.customers.ownersList);
    const userErrors = useSelector(state => state.entities.customers.errors);
    const { config } = useSelector(state => state.entities.authentication);
    let ownersList = [].concat(fleetOwners);
    let ownersNamesList = ownersList.map(manager => { return {name: `${manager.first_name} ${manager.last_name}`, id: manager.id}})
    let customersLength = list.length;
	const indexedList = list.map(e => ({...e, 'index': customersLength--}));

    let stationsList = stations.map(station => (
        {
            label: station.name,
            value: station.id,
            disabled: !station.is_active
        }
    ));

    useEffect(() => {
        dispatch(loadCustomers());
        dispatch(loadOwners());
        dispatch(loadCustomersStations());
    }, [dispatch]);

    useEffect(() => {
        form.setFields(userErrors);
        if (userErrors.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, userErrors]);

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
			render: (text, record, index) => <Text strong style={{ color: config.primaryColor }}> { record.index } </Text>
        },
        {
            title: t('customers.table.name'),
            dataIndex: 'name',
            key: 'name',
            render: (name) => <Text> {name} </Text>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: t('customers.table.stations'),
            dataIndex: 'stations',
            key: 'stations',
            render: (stations) => <Text> {stations.map(station => <li>{station.name}</li>)} </Text>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: t('customers.table.fleet_manager'),
            dataIndex: 'owners',
            key: 'owners',
            render: (ownerIds) => {
                let ownerId = ownerIds[0];
                let owner = ownersList.find(owner => owner.id === ownerId)
                return <Text>
                    {owner ? `${owner.first_name}  ${owner.last_name}` : ""}
                </Text>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: t('customers.table.status'),
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            render: (text, record) => {
                let status = record.is_active ? STATUS.active : STATUS.inactive;
                return <CustomTag colors={status.colors}>{status.label}</CustomTag>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: event => {
                        showModal(record, true);
                    }
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let hasUpdatePermissions = hasResourcePermission(UPDATE_PERMISSION_CODENAME);
                // let hasDeletePermissions = hasResourcePermission(DELETE_PERMISSION_CODENAME);
                return (
                    <Fragment>
                        <Space size="middle">
                            <IconButton
                                isVisible={hasUpdatePermissions}
                                hover_color='mdc-icon-button__edit'
                                onClick={() => showModal(record)}
                                icon={<SvgEditIcon />}
                            />
                        </Space>
                        {/* <Space size="middle">
                            <IconButton
                                isVisible={hasDeletePermissions}
                                hover_color='mdc-icon-button__delete'
                                onClick={() => ConfirmModal({
                                    "onConfirmedDeletion": onConfirmDeletion,
                                    "moduleName": MODULE_NAME,
                                    "recordId": record.id,
                                    "name": record.name
                                })}
                                icon={<SvgDeleteIcon />}
                            />
                        </Space> */}
                    </Fragment>
                );
            }
        }
    ];

    const isStationOwner = hasProfilePermission('create_station_manager');

	const showModal = (customer = [], detailsOnly = false) => {
		let newModalTitle = "Create Customer";

		if (customer.id) {
			let customerData = { ...customer };
			let { owners } = customer;
			customerData.owner = owners[0] ? owners[0] : null;
			let stations = customer.stations.map(station => { return station.id });
			customerData.stations = stations;
			form.setFieldsValue(customerData);
			newModalTitle = detailsOnly ? "Customer Detail" : "Edit Customer";
		}

		setIsDetailsOnly(detailsOnly);
		setModalTitle(newModalTitle);
		setIsModalVisible(true);
	};

	const [usersSerch, setUserSerch] = useState([]);
	const [searchValue, setSearch] = useState(null);
	
	const handleSearch = (event) => {
		let value = (event.target.value).trim().toLowerCase();

		let filtered = list.filter(element => {
			const { name, owners, stations } = element;
			return (
				name.toLowerCase().includes(value) ||
				owners.some(managerId => 
					ownersNamesList.some(manager => 
						manager.id === managerId && 
						manager.name.toLowerCase().includes(value) 
					)
				) ||
				stations.some(station => station.name.toLowerCase().includes(value))
			);
		});

		setSearch(value);
		setUserSerch(filtered);
	}

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const handleSave = (values) => {
		values.owners = [values.owner];
		delete values.owner;
		if (!isDetailsOnly) {
			if (values.id) {
				dispatch(updateCustomer(values));
			} else {
				dispatch(addCustomer(values));
			}

		} else {
			setIsDetailsOnly(false);
			setModalTitle("Edit Customer");
		}
	}

	return (
		<Fragment>
			<CreateModal
				getContainer={false}
				title={modalTitle}
				visible={isModalVisible}
				onCancel={handleCancel}
				width={800} okText={isDetailsOnly ? 'Edit' : 'Save'}
				okButtonProps={{
					onClick: form.submit, loading: loadingAction
				}}
			>
				<CreateCustomerForm
					onFinish={handleSave}
					form={form}
					fleetOwners={ownersList}
					isDetailsOnly={isDetailsOnly}
					stations={stationsList}
					isStationOwner={isStationOwner}
                    t={t}
				/>
			</CreateModal>
			<Row align="middle">
				<Col xs={12}>
					<ModuleHeader>
                        {t('header')}
					</ModuleHeader>
				</Col>
				<Col xs={12}>
					<CustomButton
						style={{
							float: 'right',
							display: hasResourcePermission(CREATE_PERMISSION_CODENAME) ? 'none' : 'none'
						}}
						type="primary"
						onClick={showModal}
					>
						New Customer
					</CustomButton>
				</Col>
				<Col xs={0}>
					<BreadcrumbCustom
						className='d-none'
						items={["Home", "Customer List"]}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={8}>
					<CustomSearhInput 
						placeholder={t('common_labels.search_bar')}
					onChange={handleSearch}
				/>
				</Col>
			</Row>
			<CustomTable
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
				dataSource={searchValue ? usersSerch : indexedList}
				columns={columns}
				rowKey='id'
				loading={loading}
			/>
		</Fragment>
	);
}

export default Customers;
