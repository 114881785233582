import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import MenuItem from '@mui/material/MenuItem';
import CustomCheckbox from '../Custom/CustomCheckbox';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';

const CreateCustomerForm = (props) => {

    const { t } = props;

    const statusOptions = [
        {
            name: 'Active',
            value: true
        },
        {
            name: 'Inactive',
            value: false
        }
    ];

    return (
        <CustomForm
            form={props.form}
            onFinish={props.onFinish}
            initialValues={{ is_active: true }}
        >
            <CustomForm.Item
                label="id"
                name="id"
                hidden={true}
            >
                <CustomTextInput />
            </CustomForm.Item>
            <CustomForm.Item
                name="name"
                rules={[{
                    required: true,
                    message: 'Please input the name'
                }]}
            >
                <CustomTextInput
                    id="name"
                    label="Name"
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    disabled={props.isDetailsOnly}
                    maxLength={140}
                />
            </CustomForm.Item>
            <CustomForm.Item
                name="phone"
                rules={[{
                    message: 'Please input a valid phone',
                    min: 10,
                    pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                }]}
            >
                <CustomTextInput
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type='tel'
                    disabled={props.isDetailsOnly}
                    inputProps={{
                        maxLength: 14,
                    }}
                />
            </CustomForm.Item>
            <CustomForm.Item
                name="owner"
                rules={[{ required: true, message: 'Please select a Fleet Manager' }]}
            >
                <CustomTextInput
                    id="fleet_manager"
                    label="Fleet Manager"
                    size="small"
                    select
                    fullWidth
                    placeholder="Please select a Fleet Manager"
                    disabled
                >
                    {props.fleetOwners.map(user => (
                        <MenuItem key={user.id} value={user.id} disabled={!user.is_active}>
                            {`${user.first_name} ${user.last_name}`}
                        </MenuItem>
                    ))}
                </CustomTextInput>
            </CustomForm.Item>
            <CustomForm.Item
                name="is_active"
            >
                <CustomTextInput
                    label="Status"
                    size="small"
                    select
                    fullWidth
                    disabled={props.isDetailsOnly}
                    placeholder="Please select a status"
                >
                    {statusOptions.map(option =>
                        <MenuItem
                            key={option.name}
                            value={option.value}
                        >
                            {t(option.name.toLowerCase(), {ns: 'tags'})}
                        </MenuItem>
                    )}
                </CustomTextInput>
            </CustomForm.Item>
            {props.isStationOwner ?
                <CustomForm.Item name="is_global" valuePropName="checked" >
                    <CustomCheckbox defaultChecked={false} disabled={true} >Is global</CustomCheckbox>
                </CustomForm.Item>
                : null
            }
            <CustomForm.Item
                name="stations">
                <CustomMultipleSelect
                    label='Stations'
                    fieldName='stations'
                    form={props.form}
                    options={props.stations}
                    disabled={props.isDetailsOnly}
                />
            </CustomForm.Item>
        </CustomForm>
    );
}

export default CreateCustomerForm;
