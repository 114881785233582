import * as React from "react";

function TransactionIcon({ title, titleId, ...props }) {
  return (
    <svg id="Grupo_15235" data-name="Grupo 15235" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <rect id="Rectángulo_17953" data-name="Rectángulo 17953" width="50" height="50" rx="3" fill="#3d80ea" opacity="0.101"/>
      <g id="vuesax_bulk_clipboard-export" data-name="vuesax/bulk/clipboard-export" transform="translate(5.086 5.671)">
        <g id="clipboard-export">
          <path id="Vector" d="M7.4,0H21.475a7.426,7.426,0,0,1,7.418,7.418V23.035a7.437,7.437,0,0,1-7.418,7.418H7.418A7.426,7.426,0,0,1,0,23.035V7.418A7.4,7.4,0,0,1,7.4,0Z" transform="translate(5.476 6.057)" fill="#3d80ea" opacity="0.397"/>
          <path id="Vector-2" data-name="Vector" d="M3.12,0h7.8a3.131,3.131,0,0,1,3.137,3.12V4.68a3.116,3.116,0,0,1-3.12,3.12H3.12A3.116,3.116,0,0,1,0,4.68V3.12A3.106,3.106,0,0,1,3.12,0Z" transform="translate(12.895 3.319)" fill="#3d80ea"/>
          <path id="Vector-3" data-name="Vector" d="M.361,10.322,8.194,2.489H6.219A1.254,1.254,0,0,1,4.974,1.245,1.254,1.254,0,0,1,6.219,0H11.2a1.254,1.254,0,0,1,1.245,1.245V6.223a1.245,1.245,0,0,1-2.489,0V4.248L2.12,12.081a1.242,1.242,0,0,1-1.759,0A1.252,1.252,0,0,1,.361,10.322Z" transform="translate(13.695 17.01)" fill="#3d80ea"/>
          <path id="Vector-4" data-name="Vector" d="M39.829,0H0V39.829H39.829Z" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>
  );
}

export default TransactionIcon;
