import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/permissions/';
const slice = createSlice({
    name: 'permissions',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        permissionsRequested: (permissions, action) => {
            permissions.loading = true;  
        },
        permissionsRequestFailed: (permissions, action) => {
            permissions.loading = false;
        },
        permissionsReceived: (permissions, action) => {
            permissions.list = action.payload.data;
            permissions.loading = false;
        },
        permissionAdded: (permissions, action) => {
            permissions.list.push(action.payload.data);
        }
    }
});

export default slice.reducer;
export const { permissionAdded } = slice.actions;

export const loadPermissions = () => apiCallBegan({
    url,
    onStart: slice.actions.permissionsRequested.type,
    onSuccess: slice.actions.permissionsReceived.type,
    onError: slice.actions.permissionsRequestFailed.type
});

export const addPermission = permission => apiCallBegan({
    url,
    method: 'post',
    data: permission,
    onSuccess: slice.actions.permissionAdded.type,
});