import React, { Suspense } from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import '../assets/css/App.scss'
import { selectToken } from '../store/authentication';
import { ThemeProvider } from "styled-components";

import Home  from '../pages/Home';
import LoginPage  from '../pages/LoginPage';
import OutsidePage  from '../pages/OutsidePage';

import QrTagAdmin from '../components/Login/QrTagAdmin';
import CustomSpinner from '../components/Custom/CustomSpinner';

const MainLayout = styled(Layout)`
    min-height: 100vh;
`

const App = () => {
    const token = useSelector(selectToken);
    const { fakeLoading } = useSelector(state => state.entities.authentication);

    const { config } = useSelector(state => state.entities.authentication);
    const path = window.location.pathname.slice(1);
    const outsidePaths = ['gstracker'];

    let page = (token) ? <Home /> : <LoginPage />;
    page = (outsidePaths.includes(path)) ? <OutsidePage /> : page;
    
    return fakeLoading 
        ? (
            <Spin 
                spinning={fakeLoading}
                size="large"
            >
            </Spin>
        )
        : (
            <MainLayout>
                <BrowserRouter>
                    <ThemeProvider theme={config}>
                        <Suspense fallback={<CustomSpinner size="large" />}>
                            {page}
                        </Suspense>
                    </ThemeProvider>
                </BrowserRouter>
            </MainLayout>
        )
}

export default App;
