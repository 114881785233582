import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/roles/';
const slice = createSlice({
    name: 'roles',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        loginRoles: [],
    },
    reducers: {
        rolesRequested: (roles, action) => {
            roles.loading = true;  
        },
        rolesRequestFailed: (roles, action) => {
            roles.loading = false;
        },
        rolesReceived: (roles, action) => {
            roles.list = action.payload.data;
            roles.loading = false;
        },
        roleAdded: (roles, action) => {
            roles.list.push(action.payload.data);
        },
        roleDeleted: (roles, action) => {
            roles.list = roles.list.filter(role => role.id !== action.payload.requestData);
        },
        roleUpdated: (roles, action) => {
            const index = roles.list.findIndex(role => role.id === action.payload.data.id);
            roles.list[index] = action.payload.data;
        },
        loginRolesReceived: (roles, action) => {
            roles.loginRoles = action.payload.data;
        }
    }
});

export default slice.reducer;
export const { roleAdded } = slice.actions;

export const loadRoles = () => apiCallBegan({
    url,
    onStart: slice.actions.rolesRequested.type,
    onSuccess: slice.actions.rolesReceived.type,
    onError: slice.actions.rolesRequestFailed.type
});

export const addRole = role => apiCallBegan({
    url,
    method: 'post',
    data: role,
    onSuccess: slice.actions.roleAdded.type,
});

export const deleteRole = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onSuccess: slice.actions.roleDeleted.type
});

export const updateRole = (role) => apiCallBegan({
    url: `${url}${role.id}/`,
    method: 'put',
    data: role,
    onSuccess: slice.actions.roleUpdated.type
});

export const partialUpdateRole = (role) => apiCallBegan({
    url: `${url}${role.id}/`,
    method: 'patch',
    data: role,
    onSuccess: slice.actions.roleUpdated.type
});

export const getLoginRoles = (role_id) => apiCallBegan({
    url: `${url}login_roles/${role_id}`,
    onSuccess: slice.actions.loginRolesReceived.type
});