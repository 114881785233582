import { Row, Col, Space, Form, Typography } from 'antd';
import CustomTag from './CustomTag';

const CustomExpandedTableRow = (props) => {

    const { element, status } = props;

    return (
        <div className='authRow'>
            <span className='column' />
            <span className='column'>
                {new Date(element.start_date).toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                    }).split(', ')[0]
                }
            </span> 
            <span className='column'>
                {new Date(element.end_date).toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                    }).split(', ')[0]
                }
            </span> 
            <span
                className='tag'
            >
                <CustomTag 
                    colors={status.colors}
                >
                    {status.label}
                </CustomTag>
            </span>
        </div>
    );
}

export default CustomExpandedTableRow;
