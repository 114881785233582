import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const ConfirmationModal = styled(Modal)`
`;

const { confirm } = ConfirmationModal;

const ConfirmModal = ({ 
    moduleName, 
    onConfirmedDeletion, 
    recordId, 
    name 
}) => {

    confirm({
        title: `Remove ${moduleName}`,
        icon: <WarningOutlined  style={{ color: '#ff4d4f' }} />,
        okText: `Remove`,
        okButtonProps: { type: "primary",  danger: true },
        content: `Are you sure to remove ${moduleName} ${name} permanently? this action cannot be undone.`,
        onOk(){
            onConfirmedDeletion(recordId)
        },
        onCancel() {},
        })
}


export default ConfirmModal;