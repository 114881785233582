import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/vehicles/';
const urlTracker = '/tracker/';

const slice = createSlice({
    name: 'vehicles',
    initialState: {
        list: [],
        managersList: [],
        drivers: [],
        gsTrackingDevices: [],
        loading: false,
        gsDevicesLoading: false,
        lastFetch: null,
        errors: [],
        loadingAction: false,
        externalIdList: [],
    },
    reducers: {
        vehiclesRequested: (vehicles, action) => {
            vehicles.loading = true;
        },
        vehiclesRequestFailed: (vehicles, action) => {
            vehicles.loading = false;
        },
        vehiclesReceived: (vehicles, action) => {
            vehicles.list = action.payload.data;
            vehicles.loading = false;
        },
        vehicleAddRequested: (vehicles, action) => {
            vehicles.loadingAction = true
        },
        vehiclesAddRequestFailed: (vehicles, action) => {
            let responseData = action.payload.data;
            vehicles.errors = [];
            for (const field in responseData) {
                vehicles.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            vehicles.loadingAction = false;
        },
        vehicleAdded: (vehicles, action) => {
            vehicles.list.unshift(action.payload.data);
            vehicles.loadingAction = false;
            vehicles.errors = [];
        },
        vehicleDeleted: (vehicles, action) => {
            vehicles.list = vehicles.list.filter(vehicle => vehicle.id !== action.payload.requestData);
            vehicles.loadingAction = false;
        },
        vehicleUpdated: (vehicles, action) => {
            const index = vehicles.list.findIndex(vehicle => vehicle.id === action.payload.data.id);
            vehicles.list[index] = action.payload.data;
            vehicles.loadingAction = false;
            vehicles.errors = [];
        },
        vehicleDriversReceived: (vehicles, action) => {
            vehicles.drivers = action.payload.data;
            vehicles.loadingAction = false;
        },
        managersReceived: (vehicles, action) => {
            vehicles.managersList = action.payload.data;
            vehicles.loadingAction = false;
        },
        gsDveicesRequested: (vehicles, action) => {
            //vehicles.loading = true;
        },
        gsDveicesRequestFailed: (vehicles, action) => {
            //vehicles.loading = false;
        },
        gsDveicesReceived: (vehicles, action) => {
            vehicles.gsTrackingDevices = action.payload.data;
            //vehicles.loading = false;
        },
        addGsDeviceRequested: (vehicles, action) => {
            vehicles.gsDevicesLoading = true;
        },
        addGsDeviceRequestFailed: (vehicles, action) => {
            vehicles.gsDevicesLoading = false;
        },
        addGsDeviceSuccess: (vehicles, action) => {
            vehicles.gsTrackingDevices.unshift(action.payload.data);
            vehicles.gsDevicesLoading = false;
            vehicles.errors = [];
        },
        externalIdRequested: (vehicles, action) => {
            vehicles.loadingAction = true;
        },
        externalIdReceived: (vehicles, action) => {
            vehicles.externalIdList = action.payload.data;
            vehicles.loadingAction = false;
        },
        externalIdFailed: (vehicles, action) => {
            vehicles.loadingAction = false;
        },
    }
});

export default slice.reducer;
export const loadVehicles = () => apiCallBegan({
    url,
    onStart: slice.actions.vehiclesRequested.type,
    onSuccess: slice.actions.vehiclesReceived.type,
    onError: slice.actions.vehiclesRequestFailed.type
});

export const addVehicle = vehicle => apiCallBegan({
    url,
    method: 'post',
    data: vehicle,
    onStart: slice.actions.vehicleAddRequested.type,
    onSuccess: slice.actions.vehicleAdded.type,
    onError: slice.actions.vehiclesAddRequestFailed.type
});

export const deleteVehicle = id => apiCallBegan({
    url: `${url}${id}`,
    method: 'delete',
    data: id,
    onSuccess: slice.actions.vehicleDeleted.type
});

export const updateVehicle = (vehicle) => apiCallBegan({
    url: `${url}${vehicle.id}/`,
    method: 'put',
    data: vehicle,
    onStart: slice.actions.vehicleAddRequested.type,
    onSuccess: slice.actions.vehicleUpdated.type,
    onError: slice.actions.vehiclesAddRequestFailed.type
});

export const loadManagers = () => apiCallBegan({
    url: `${url}managers`,
    onStart: slice.actions.vehicleAddRequested.type,
    onSuccess: slice.actions.managersReceived.type,
    onError: slice.actions.vehiclesRequestFailed.type
});

export const loadDrivers = () => apiCallBegan({
    url: `${url}drivers`,
    onStart: slice.actions.vehicleAddRequested.type,
    onSuccess: slice.actions.vehicleDriversReceived.type,
    onError: slice.actions.vehiclesRequestFailed.type
});

export const loadDriversByManager = managerId => apiCallBegan({
    url: `${url}drivers?manager=${managerId}`,
    onStart: slice.actions.vehicleAddRequested.type,
    onSuccess: slice.actions.vehicleDriversReceived.type,
    onError: slice.actions.vehiclesRequestFailed.type
});

/*
    Tracker URL
*/
export const loadGsDevices = () => apiCallBegan({
    url: `${urlTracker}`,
    onStart: slice.actions.gsDveicesRequested.type,
    onSuccess: slice.actions.gsDveicesReceived.type,
    onError: slice.actions.gsDveicesRequestFailed.type
});

export const addGsDevice = (data) => apiCallBegan({
    url: `${urlTracker}`,
    method: 'post',
    data: data,
    onStart: slice.actions.addGsDeviceRequested.type,
    onSuccess: slice.actions.addGsDeviceSuccess.type,
    onError: slice.actions.addGsDeviceRequestFailed.type
});

export const createExternalId = (values) => apiCallBegan({
    url: `${url}create_external_id/`,
    method: 'post',
    data: values,
    onStart: slice.actions.externalIdRequested.type,
    onSuccess: slice.actions.externalIdReceived.type,
    onError: slice.actions.externalIdFailed.type
});
