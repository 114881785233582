import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/fleets/';
const slice = createSlice({
    name: 'fleets',
    initialState: {
        list: [],
        loading: false,
        errors: [],
        loadingAction: false,
        reports: [],
        loadingReports: false,
    },
    reducers: {
        fleetsRequested: (fleets, action) => {
            fleets.loading = true;
        },
        fleetsReceived: (fleets, action) => {
            fleets.list = action.payload.data;
            fleets.loading = false;
        },
        fleetsRequestFailed: (fleets, action) => {
            fleets.loading = false;
        },

        fleetAddRequested: (fleets, action) => {
            fleets.loadingAction = true
        },
        fleetAdded: (fleets, action) => {
            fleets.list.unshift(action.payload.data);
            fleets.loadingAction = false;
            fleets.errors = [];
        },
        fleetsAddRequestFailed: (fleets, action) => {
            let responseData = action.payload.data;
            fleets.errors = [];
            for (const field in responseData) {
                fleets.errors.unshift({ 'name': field, 'errors': responseData[field] });
            }
            fleets.loadingAction = false;
        },
        fleetDeleted: (fleets, action) => {
            fleets.list = fleets.list.filter(fleet => fleet.id !== action.payload.requestData);
            fleets.loadingAction = false;
        },
        fleetUpdated: (fleets, action) => {
            const index = fleets.list.findIndex(fleet => fleet.id === action.payload.data.id);
            fleets.list[index] = action.payload.data;
            fleets.loadingAction = false;
            fleets.errors = [];
        },
        fleetsReportsRequested: (fleets, action) => {
            fleets.loadingReports = true;
            fleets.reports = [];
        },
        fleetsReportsReceived: (fleets, action) => {
            fleets.reports = action.payload.data;
            fleets.loadingReports = false;
        },
        fleetsReportsRequestFailed: (fleets, action) => {
            fleets.loadingReports = false;
        },
    }
});

export default slice.reducer;
export const loadfleets = () => apiCallBegan({
    url,
    onStart: slice.actions.fleetsRequested.type,
    onSuccess: slice.actions.fleetsReceived.type,
    onError: slice.actions.fleetsRequestFailed.type
});

export const addFleet = fleet => apiCallBegan({
    url,
    method: 'post',
    data: fleet,
    onStart: slice.actions.fleetAddRequested.type,
    onSuccess: slice.actions.fleetAdded.type,
    onError: slice.actions.fleetsAddRequestFailed.type
});

export const deletefleet = id => apiCallBegan({
    url: `${url}${id}/`,
    method: 'delete',
    data: id,
    onStart: slice.actions.fleetAddRequested.type,
    onSuccess: slice.actions.fleetDeleted.type,
    onError: slice.actions.fleetsAddRequestFailed.type
});

export const updatefleet = (fleet) => apiCallBegan({
    url: `${url}${fleet.id}/`,
    method: 'put',
    data: fleet,
    onStart: slice.actions.fleetAddRequested.type,
    onSuccess: slice.actions.fleetUpdated.type,
    onError: slice.actions.fleetsAddRequestFailed.type
});

export const loadfleetsReports = (start, end) => apiCallBegan({
    url: `${url}reports/?start_date=${start}&end_date=${end}`,
    onStart: slice.actions.fleetsReportsRequested.type,
    onSuccess: slice.actions.fleetsReportsReceived.type,
    onError: slice.actions.fleetsReportsRequestFailed.type
});
