import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "vehicleBrands",
    initialState: {
        list: [],
        loading: false,
    },
    reducers: {
        vehicleBrandsRequested: (vehicleBrands, action) => {
            vehicleBrands.loading = true;
        },
        vehicleBrandsReceived: (vehicleBrands, action) => {
            vehicleBrands.list = action.payload.data;
            vehicleBrands.loading = false;
        },
        vehicleBrandsRequestFailed: (vehicleBrands, action) => {
            vehicleBrands.loading = false
        }
    }
});

const {
    vehicleBrandsRequested,
    vehicleBrandsReceived, 
    vehicleBrandsRequestFailed
} = slice.actions;

export default slice.reducer;

const url = "/vehicle_brands"

export const loadVehicleBrands = () => (dispatch, getState) => {    
    dispatch(
        apiCallBegan({
            url,
            onStart: vehicleBrandsRequested.type,
            onSuccess: vehicleBrandsReceived.type,
            onError: vehicleBrandsRequestFailed.type
        })
    );
}

export const getVehicleBrands = createSelector(
    state => state.entities.vehicleBrands,
    vehicleBrands => vehicleBrands.list.filter(vehicleBrand => vehicleBrand.is_active)
)

