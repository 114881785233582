import * as React from "react";

function SvgEditIcon({ title, titleId, ...props }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="16" 
      height="16" 
      viewBox="0 0 17.634 25"
      {...props}
    >
      <g id="edit-3" transform="translate(-2 -1.879)">
        <path id="Trazado_11496" data-name="Trazado 11496" d="M12,20h7.817" transform="translate(-1.183 -2.25)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path id="Trazado_11497" data-name="Trazado 11497" d="M14.726,3.418a1.843,1.843,0,1,1,2.606,2.606L6.474,16.881,3,17.75l.869-3.474Z" transform="translate(0 0)" fill="none" stroke="#9eaab5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
    
  );
}

export default SvgEditIcon;
