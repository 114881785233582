import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "vehicleModels",
    initialState: {
        list: [],
        byBrand: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        vehicleModelsRequested: (vehicleModels, action) => {
            vehicleModels.loading = true;
        },
        vehicleModelsReceived: (vehicleModels, action) => {
            vehicleModels.list = action.payload.data;
            vehicleModels.loading = false;
            vehicleModels.lastFetch = Date.now()
        },
        vehicleModelsReceivedByBrand: (vehicleModels, action) => {
            vehicleModels.byBrand = action.payload.data;
            vehicleModels.loading = false;
            vehicleModels.lastFetch = Date.now()
        },
        vehicleModelsRequestFailed: (vehicleModels, action) => {
            vehicleModels.loading = false;
        }
    }
});

const {
    vehicleModelsRequested,
    vehicleModelsReceived,
    vehicleModelsReceivedByBrand,
    vehicleModelsRequestFailed
} = slice.actions;

export default slice.reducer;

const url = "/vehicle_models"

export const loadVehicleModels = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: url,
            onStart: vehicleModelsRequested.type,
            onSuccess: vehicleModelsReceived.type,
            onError: vehicleModelsRequestFailed.type
        })
    );
}

export const loadVehicleModelsByBrand = (id) => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: "vehicle_brands/" + id +"/vehicle_models/",
            onStart: vehicleModelsRequested.type,
            onSuccess: vehicleModelsReceivedByBrand.type,
            onError: vehicleModelsRequestFailed.type
        })
    );
}

export const getVehicleModels = createSelector(
    state => state.entities.vehicleModels,
    vehicleModels => vehicleModels.list.filter(vehicleModel => vehicleModel.is_active)
)

export const getVehicleModelsByBrands = (vehicleBrandId, vehicleModels) => {
    vehicleModels.filter(vehicleModel => vehicleModel.vehicle_brand === vehicleBrandId)
}