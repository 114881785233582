import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import { Row, Col, Form, Typography, Space, Modal } from 'antd';
import CreateModal from '../Custom/CreateModal';
import SvgSentEmail from '../icons/SentEmail';
import CreateAutorizationForm from './CreateAuthorizationForm';
import { addAuthorization, loadAuthorizations, loadAuthorizationUsers, authorizationEnptyErrors, forwardEmailNotification, inactiveUserCode} from '../../store/authorizations';
import moment from 'moment';
import CustomButton from '../Custom/CustomButton';
import CustomTable from '../Custom/CustomTable';
import CustomTag from '../Custom/CustomTag';
import IconButton from '../Custom/IconButton';
import ModuleHeader from '../Custom/ModuleHeader';
import InactiveAuthCodeIcon from '../icons/InactiveAuthCodeIcon'
import { STATUS } from '../constants';
import { PermissionValidator } from '../shared/PermissionValidator';
import CustomSearhInput from '../Custom/CustomSearchInput';
import CustomExpandedTableRow from '../Custom/CustomExpandedTableRow'
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Authorizations = () => {

    const { t } = useTranslation(['authorizations', 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    const floatRightStyle = {float: 'right'};

    const [selectedUsers, setSelectedUsers] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { hasResourcePermission } = PermissionValidator();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const users = useSelector(state => state.entities.authorizations.userList);
    const { list, loading, errorList, loadingAction } = useSelector(state => state.entities.authorizations);
    const { config } = useSelector(state => state.entities.authentication);
    let userEmails = [];
    const usersCascade = users.map(user => ({
        label: user.first_name + " " + user.last_name,
        value: user.id,
        disabled: !user.is_active || user.has_authorization,
        description: user.has_authorization ? '(This user has an authorization)' : '',
        email: user.email
    }));
    users.forEach(user => userEmails[user.id] = user.email);
    
    usersCascade?.sort((a, b) => a.label.localeCompare(b.label)); 

    /**
     * Sorting the authorizations list so the
     * active authorizations appears first
     */

    let sortedList = [...list]

    sortedList?.sort((a, b) => {
        return b.is_active - a.is_active
    })

    /**
     * Removing repeated values from the authorization list
     */

    let reducedList = [];

    sortedList?.forEach(element => {

        if (!reducedList.length) {
            reducedList.push(element) 
        } else {
            if(!reducedList.some(listElement => listElement.email === element.email)) {
                reducedList.push(element)
            }
        }

    })

    usersCascade?.sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        if (!loadingAction) {
            dispatch(loadAuthorizations());
            dispatch(loadAuthorizationUsers());
        }
    }, [dispatch, loadingAction /*, company*/]);

    useEffect(() => {
        form.setFields(errorList);
        if (errorList.length === 0) {
            form.resetFields();
            setIsModalVisible(false);
        }
    }, [form, errorList]);

    const columns = [
        {
            title: 'User',
            dataIndex: 'user_name',
            id: 'user_name',
            render: (user_name) => <Text> {user_name} </Text>
        },
        {
            title: 'Start date',
            dataIndex: 'start_date',
            id: 'start_date',
            render: (start_date) => <Text>{new Date(start_date).toLocaleString("en-US", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit"
			}).split(', ')[0]}</Text>
        },
        {
            title: 'Expiration date',
            dataIndex: 'end_date',
            id: 'end_date',
            render: (end_date) => <Text>{new Date(end_date).toLocaleString("en-US", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit"
			}).split(', ')[0]}</Text>
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            id: 'is_active',
            render: (text, record) => {
                let status = record.is_active ? STATUS.active : STATUS.expired;
                return <CustomTag colors={status.colors}>{status.label}</CustomTag>
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {

                if (!record.is_active) {
                    return
                }
                
                return (
                    <Fragment>
                        <Space size="middle" title={t('resend_authorization')}>
                            <IconButton
                                hover_color='mdc-icon-button__edit'
                                onClick={() => dispatch(forwardEmailNotification(record))}
                                icon={<SvgSentEmail/>}
                            />
                        </Space>
                        <Space size="middle" title={t('deactivate')}>
                            <IconButton
                                hover_color='mdc-icon-button__edit'
                                onClick={() => confirmDeactivation(record)}
                                icon={<InactiveAuthCodeIcon/>}
                            />
                        </Space>
                    </Fragment>
                );
            }
        }
    ];

    const resetForm = () => {
        form.resetFields();
    };

    const showModal = (vehicle = []) => {
        dispatch(loadAuthorizationUsers());
        form.isUpdate = true;
        setIsModalVisible(true);
    };

    const [usersSerch, setUserSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    
    const handleSearch = (event) => {
        let value = (event.target.value).trim().toLowerCase();

        let filtered = list.filter(element => {
            const { user_name } = element;
            return (user_name.toLowerCase().includes(value));
        });

        setSearch(value);
        setUserSerch(filtered);
    }

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch(loadAuthorizations());
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        resetForm();
        setSelectedUsers([]);
    }

    const handleSave = (values) => {
        let users_array = [];
        if (values.users !== undefined) {
            for (let userId of values.users) {
                users_array.push({
                    'id': userId,
                    'email': userEmails[userId],
                });
            }
            values.users = users_array;
        }
        // values.project_id = company;
        delete values.emails;
        values.renewal = values.renewal !== undefined;
        values.end_date = moment(values.end_date).format('yyyy-MM-DD');
        values.start_date = moment(values.start_date).format('yyyy-MM-DD');
        let date = new Date();
        let offset = date.getTimezoneOffset();
        values['offset'] = offset
        dispatch(addAuthorization(values));
        setSelectedUsers([]);
    }

    const onChangeUsers = (items) => {
        setSelectedUsers(items.map(user => user.email));
    }

    const closeModal = () => {
        dispatch(authorizationEnptyErrors());
    }

    const deactivateAuthorizationCode = (authorization) => {
        dispatch(inactiveUserCode(authorization));
    }
    
    const confirmDeactivation = (record) => {
        Modal.confirm({
            title: t('deactivation_modal.title'),
            content: t('deactivation_modal.content'),
            okText: t('deactivation_modal.ok_text'),
            cancelText: t('deactivation_modal.cancel_text'),
            onOk: () => deactivateAuthorizationCode(record)
        });
    };
    
    if( errorList.length > 0){
        CreateModal.error({
            title: t('deactivation_modal.users_error'),
            content: errorList.map(error => <li>{error.email}</li>),
            afterClose: closeModal,
            okButtonProps:{ style:{ color: '#ffffff', backgroundColor: config.primaryColor,
            borderRadius: '8px' }}
        });
    }

    return (
        <Fragment>
            <CreateModal
                title="Send new Password ID"
                okText="Save"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}
                okButtonProps={{
                    onClick: form.submit,
                    loading: loadingAction
                }}
            >
                <CreateAutorizationForm
                    form={form}
                    onFinish={handleSave}
                    users={usersCascade}
                    onChangeUsers={onChangeUsers}
                    selectedUsers={selectedUsers}
                />
            </CreateModal>
            <Row>
                <Col xs={12}>
                    <ModuleHeader>
                        {
                            hasResourcePermission('access_vehicle') 
                            ? t('header2')
                            : t('header1')
                        }
                    </ModuleHeader>
                </Col>
                <Col xs={12}>
                    <CustomButton
                        className={'create-button'}
                        onClick={showModal}
                        type="primary"
                        style={floatRightStyle}
                    >
                        {t('button')}
                    </CustomButton>
                </Col>
                <Col xs={0}>
                    <BreadcrumbCustom
                        items={["Home", "Emails list with PIN"]}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <CustomSearhInput 
                        placeholder="Search here..." 
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <CustomTable
                locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                tableLayout={"fixed"}
                columns={columns}
                dataSource={searchValue ? usersSerch : reducedList}
                rowKey='id'
                loading={loading || loadingAction}
                expandable={{
                    expandedRowRender: (record) => { 

                        let filteredList = list?.filter(element => {
                            return (
                                element.email === record.email &&
                                element.id !== record.id &&
                                !element.is_active
                            )
                        });

                        return filteredList && filteredList.map(element => {

                            let status = element.is_active ? STATUS.active : STATUS.expired;

                            return <CustomExpandedTableRow element={element} status={status} />
                        })
                    },
                    rowExpandable: (record) => { 
                        return sortedList?.some(element => {
                            return element.email === record.email &&
                            element.id !== record.id &&
                            !element.is_active
                        })
                    }
                }}
            />
        </Fragment>
    );
}

export default Authorizations;
