import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

const StyledTextInput = styled(TextField)( props => ({
    '& label': {
        fontFamily: 'MetropolisBold',
        marginTop: '10px'
    },

    '& label.Mui-focused': {
        color: props.theme.primaryColor,
        fontSize: '17px',
        borderRadius: '10px',
        marginTop: '0'
    },

    '& label.MuiFormLabel-filled': {
        marginTop: '0',
        fontSize: '17px',
    },

    '& .MuiInputLabel-root': {
        color: 'rgba(158, 170, 181, 1)',
    },

    '& .MuiOutlinedInput-root': {
        fontSize: '14px',
        fontFamily: 'MetropolisBold',
        paddingTop: '7px',
        paddingBottom: '7px',
        borderRadius: '7px',
        color: 'rgba(42, 50, 57, 0.9)',

        'input': {
            borderRadius: '7px'
        },

        '& fieldset': {
            border: 'rgba(158, 170, 181, 0.5) solid 1px',
            borderRadius: '7px'
        },

        '&:hover fieldset': {
            border: 'rgba(158, 170, 181, 0.5) solid 1px'
        },

        '&.Mui-focused fieldset': {
            border: `${props.theme.primaryColor} solid 2px`

        },
    },

    '.css-1z7n62': {
        width: 'auto',
        fontSize: '12px',
    }
}));

const CustomTextInput = (props) => {

    const { t } = useTranslation('custom_input_labels');
    const { id, SelectProps, label } = props;

    const customProps = {
        ...props, 
        label: label && label === "UID" && t('uid') ||
               label && label === "E-mails" && t('e-mails') ||
               label && label === "Start Date" && t('start_date') ||
               label && label === "Expiration Date" && t('expiration_date') ||
               id && t(id.toLowerCase()) || 
               SelectProps && t(SelectProps.id.toLowerCase()) || 
               ""
    };

    return (<StyledTextInput {...customProps}/>)
};

export default CustomTextInput;
