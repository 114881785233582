import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'antd';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from '../../store/users';
import CustomButton from '../Custom/CustomButton';
import CustomTextInput from '../Custom/CustomTextInput';
import PasswordFormItem from '../Custom/PasswordFormItem';

const ChangePasswordForm = (props) => {
    const { title, t } = props;
    const dispatch = useDispatch();
    let  {  loading } = useSelector(state => state.entities.users);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        dispatch(setPassword(values));
        navigate('/');
    }

    return (
        <div>
             <Row>
                <Col md={24} sm={24} className='m-2 mt-3'
                    style={{ color: '#425D5D', fontSize: '18px' }} >
                    {title}
                </Col>
            </Row>
            <CustomForm form={form}  onFinish={handleSubmit}>
                <CustomFormItem
                    name="password"
                    rules={[{ required: true, message: 'Please current password' }]}
                    >
                    <CustomTextInput
                        id="password"
                        label="Current password"
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        type="Password"
                        maxLength={60}
                    />
                </CustomFormItem>
                <PasswordFormItem  />
                <CustomFormItem
                    name="confirm_password"
                    rules={[{
                        required: true, message: 'Please confirm password'
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('sections.security.password_input.confirm_message')));
                            },
                        }),
                    ]} >
                     <CustomTextInput
                            id="confirm_password"
                            label="Confirm password"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            type="Password"
                            maxLength={60}
                    />
                </CustomFormItem>

          <Row>
                <Col xs={24} md={24} className='text-right'>
                    <CustomButton key="submit" type="primary" loading={loading} htmlType="submit">
                        {t && t('save', {ns: 'common_labels'})}
                    </CustomButton>
                </Col>
            </Row>
        </CustomForm>
      </div>
    );
  }



export default ChangePasswordForm;
