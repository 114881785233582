import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PermissionValidator } from '../shared/PermissionValidator';
import { loadStations } from '../../store/stations';
import { loadManagers } from '../../store/users';
import { Row, Col } from 'antd';
import CustomTextInput from '../Custom/CustomTextInput';
import CustomForm from '../Custom/CustomForm';
import CustomFormItem from '../Custom/CustomFormItem';
import CustomCheckbox from '../Custom/CustomCheckbox';
import MenuItem from '@mui/material/MenuItem';
import CustomMultipleSelect from '../Custom/CustomMultipleSelect';

const CreateUserForm = (props) => {
    const { roleHasResourcePermission, hasResourcePermission } = PermissionValidator();

    let adminFleet = hasResourcePermission('admin_fleet');

    const dispatch = useDispatch();
    const [hidden, setHidden] = useState(true);
    const [isStationsFieldHidden, setIsStationsFieldHidden] = useState(true);
    const [isManagersFieldHidden, setIsManagersFieldHidden] = useState(true);
    const [isCustomersFieldHidden, setIsCustomersFieldHidden] = useState(true);
    const [isStationsFieldHiddenDriver, setIsStationsFieldHiddenDriver] = useState(true);
    const managers = useSelector(state => state.entities.users.managersList);
    const { user } = useSelector(state => state.entities.authentication);


    useEffect(() => {
        // Gettin the profile
        let formValues = props.form.getFieldsValue();
        let role = props.roles.find(role => role.id === formValues.role);        

        // Cheking if the role belongs to a station
        let roleBelongStation = role ? roleHasResourcePermission(
            role.id,
            'belong_station'
        ) : false;

        let roleBelongToFleet = role ? roleHasResourcePermission(
            role.id,
            'belong_fleet'
        ) : false;

        let roleDriveVehicle = role ? roleHasResourcePermission(
            role.id,
            'drive_vehicle'
        ) : false;

        let roleBeAdminStation = role ? roleHasResourcePermission(
            role.id,
            'admin_customer'
        ) : false;
        
        let hiddenManager = !adminFleet && roleBelongToFleet;
        let isStation = props.stations.length > 0 && roleBelongStation;
        // Setting the visibility of fields
        setIsStationsFieldHidden(!isStation)
        setIsManagersFieldHidden(!hiddenManager);
        setIsCustomersFieldHidden(!roleBeAdminStation);
        setIsStationsFieldHiddenDriver(!roleDriveVehicle);
    }, [setIsStationsFieldHidden, props, roleHasResourcePermission, adminFleet]);

    if (adminFleet) {
        props.form.setFieldsValue({
            'assigned_stations': []
        });

        let roleBelongStation = roleHasResourcePermission(
            props.roles[0].id,
            'belong_station'
        );

        let roleBelongToFleet = roleHasResourcePermission(
            props.roles[0].id,
            'belong_fleet'
        );

        let roleDriveVehicle = roleHasResourcePermission(
            props.roles[0].id,
            'drive_vehicle'
        );

        if (adminFleet) {
            props.form.setFieldsValue({ 'parent': user.id });
        }
    }

    const showRoleOptions = (event, child) => {
        // Cheking if the role belongs to a station

        let roleBelongStation = roleHasResourcePermission(
            child.props.value,
            'belong_station'
        );

        let roleBelongToFleet = roleHasResourcePermission(
            child.props.value,
            'belong_fleet'
        );

        let roleDriveVehicle = roleHasResourcePermission(
            child.props.value,
            'drive_vehicle'
        );
        // Fetching the extra data
        if (roleBelongStation) {
            dispatch(loadStations());
        }

        if (!adminFleet && roleBelongToFleet) {
            dispatch(loadManagers());
        }


        // Cheking if there is at least 1 station created
        let isThereAStationCreated = props.stations.length > 0;

        // Validating the visibility of the stations field
        let isStationsFieldVisible = isThereAStationCreated && roleBelongStation;

        let hiddenManager = !adminFleet && roleBelongToFleet;
        // Setting the visibility of the extra fields
        setIsStationsFieldHidden(!isStationsFieldVisible);
        setIsManagersFieldHidden(!hiddenManager);
        setIsStationsFieldHiddenDriver(!roleDriveVehicle);

        // Setting the form station field
        props.form.setFieldsValue({
            'assigned_stations': [], 'assigned_dispensers': [], 'permitted_stations': [],
            'customer_stations': [], 'customer_name': '',
            'parent': ''
        });

        if (adminFleet) {
            props.form.setFieldsValue({ 'parent': user.id });
        }
    };

    const onChange = () => {
        setHidden(!hidden);
    };


    return (
        <CustomForm
            onFinish={props.onFinish}
            form={props.form}
        >            
            <CustomFormItem
                label="id"
                name="id"
                hidden={true}
            >
                <CustomTextInput />
            </CustomFormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomFormItem
                        name="first_name"
                        rules={[{
                            required: true,
                            message: 'Please input the name'
                        }]}
                    >
                        <CustomTextInput
                            id="first_name"
                            label="First name"
                            variant="outlined"
                            size="small"
                            disabled={props.isDetailsOnly}
                            required
                            fullWidth
                        />
                    </CustomFormItem>
                </Col>
                <Col span={12}>
                    <CustomFormItem
                        name="last_name"
                        rules={[{
                            required: true,
                            message: 'Please input the last name'
                        }]}
                    >
                        <CustomTextInput
                            id="last_name"
                            label="Last name"
                            variant="outlined"
                            size="small"
                            disabled={props.isDetailsOnly}
                            required
                            fullWidth
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CustomFormItem
                        name="email"
                        rules={[{
                            type: 'email',
                            required: true,
                            message: 'Please input the email'
                        }]}
                    >
                        <CustomTextInput
                            id="email"
                            label="Email"
                            variant="outlined"
                            size="small"
                            disabled={props.isDetailsOnly}
                            required
                            fullWidth
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={props.isDetailsOnly ? 12 : 24}>
                    <CustomFormItem
                        name="phone"
                        rules={[{
                            required: true,
                            message: 'Please input a valid phone number',
                            min: 10,
                            pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                        }]}
                    >
                        <CustomTextInput
                            id="phone"
                            label="Phone"
                            variant="outlined"
                            size="small"
                            type='tel'
                            disabled={props.isDetailsOnly}
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 14,
                            }}
                        />
                    </CustomFormItem>
                </Col>
                <Col span={props.isDetailsOnly ? 12 : 24}>
                    <CustomFormItem
                        name="role"
                        rules={[{
                            required: true,
                            message: 'Please select a role'
                        }]}
                    >
                        <CustomTextInput
                            label="Role"
                            size="small"
                            select
                            fullWidth
                            onChange={showRoleOptions}
                            disabled={props.form.getFieldValue("id") ? true : props.isDetailsOnly}
                        >
                            {props.roles.map(role => {
                                adminFleet && props.form.setFieldsValue({'role': props.roles[0].id})
                                if (role.is_active) {
                                return    <MenuItem
                                    key={role.id}
                                    value={role.id}
                                    selected={true}
                                >
                                    {role.name}
                                </MenuItem>
                                }
                            }
                        )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CustomFormItem
                        name="parent"
                        hidden={isManagersFieldHidden}
                        rules={[{
                                required: !isManagersFieldHidden,
                                message: 'Please select manager'
                        }]}
                    >
                        <CustomTextInput
                            id="parent"
                            label="Manager"
                            size="small"
                            select
                            fullWidth
                            disabled={props.isDetailsOnly}
                            placeholder="Please select manager"
                        >
                            {managers.map(manager =>
                                <MenuItem
                                    key={manager.id}
                                    value={manager.id}
                                    disabled={!manager.is_active}
                                >
                                    {`${manager.first_name} ${manager.last_name}`}
                                </MenuItem>
                            )}
                        </CustomTextInput>
                    </CustomFormItem>
                </Col>
            </Row>
            {
                !isCustomersFieldHidden && !props.form.getFieldValue("id")  ? <Fragment>
                    <Row
                    hidden={isCustomersFieldHidden}>
                    <Col span={24}>
                        <CustomFormItem
                            name="customer_name"
                            rules={[{
                                required: true,
                                message: 'Please input the Customer name'
                            }]}
                            >
                            <CustomTextInput
                                id="customer_name"
                                label="Customer name"
                                variant="outlined"
                                size="small"
                                disabled={props.isDetailsOnly}                     
                                fullWidth
                            />
                        </CustomFormItem>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                    className="stations_row"
                    hidden={isCustomersFieldHidden}
                >
                <Col span={24}>
                    <CustomFormItem
                        name="customer_stations"
                        rules={[{
                            required: true,
                            message: 'Please select a station'
                        }]}
                    >
                        <CustomMultipleSelect
                            label='Stations'
                            fieldName='customer_stations'
                            form={props.form}
                            options={props.stations}
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                </Col>
                </Row>
                </Fragment>
              : null
            }
            <Row
                gutter={16}
                className="stations_row"
                hidden={isCustomersFieldHidden ? isStationsFieldHidden : true}
            >
                <Col span={24}>
                    <CustomFormItem
                        name="assigned_stations"
                        rules={[{
                            required: !(isCustomersFieldHidden ? isStationsFieldHidden : true),
                            message: 'Please select a station'
                        }]}
                    >
                        <CustomMultipleSelect
                            label='Stations'
                            fieldName='assigned_stations'
                            form={props.form}
                            options={props.stations}
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row
                hidden={isCustomersFieldHidden ? isStationsFieldHidden : true}
            >
                <CustomFormItem 
                    name="tag_required"
                    // rules={[{
                    //     required: false,
                    // }]}
                    valuePropName="checked"
                    // hidden={props.form.getFieldValue("tag") !== null}
                >
                    <CustomCheckbox 
                        defaultChecked={true} 
                        disabled={props.isDetailsOnly}
                        onChange={onChange}
                    >
                        Tag required
                    </CustomCheckbox>
                </CustomFormItem>
            </Row>
            <Row
                gutter={16}
                className="dispensers_row"
                hidden={!props.form.getFieldValue('tag_required')}
            >
                <Col span={24} hidden={isStationsFieldHidden}>
                    <CustomFormItem
                        name="assigned_dispensers"
                    >
                        <CustomMultipleSelect
                            label='Dispensers'
                            fieldName='assigned_dispensers'
                            form={props.form}
                            options={props.dispensers}
                            disabled={props.isDetailsOnly}
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            {
                adminFleet ?  <Row
                    gutter={16}
                    className="stations_row"
                    hidden={!adminFleet}
                >
                    <Col span={24}>
                        <CustomFormItem
                            name="permitted_stations"
                            rules={[{
                                required: true,
                                message: 'Please select a station'
                            }]}
                        >
                            <CustomMultipleSelect
                                label='Stations'
                                fieldName='permitted_stations'
                                form={props.form}
                                options={props.stations}
                                disabled={props.isDetailsOnly}
                            />
                        </CustomFormItem>
                    </Col>
                </Row>
                : null
            }
        </CustomForm>
    );
}

export default CreateUserForm;
