import * as React from "react";

function SvgProducts({ title, titleId, ...props }) {
  return (
    <svg
      data-name="Grupo 13091"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path data-name="Trazado 11482" d="M0 0h21v21H0z" fill="none" />
      <rect
        data-name="Rect\xE1ngulo 17285"
        width={13}
        height={15}
        rx={2}
        transform="translate(4 3)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <rect
        data-name="Rect\xE1ngulo 17286"
        width={8}
        height={3}
        rx={1}
        transform="translate(6.5 6)"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        data-name="L\xEDnea 129"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.5 12h0"
      />
      <path
        data-name="L\xEDnea 130"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.5 12h0"
      />
      <path
        data-name="L\xEDnea 131"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.5 12h0"
      />
      <path
        data-name="L\xEDnea 132"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.5 15h0"
      />
      <path
        data-name="L\xEDnea 133"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.5 15h0"
      />
      <path
        data-name="L\xEDnea 134"
        fill="none"
        stroke="#c2cfe0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.5 15h0"
      />
    </svg>
  );
}

export default SvgProducts;
