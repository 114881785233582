import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';


const url = '/users/';
const slice = createSlice({
    name: 'authentication',
    initialState: {
        passwordReseted: false,
        loading: false,
        fakeLoading: false,
        lastFetch: null,
        errors: [],
        resetPasswordErrors: [],
        authData: JSON.parse(localStorage.getItem('auth_data')),
        user: JSON.parse(localStorage.getItem('user')),
        emailVerificationResponse: {
            "type": "error", 
            "data": false 
        },
        resetPasswordResponse: {
            "type": "error", 
            "data": false 
        },
        config: {
            primaryColor: localStorage.getItem('primary_color'),
            primaryColorStation: '#65C0C4',
            primaryColorFleet: '#F3B341',
            volume_unit: "gal",
            currency: "usd"
        }
    },
    reducers: {
        authenticationRequestStarted: (state, action) => {
            state.errors = []
            state.loading = true;
        },
        loginRequestFailed: (state, action) => {
            state.errors = [{ "type": "error", "data": action.payload.data }]
            state.loading = false;
        },
        loginRequestSucceed: (state, action) => {
            const data = action.payload.data;
            const user = data.user;
            const token = data.access;

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('auth_data', JSON.stringify(data));
            state.token = token;
            state.user = user;
            state.loading = false;
            state.authData = data;
            let is_station = user.resource_permissions.filter(resource => resource.codename === 'access_station');
            if (is_station.length > 0) {
                state.config.primaryColor = '#65C0C4';
                localStorage.setItem('primary_color', '#65C0C4');
            } else {
                state.config.primaryColor = '#F3B341';
                localStorage.setItem('primary_color', '#F3B341');
            }

        },
        FakeAuthenticationRequestStarted: (state, action) => {
            state.errors = []
            state.fakeLoading = true;
        },
        FakeLoginRequestFailed: (state, action) => {
            state.errors = [{ "type": "error", "data": action.payload.data }]
            state.fakeLoading = false;
        },
        FakeLoginRequestSucceed: (state, action) => {
            const data = action.payload.data;
            const user = data.user;
            const token = data.access;

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('auth_data', JSON.stringify(data));
            state.token = token;
            state.user = user;
            state.fakeLoading = false;
            state.authData = data;
            let is_station = user.resource_permissions.filter(resource => resource.codename === 'access_station');
            if (is_station.length > 0) {
                state.config.primaryColor = '#65C0C4';
                localStorage.setItem('primary_color', '#65C0C4');
            } else {
                state.config.primaryColor = '#F3B341';
                localStorage.setItem('primary_color', '#F3B341');
            }
            
            window.location = window.location.origin
        },
        logout: (state, action) => {
            localStorage.removeItem('auth_data');
            localStorage.removeItem('user');
            state.token = '';
            state.authData = null;
        },
        refreshErrors: (state, action) => {
            state.resetPasswordErrors = [];
            state.errors = [];
        },
        emailVerificationRequestSuccess: (state, action) => {
            state.emailVerificationResponse = {
                "type": "success",
                "data": action.payload.data
            };
            state.loading = false;

        },
        emailVerificationRequestFailed: (state, action) => {
            state.emailVerificationResponse = {
                "type": "error",
                "data": action.payload.data
            }
            state.loading = false;
        },
        resetEmailRequestStarted: (state, action) => {
            state.resetPasswordErrors = []
            state.loading = true;
        },
        resetEmailRequestSuccess: (state, action) => {
            state.resetPasswordResponse = {
                "type": "success",
                "data": action.payload.data
            };
            state.loading = false;
        },
        resetEmailRequestFailed: (state, action) => {
            state.resetPasswordErrors = [{ "type": "error", "data": action.payload.data }]
            state.loading = false;
        },
        resetPasswordRequestStarted: (state, action) => {
            state.loading = true;
        },
        resetPasswordRequestSuccess: (state, action) => {
            state.loading = false;
            
            state.resetPasswordResponse = {
                "type": "success",
                "data": action.payload.data
            };
        },
        resetPasswordRequestFailed: (state, action) => {
            state.loading = false;
        },
        verifyResetPasswordTokenRequestStarted: (state, action) => {
            state.loading = true;
        },
        verifyResetPasswordTokenRequestSuccess: (state, action) => {
            state.loading = false; 
            state.resetPasswordResponse = {
                "type": "success",
                "data": action.payload.data
            };
        },
        verifyResetPasswordTokenRequestFailed: (state, action) => {
            state.loading = false;
        },
        companyConfigurationRequested: (state, action) => {
            state.loading = true;
        },
        companyConfigurationRequestFailed: (state, action) => {
            state.loading = false;
        },
        companyConfigurationReceived: (state, action) => {
            const {volume_unit, currency} = action.payload.data;

            state.config.volume_unit = volume_unit;
            state.config.currency = currency;
            state.loading = false;
        }
    }
});

export const { logout, refreshErrors, changeSystemMeasurementUnit } = slice.actions;
export default slice.reducer;

export const loadLogin = (email, password, counter) => apiCallBegan({
    url: `${url}login/`,
    data: { email, password, counter },
    method: 'POST',
    onStart: slice.actions.authenticationRequestStarted.type,
    onSuccess: slice.actions.loginRequestSucceed.type,
    onError: slice.actions.loginRequestFailed.type
});

export const verifyEmail = (token) => apiCallBegan({
    url: `${url}email_verification/${token}`,
    method: 'get',
    onStart: slice.actions.authenticationRequestStarted.type,
    onSuccess: slice.actions.emailVerificationRequestSuccess.type,
    onError: slice.actions.emailVerificationRequestFailed.type
});

export const sendResetPasswordEmail = (email) => apiCallBegan({
    url: `${url}email_reset_password/`,
    data: { email },
    method: 'post',
    onStart: slice.actions.resetEmailRequestStarted.type,
    onSuccess: slice.actions.resetEmailRequestSuccess.type,
    onError: slice.actions.resetEmailRequestFailed.type
});

export const verifyResetPasswordToken = (token) => apiCallBegan({
    url: `${url}email_reset_password/${token}`,
    method: 'get',
    onStart: slice.actions.verifyResetPasswordTokenRequestStarted.type,
    onSuccess: slice.actions.verifyResetPasswordTokenRequestSuccess.type,
    onError: slice.actions.verifyResetPasswordTokenRequestFailed.type
});

export const resetPassword = (newPassword, userEmail) => apiCallBegan({
    url: `${url}email_reset_password/`,
    data: { newPassword, userEmail },
    method: 'patch',
    onStart: slice.actions.resetPasswordRequestStarted.type,
    onSuccess: slice.actions.resetPasswordRequestSuccess.type,
    onError: slice.actions.resetPasswordRequestFailed.type
});


export const selectToken = (state) => state.entities.authentication.authData;


export const loadFakeLogin = (email, password, role) => apiCallBegan({
    url: `${url}fakeLogin/`,
    data: { email, password, role },
    method: 'POST',
    onStart: slice.actions.FakeAuthenticationRequestStarted.type,
    onSuccess: slice.actions.FakeLoginRequestSucceed.type,
    onError: slice.actions.FakeLoginRequestFailed.type
});

export const getCompanyConfiguration = (id) => apiCallBegan({
    url: `/companies/${id}/get_configuration`,
    onStart: slice.actions.companyConfigurationRequested.type,
    onSuccess: slice.actions.companyConfigurationReceived.type,
    onError: slice.actions.companyConfigurationRequestFailed.type
});

export const updateCompanyConfiguration = (id, configuration) => apiCallBegan({
    url: `/companies/${id}/update_configuration/`,
    data: configuration,
    method: 'PATCH',
    onStart: slice.actions.companyConfigurationRequested.type,
    onSuccess: slice.actions.companyConfigurationReceived.type,
    onError: slice.actions.companyConfigurationRequestFailed.type
});
