import "./iconButton.scss" ;



const IconButton = ({icon, onClick, hover_color, isVisible = true}) => {
    return (
        <div className="mdc-touch-target-wrapper" style={{ display: (!isVisible) ? 'none': '' }} >
            <button className={`mdc-icon-button ${hover_color}`} onClick={onClick}>
                <div className="mdc-icon-button__ripple"></div>
                    {icon}
            </button>
        </div>
    );
}

export default IconButton