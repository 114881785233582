import React from 'react';
import { Drawer } from 'antd';
import logo_text from './../assets/img/logo_text.png';
import styled from 'styled-components';
import MenuBar  from './MenuBar';

const Img = styled.img`
    width: 90%;
`;

const DarkDrawer = styled(Drawer)`
    .ant-drawer-close {
       color: white;
    }
`;

const DrawerBar = (props) => {

    return (
        <DarkDrawer
            title={<div className="logo text-center" >
                <Img src={logo_text} alt="logo" />
            </div>}
            placement='left'
            closable={props.visible}
            visible={props.visible}
            onClose={props.onClose}
            key='DarkDrawer'            
        >
            <MenuBar  onClose={props.onClose} />
        </DarkDrawer>  
    );
}

export default DrawerBar;
