/** 
 * @description This component is intended to use inside
 * password forms in wich is required
 * the use of a new password to evaluate
 * with the assigned validations. 
 */

import React, {useState, Fragment, useEffect} from 'react';
import { Tooltip, Progress, Typography } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CustomForm from '../Custom/CustomForm';
import CustomTextInput from '../Custom/CustomTextInput';

const { Item } = CustomForm;
const { Paragraph, Text } = Typography;

const StyledProgress = styled(Progress)`

        .ant-progress-steps-item {
            width: 24% !important;
        }
`;
const StyledParagraph = styled(Paragraph)`
        font-size: 14px;
        min-width: 225px;

        &.validations-title {
            font-size: 12px;
        }
`;

const Validation = styled.li`
        font-size: 12px;
        font-weight: 100;
`;

const PasswordFormItem = (props) => {

    const { t } = useTranslation('settings');

    const [hasUpperAndLower, setHasUpperAndLower] = useState(false);
    const [hasSymbols, setHasSymbols] = useState(false);
    const [hasCharacters, setHasCharacters] = useState(false);
    const [hasEnoughCharacters, setHasEnoughCharacters] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState("initial");

    const
        UPPER_CASE_CHARACTERS = /[A-Z]/,
        LOWER_CASE_CHARACTERS = /[a-z]/,
        SPECIAL_CHARACTERS = /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/

    const 
        VALIDATIONS = [
            {name: t('sections.security.password_input.letter_case_validation'), state: hasUpperAndLower},
            {name: t('sections.security.password_input.symbol_validation'), state: hasSymbols},
            {name: t('sections.security.password_input.length_validation'), state: hasEnoughCharacters},
        ], // validatons for the new password
        PROGRESS_LEVELS = {
            0: {strength: "initial", value: 0},
            1: {strength: "weak", value: 25},
            2: {strength: "average", value: 50},
            3: {strength: "good", value: 75},
            4: {strength: "strong", value: 100},
        }, // levels of progress for the Progress component
        PROGRESS_MESSAGES = {
            "initial": {message: t('sections.security.password_input.characters_length_message'), color: ''},
            "weak": {message: t('sections.security.password_input.characters_length_message'), color: "#FD7D50"},
            "average": {message: t('sections.security.password_input.average_message'), color: '#FBD555'},
            "good": {message: t('sections.security.password_input.good_message'), color: '#54B78A'},
            "strong": {message: t('sections.security.password_input.strong_message'), color: '#1B7963'},
        } // labels for the different progress levels

    const checkValidations = (string) => {
        const
            hasUpper = UPPER_CASE_CHARACTERS.test(string),
            hasLower = LOWER_CASE_CHARACTERS.test(string),
            hasSymbol = SPECIAL_CHARACTERS.test(string);

        setHasCharacters(string !== '');
        setHasUpperAndLower(hasUpper & hasLower);
        setHasSymbols(hasSymbol);
        setHasEnoughCharacters(string.length > 7);
    }

    const getProgressData = () => {
        return PROGRESS_MESSAGES[passwordStrength?.strength]
    }

    const getMessage = (message) => {
        return (
            <StyledParagraph className="mb-0">
                {message}
            </StyledParagraph>
        )
    }

    const getProgress = (percent) => {
        return (
            <StyledProgress
                className="w-100"
                showInfo={false} 
                percent={percent} 
                steps={4} 
                strokeWidth={3} 
                strokeColor={getProgressData()?.color} 
            />
        )
    }

    const getValidationsList = (validations) => {
        return (
            <Fragment>
                <StyledParagraph className="validations-title mb-0 mt-2">
                    {t('sections.security.password_input.title')}
                </StyledParagraph>
                <ul className='text-dark pl-4 mb-0'>
                    {
                        validations?.map(validation => 
                            <Validation>
                                <Text delete={validation?.state}>
                                    {validation?.name}
                                </Text>
                            </Validation> 
                        ) 
                    }
                </ul>
            </Fragment>
        )
    }

    useEffect(() => {

        const 
            validations = [
                hasUpperAndLower, 
                hasSymbols,
                hasCharacters,
                hasEnoughCharacters
            ], // array of validations to check
            activeValidations = validations.filter(Boolean).length // extracting the number of active validations

        setPasswordStrength(PROGRESS_LEVELS[activeValidations]);

    }, [hasUpperAndLower, hasSymbols, hasCharacters, hasEnoughCharacters])

    return (
        <Tooltip
            placement="right"
            trigger="focus"
            color="white"
            title={() => 
                <div className="p-2 pb-0">
                    {getMessage(getProgressData()?.message)}
                    {getProgress(passwordStrength?.value)}
                    {getValidationsList(VALIDATIONS)}
                </div>
            }
        >
            <Item
                name="new_password"
                rules={[{ required: true, min: 8, message: t('sections.security.password_input.message') }]}
            >
                <CustomTextInput
                    id="new_password"
                    label="New password"
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    type="Password"
                    maxLength={60}
                    onChange={event => checkValidations(event.target.value)}
                />
            </Item>
        </Tooltip>
    );
}



export default PasswordFormItem;
