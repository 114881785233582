import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-checked  {
        .ant-checkbox-inner {
            background-color: ${props => props.theme.primaryColor};
            border-color: ${props => props.theme.primaryColor};
        }
    }
    :focus {
        .ant-checkbox-inner {
            border-color: ${props => props.theme.primaryColor};
        }
        border-color: ${props => props.theme.primaryColor};
    }
`;

const CustomCheckbox = (props) => {

    const { t } = useTranslation('common_labels');
    const { id } = props;
    const customProps = {...props, children: id && t(id)};

    return (<StyledCheckbox {...customProps} />)
};

export default CustomCheckbox;
