import { Layout } from 'antd';
import MenuBar from './MenuBar';
import styled from 'styled-components';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
`

const SideBar = (props) => {

    return (
        <StyledSider
            id="sider"
            width={220}
            collapsedWidth={0}
            trigger={null}
            collapsible
            collapsed={props.collapsed}
            breakpoint={'md'}
            onBreakpoint={props.onBreakPoint}
        >
            <MenuBar translationFunction={props.translationFunction}/>
        </StyledSider>
    );
}

export default SideBar;
