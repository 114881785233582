import React from 'react';
import styled from 'styled-components';
import { Radio, DatePicker, Space, Input, Col, Row, Typography, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

import DownloadReports from './DownloadReports';
import CustomRadioButton from '../Custom/CustomRadioButton';
import TaxCo2Card from '../Custom/TaxCo2Card';
import CustomSearchInput from '../Custom/CustomSearchInput';

import SavedCO2 from '../icons/SavedCO2';
import TaxCredit from '../icons/TaxCredit';

import ReportIcon from '../icons/ReportIcon';

const { RangePicker } = DatePicker;
const { Search } = Input;

const { Text } = Typography;

const titleH1 = {
    color: "#495057",
    fontFamily: "MetropolisRegular",
    fontSize: "21px",
    lineHeight: '2.2',
    margin: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}

const titleSpan = {
    color: "#495057",
    fontSize: "19px",
}

const title = {
    titleH1,
    titleSpan
}

const CustRangePicker = styled(RangePicker)`
    height: 48px;
    border-color: #C2CFE0;
    border-radius: 10px;
    max-width: 225px;
    width: 100%;
`;

const FilterServices = (props) => {

    const {  translationFunction, accessStation, co2_saved, tax_revenue, readSavedCO2 } = props;
    const { authData } = useSelector(state => state.entities.authentication);
    const dateFormat = 'YYYY-MM-DD';
    
    function disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    
    const getName = () => {
        return authData.user.first_name + " " + authData.user.last_name;
    }

    let today = new Date();
    let params = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${today.getDate()}`;


    return (
        <Row className="h-100">
            <Col lg={8} xl={10} className='pl-2'>
                <p style={title.titleH1}>
                    {translationFunction('filter_services.title')}<span style={title.titleSpan}>{ getName() }</span>
                </p>
            </Col>
            <Col className="m-auto" lg={!accessStation ? 5 : 8} xl={!accessStation ? 7 : 5} >
                <label className="mr-2">
                    {translationFunction('filter_services.date_label')}
                </label>
                <CustRangePicker
                    onCalendarChange={(dates, dateStrings, info) => {
                        if (info?.range === "end") {
                            props.onChangeDateCalendar(dateStrings[0], dateStrings[1])
                        }
                    }}
                    defaultValue={[moment(params, dateFormat), moment(params, dateFormat)]}
                    disabledDate={disabledDate}
                    format={dateFormat}
                />
            </Col>
            {accessStation &&
                <Col className='pl-4 pr-1 m-auto' lg={10} xl={6} >
                    <CustomSearchInput
                        onChange={props.handleSearch}
                    />
                </Col>
            }
            { !accessStation &&
                <Col className='text-right pr-2 m-auto' xl={!accessStation ? 7 : 5} >
                    <label className="mr-2">
                        {translationFunction('filter_services.fleet_filter.label')}
                    </label>
                    <Radio.Group  defaultValue={true} onChange={props.handleFilter} >
                        <CustomRadioButton value={true}>
                            <div>
                                <Text>
                                    {translationFunction('filter_services.fleet_filter.volume_option')}
                                </Text>
                            </div>
                        </CustomRadioButton>
                        <CustomRadioButton value={false}>
                            <div>
                                <Text>
                                    {translationFunction('filter_services.fleet_filter.amount_option')}
                                </Text>
                            </div>
                        </CustomRadioButton>
                    </Radio.Group>
                </Col>
            }
        </Row> 
    );
}

export default FilterServices;
