import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const url = '/content_types/';
const slice = createSlice({
    name: 'contentTypes',
    initialState: {
        list: [],
        loading: false,
        lastFetch: null
    },
    reducers: {
        contentTypesRequested: (contentTypes, action) => {
            contentTypes.loading = true;
        },
        contentTypesRequestFailed: (contentTypes, action) => {
            contentTypes.loading = false;
        },
        contentTypesReceived: (contentTypes, action) => {
            contentTypes.list = action.payload.data;
            contentTypes.loading = false;
        }
    }
});

export default slice.reducer;
export const loadContentTypes = () => apiCallBegan({
    url,
    onStart: slice.actions.contentTypesRequested.type,
    onSuccess: slice.actions.contentTypesReceived.type,
    onError: slice.actions.contentTypesRequestFailed.type
});