import React, { Component } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import SvgActionIcon from '../icons/ActionsIcon';


class ActionsStations extends Component {

  render() {
      const { station, hasEditPermissions, hasViewPermissions,
              clickChangeStatus, openModal, t } = this.props;
    const menu = (
      <Menu>
        {hasEditPermissions ?
            <Menu.Item >
                <Button type="text"
                    onClick={() => clickChangeStatus(station.id, !station.is_active)}
                    >
                    {station.is_active ? t('inactivate') : t('activate') }
                </Button>
            </Menu.Item>
            : null
        }
            <Menu.Item d style={{display: hasViewPermissions ? '' : 'none'}} >
                <Button type="text" 
                    onClick={() => openModal(station, true)} >
                    {t('station_detail')}
                </Button>
            </Menu.Item>
      </Menu>
    );

    return ( 

        <div>
            <Dropdown overlay={menu} >
                <div className="mdc-touch-target-wrapper">
                    <Button type="text" className="mdc-icon-button mdc-icon-button__detail">
                        <div className="mdc-icon-button__ripple"></div>
                        <SvgActionIcon />
                    </Button>
                </div>
            </Dropdown>
            
      </div>
    );
  }
}

const ActionsStationsComponent = withTranslation('actions')(ActionsStations);

export default ActionsStationsComponent;
