import styled from 'styled-components';
import { Form } from 'antd';

const CustomForm = styled(Form)`
	.ant-form-item-explain-error {
		font-size: 12px;
		margin-bottom: 2px;
	}
`;

export default CustomForm;