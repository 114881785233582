import * as React from "react";

function TaxCredit({ title, titleId, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20.157 19.595">
      <g id="vuesax_bulk_money-send" data-name="vuesax/bulk/money-send" transform="translate(-428 -444)">
        <g id="money-send" transform="translate(428 444)">
          <path id="Vector" d="M0,0H20.157V19.595H0Z" fill="none" opacity="0"/>
          <g id="Group" transform="translate(1.407 1.407)">
            <path id="Vector-2" data-name="Vector" d="M14.325,2.03A8.86,8.86,0,0,0,8.671,0,8.537,8.537,0,0,0,0,8.39a8.537,8.537,0,0,0,8.671,8.39,8.537,8.537,0,0,0,8.671-8.39,8.248,8.248,0,0,0-2.1-5.47Z" fill="#74d583"/>
          </g>
          <path id="Vector-3" data-name="Vector" d="M3.518,3.568l-.7-.246V1.759h.056a.677.677,0,0,1,.647.7.528.528,0,1,0,1.056,0A1.731,1.731,0,0,0,2.871.7H2.815V.528a.528.528,0,1,0-1.056,0V.7H1.548A1.579,1.579,0,0,0,0,2.308a1.442,1.442,0,0,0,1.056,1.52l.7.246V5.637H1.7a.677.677,0,0,1-.647-.7.532.532,0,0,0-.528-.528A.532.532,0,0,0,0,4.933,1.731,1.731,0,0,0,1.7,6.692h.056v.176a.528.528,0,0,0,1.056,0V6.692h.211a1.579,1.579,0,0,0,1.548-1.6A1.43,1.43,0,0,0,3.518,3.568ZM1.4,2.822c-.239-.084-.345-.134-.345-.521a.526.526,0,0,1,.493-.549h.211V2.941ZM3.026,5.629H2.815V4.44l.359.127c.239.084.345.134.345.521A.524.524,0,0,1,3.026,5.629Z" transform="translate(7.792 6.104)" fill="#fff"/>
        </g>
      </g>
    </svg>
  );
}

export default TaxCredit;